<template>
  <div class="vuestic-layout"
       :class="classObject"
  >
    <slot></slot>
    <div class="content-wrap no-padding" id="content-wrap">
      <slot name="content"></slot>
    </div>
    <!-- <div class="made-by-footer">
      <slot name="footer"></slot>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'vuestic-layout',
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  directives: {

  },
  computed: {
    classObject: function () {
      return {
        'layout-fixed': this.fixed,
      }
    },
  },
}
</script>

<style lang="scss">
.vuestic-layout {
  .content-wrap {
    // margin-left: $content-wrap-ml;
    transition: margin-left 0.3s ease;
    // padding: $layout-padding $layout-padding-right $content-wrap-pb 0;

    .pre-loader {
      position: absolute;
      left: $vuestic-preloader-left;
      top: $vuestic-preloader-top;
    }
    @include media-breakpoint-down(md) {
      padding: $content-mobile-wrap;
      margin-left: 0;
      .sidebar-hidden & {
        margin-left: 0;
        padding-top: $content-mobile-wrap-sb-top;
      }
    }

    @media(max-width: 990px){
          width: 100%;
          display: inline-flex;
    }
  }
  &-fixed {
    .content-wrap {
      padding-right: $layout-padding-right;
      padding-top: $sidebar-top;
      @include media-breakpoint-down(md) {
        padding: $content-mobile-wrap-fixed-layout;
        margin-left: 0;
      }
    }
  }

  .made-by-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: $made-by-footer-pb;
    position: absolute;
    bottom: 0;
    height: calc(#{$layout-padding} + #{$widget-mb});
    width: 100%;
  }
}
</style>
