// Polyfills
import "babel-polyfill";
import "es6-promise/auto";
require("dotenv").config();

import DesignSystem from "@tiddh/brave-vue";
import Vue from "vue";
import App from "./App";
import store from "./store";
import router from "./router";
import VuesticPlugin from "@/vuestic-theme/vuestic-plugin";
import "./i18n";
import YmapPlugin from "vue-yandex-maps";
import axios from "axios";
import ToggleButton from "vue-js-toggle-button";
import vuescroll from "vue-scroll";
import VeeValidate, { Validator } from "vee-validate";
import ptBr from "vee-validate/dist/locale/pt_BR";
import Lottie from "vue-lottie";
import VueGtm from "vue-gtm";
import Flipbook from "flipbook-vue";
import vueJquery from "vue-jquery";
import VueCardFormat from "vue-credit-card-validation";
import money from "v-money";
import InfiniteLoading from "vue-infinite-loading";
import VueTheMask from "vue-the-mask";
import CpfValidator from "./components/validators/cpf.validator";
import Dictionary from "./components/validators/dictionary";
axios.defaults.baseURL = process.env.BASE_URL;
import VueLazyload from "vue-lazyload";
// import Router from "vue-router";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import VueTippy, { TippyComponent } from "vue-tippy";

// const router = new Router({
// 	// ...
// });

// or

Vue.component("tippy", TippyComponent);
Vue.use(DesignSystem);
Vue.use(axios);
Vue.use(ToggleButton);
Vue.use(VuesticPlugin);
Vue.use(YmapPlugin);
Vue.use(vuescroll);
Vue.use(VueTheMask);
Vue.use(Lottie);
Vue.use(Flipbook);
Vue.use(vueJquery);
Vue.use(VueCardFormat);
Vue.use(InfiniteLoading);
Vue.use(money, { precision: 4 });
Vue.use(VueTippy);
Vue.use(VueLazyload);
// Vue.use(Router);

Sentry.init({
	Vue,
	dsn: "https://750583d2e94c41b2a4d2beb5272582f6@o438982.ingest.sentry.io/4504894042472448",
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracePropagationTargets: ["localhost", "dentrodaescola.com.br", /^\//],
		}),
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

// GTM
Vue.use(VueGtm, {
	id: "GTM-PXQJD7S", // Your GTM ID
	enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug: false, // Whether or not display console logs debugs (optional)
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	ignoredViews: ["homepage"], // If router, you can exclude some routes name (case insensitive) (optional)
});

// NOTE: workaround for VeeValidate + vuetable-2
Vue.use(VeeValidate, { events: "" });
Validator.localize("pt_BR", ptBr);
Validator.localize(Dictionary);
Validator.extend("cpf", CpfValidator);

router.beforeEach((to, from, next) => {
	store.commit("setLoading", true);
	next();
});

router.afterEach((to, from) => {
	store.commit("setLoading", false);
});

/* eslint-disable no-new */

new Vue({
	el: "#app",
	router,
	store,
	render: (h) => h(App),
});
