// import Alert from './vuestic-components/vuestic-alert/VuesticAlert.vue'
// import Breadcrumbs
//   from './vuestic-components/vuestic-breadcrumbs/VuesticBreadcrumbs.vue'
import Chart from "./vuestic-components/vuestic-chart/VuesticChart.vue";
// import Chat from './vuestic-components/vuestic-chat/VuesticChat.vue'
// import Checkbox from './vuestic-components/vuestic-checkbox/VuesticCheckbox.vue'
import DataTable from "./vuestic-components/vuestic-datatable/VuesticDataTable.vue";

// import Feed from './vuestic-components/vuestic-feed/VuesticFeed.vue'
// import MediumEditor
//   from './vuestic-components/vuestic-medium-editor/VuesticMediumEditor.vue'
import Modal from "./vuestic-components/vuestic-modal/VuesticModal.vue";
// import MultiSelect
//   from './vuestic-components/vuestic-multi-select/VuesticMultiSelect.vue'
// import Popover from './vuestic-components/vuestic-popover/VuesticPopover.vue'
// import PreLoader
//   from './vuestic-components/vuestic-preloader/VuesticPreLoader.vue'
// import ProfileCard
//   from './vuestic-components/vuestic-profile-card/VuesticProfileCard.vue'
import ProgressBar from "./vuestic-components/vuestic-progress-bar/VuesticProgressBar.vue";
// import RadioButton
//   from './vuestic-components/vuestic-radio-button/VuesticRadioButton'
// import Scrollbar
//   from './vuestic-components/vuestic-scrollbar/VuesticScrollbar.vue'
// import SimpleSelect
//   from './vuestic-components/vuestic-simple-select/VuesticSimpleSelect.vue'
// import Slider from './vuestic-components/vuestic-slider/VuesticSlider.vue'

// import SocialNews
//   from './vuestic-components/vuestic-social-news/VuesticSocialNews.vue'
// import Switch from './vuestic-components/vuestic-switch/VuesticSwitch.vue'
// import Tabs from './vuestic-components/vuestic-tabs/VuesticTabs.vue'
// import Tooltip from './vuestic-components/vuestic-tooltip/VuesticTooltip.vue'
// import Widget from './vuestic-components/vuestic-widget/VuesticWidget.vue'
// import Wizard from './vuestic-components/vuestic-wizard/VuesticWizard.vue'

import TreeRoot from "./vuestic-components/vuestic-tree-view/VuesticTreeRoot";
import TreeCategory from "./vuestic-components/vuestic-tree-view/VuesticTreeCategory";
import TreeNode from "./vuestic-components/vuestic-tree-view/VuesticTreeNode";

// import DatePicker
//   from './vuestic-components/vuestic-date-picker/VuesticDatePicker'
// import Card from './vuestic-components/vuestic-card/VuesticCard'
// import FileUpload
//   from './vuestic-components/vuestic-file-upload/VuesticFileUpload'
// import Tag from './vuestic-components/vuestic-tag/VuesticTag'
// import TagGroup from './vuestic-components/vuestic-tag/VuesticTagGroup'
// import VuesticDropdown
//   from './vuestic-components/vuestic-dropdown/VuesticDropdown'

import VuesticToasted from "./vuestic-mixins/VuesticToasted";
// import Dropdown from './vuestic-directives/Dropdown'
// import StickyScroll from './vuestic-directives/StickyScroll'

// import VuesticAccordion
//   from './vuestic-components/vuestic-collapse/VuesticAccordion'
// import VuesticCollapse
//   from './vuestic-components/vuestic-collapse/VuesticCollapse'

// import VuesticTimeline
//   from './vuestic-components/vuestic-timelines/VuesticTimeline'
// import VuesticTimelineItem
//   from './vuestic-components/vuestic-timelines/VuesticTimelineItem'
// import VuesticPopup
//   from './vuestic-components/vuestic-popup/quasar/components/popup/VuesticPopup.vue'

// import VuesticContainer from './vuestic-components/vuestic-grid/VGrid/Container'
// import VuestVaLayout from './vuestic-components/vuestic-grid/VGrid/Layout'
// import VuesticFlex from './vuestic-components/vuestic-grid/VGrid/Flex'

//import { installQuasarPlatform } from './vuestic-components/vuestic-popup/quasar/install'

// DDH
import inputEdit from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhInput.vue";
import inputSimple from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhInputSimple.vue";
import inputMoney from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhInputMoney.vue";
import inputNormal from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhInputEdit.vue";
import radioButton from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhRadio.vue";
import selectNormal from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhSelect.vue";
import selectMutiple from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhSelectMutiple.vue";

import menuTabs from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhMenuTab.vue";
import statusBar from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhStatusBar.vue";
import nextKit from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhNextKit.vue";
import deliveredKit from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhDeliveredKit.vue";
import followModal from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhModal.vue";
import nameModal from "./vuestic-components/vuestic-ddh-input-edit/VuesticDdhModalName.vue";
// import shoppingBox from './vuestic-components/vuestic-ddh-input-edit/VuesticDdhShoppingBox.vue'
// import resumeBox from './vuestic-components/vuestic-ddh-input-edit/VuesticDdhResumeBox.vue'

// import angelEdit from './vuestic-components/modals/AngelEdit.vue'
// import angelView from './vuestic-components/modals/AngelView.vue'
// import angelDelete from './vuestic-components/modals/AngelDelete.vue'

import sectorEdit from "./vuestic-components/modals/SectorEdit.vue";
//import gradeView from './vuestic-components/modals/GradeView.vue'
import sectorDelete from "./vuestic-components/modals/GradeDelete.vue";

import projectEdit from "./vuestic-components/modals/ProjectEdit.vue";
import projectView from "./vuestic-components/modals/ProjectView.vue";
import projectDelete from "./vuestic-components/modals/ProjectDelete.vue";

import peopleEdit from "./vuestic-components/modals/PeopleEdit.vue";
import peopleView from "./vuestic-components/modals/PeopleView.vue";
import peopleDelete from "./vuestic-components/modals/PeopleDelete.vue";

import peopleEditManager from "./vuestic-components/modals/PeopleEditManager.vue";
import peopleViewManager from "./vuestic-components/modals/PeopleViewManager.vue";
import peopleDeleteManager from "./vuestic-components/modals/PeopleDeleteManager.vue";

import schoolEdit from "./vuestic-components/modals/SchoolEdit.vue";
import schoolView from "./vuestic-components/modals/SchoolView.vue";
import schoolDelete from "./vuestic-components/modals/SchoolDelete.vue";

import gradeEdit from "./vuestic-components/modals/GradeEdit.vue";
import gradeView from "./vuestic-components/modals/GradeView.vue";
import gradeDelete from "./vuestic-components/modals/GradeDelete.vue";

import gradeEditConsultant from "./vuestic-components/modals/GradeEditConsultant.vue";
import gradeViewConsultant from "./vuestic-components/modals/GradeViewConsultant.vue";
import gradeDeleteConsultant from "./vuestic-components/modals/GradeDeleteConsultant.vue";

import gradeEditManager from "./vuestic-components/modals/GradeEditManager.vue";
import gradeViewManager from "./vuestic-components/modals/GradeViewManager.vue";
import gradeDeleteManager from "./vuestic-components/modals/GradeDeleteManager.vue";

import adotpionEditon from "./vuestic-components/modals/AdoptionEdit.vue";
import adotpionEditonManager from "./vuestic-components/modals/AdoptionEditManager.vue";

import booksEdit from "./vuestic-components/modals/BooksEdit.vue";

// At the moment we use quasar platform install to make its components work.
// Ideally we want to create similar vuestic platform object that holds needed values.
//installQuasarPlatform()

const VuesticPlugin = {
	install(Vue, options) {
		[
			//Modais
			// angelEdit,
			// angelView,
			// angelDelete,
			booksEdit,

			sectorEdit,
			sectorDelete,

			peopleEdit,
			peopleView,
			peopleDelete,

			peopleEditManager,
			peopleViewManager,
			peopleDeleteManager,

			schoolEdit,
			schoolView,
			schoolDelete,

			gradeEdit,
			gradeView,
			gradeDelete,

			gradeEditManager,
			gradeViewManager,
			gradeDeleteManager,

			gradeEditConsultant,
			gradeViewConsultant,
			gradeDeleteConsultant,

			projectEdit,
			projectView,
			projectDelete,

			adotpionEditon,
			adotpionEditonManager,

			// Alert,
			// Breadcrumbs,
			Chart,
			//Chat,
			//Checkbox,
			DataTable,
			//Feed,

			//MediumEditor,

			Modal,
			// MultiSelect,
			// PreLoader,
			// ProfileCard,
			ProgressBar,
			// RadioButton,
			// Scrollbar,
			// SimpleSelect,
			// Slider,
			//Popover,

			// SocialNews,
			// Switch,
			// Tabs,
			// Widget,
			// Wizard,

			// Tooltip,

			TreeRoot,
			TreeCategory,
			TreeNode,

			// FileUpload,
			// VuesticDropdown,
			// DatePicker,
			// Card,
			// Tag,
			// TagGroup,
			// VuesticAccordion,
			// VuesticCollapse,
			// VuesticTimeline,
			// VuesticTimelineItem,
			// VuesticPopup,
			// VuesticContainer,
			// VuestVaLayout,
			// VuesticFlex,

			// DDH
			inputEdit,
			inputNormal,
			inputMoney,
			radioButton,
			menuTabs,
			selectNormal,
			selectMutiple,

			statusBar,
			nextKit,
			deliveredKit,
			followModal,
			nameModal,
			// shoppingBox,
			// resumeBox,
			inputSimple,
		].forEach((component) => {
			Vue.component(component.name, component);
		});

		Vue.mixin(VuesticToasted);

		//Vue.directive('dropdown', Dropdown)
		//Vue.directive('sticky-scroll', StickyScroll)
	},
};

export default VuesticPlugin;
