<template>
	<div class="my-account">
		<div class="va-row">
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding">
							<div class="">
								<div class="va-row">
									<!-- {{school}} -->

									<div class="error-label" v-if="errors.length > 0">
										{{ errors[0].message }}
									</div>

									<!-- <div class="tab">
										<button
											:class="upload ? '' : 'active'"
											@click="changeUploadStatus(false)"
										>
											Cadastrar turma
										</button>
									</div> -->

									<div class="tab-item flex xs12" v-if="!upload">
										<form @submit.prevent="saveGrade" class="va-row">
											<div class="flex xs12 md12 no-padding">
												<div class="va-row">
													<div
														class="flex xs6 no-padding-left no-padding-bottom"
													>
														<select-normal
															:items="grades_types"
															name="typeName"
															label="Selecione um ano"
															v-validate="'required'"
															:disabled="false"
															:required="true"
															:data-vv-as="'ano'"
															:value="selectedGrade"
															:error="errors.first('typeName')"
															@change="onChangeGradeType($event)"
														/>
													</div>

													<div
														class="flex xs6 no-padding-right no-padding-bottom"
													>
														<input-normal
															type="text"
															label="Complemento"
															name="complement"
															v-validate="'required'"
															:value="name"
															:required="true"
															:disabled="false"
															:error="errors.first('complement')"
															:data-vv-as="'complemento'"
															@input="name = $event.target.value"
														/>
													</div>
												</div>

												<select-normal
													:items="products"
													name="product"
													label="Selecione o produto principal"
													v-validate="'required'"
													:required="true"
													:disabled="false"
													:data-vv-as="'produto'"
													:value="selectedProductMain"
													:error="errors.first('product')"
													@change="onChangeProduct($event)"
												/>

												<select-normal
													:items="products"
													name="product"
													label="Selecione o produto secundário"
													v-if="
														managerCredential.settings.has_multiple_products
													"
													:required="true"
													:disabled="false"
													:data-vv-as="'produto'"
													:value="selectedProductSecondary"
													@change="onChangeProductSecondary($event)"
												/>

												<select-normal
													:items="teachers"
													name="teacher"
													label="Selecione um professor"
													v-validate="'required'"
													:disabled="false"
													:data-vv-as="'professor'"
													:value="selectedTeacher"
													:error="errors.first('teacher')"
													@change="onChangeTeacher($event)"
												/>

												<div v-if="newTeacher">
													<h4>Professor</h4>

													<input-normal
														type="text"
														label="Nome"
														name="teacher_name"
														:disabled="false"
														v-validate="'required'"
														:required="true"
														:data-vv-as="'teacher_name'"
														:value="user.name"
														:error="errors.first('teacher_name')"
														@input="user.name = $event.target.value"
													/>

													<input-normal
														type="email"
														label="E-mail"
														name="email"
														:disabled="typeSend === 'put' ? true : false"
														v-validate="'required|email'"
														:data-vv-as="'email'"
														:required="true"
														:value="user.email"
														:error="errors.first('email')"
														@input="user.email = $event.target.value"
													/>
												</div>
											</div>
											<hr />

											<div class="error text-center">
												{{ errors.first("error") }}
											</div>

											<div class="flex md12 text-right">
												<button type="submit" class="btn btn-change right">
													SALVAR
												</button>
												<button
													type="button"
													class="btn btn-primary right cancel"
													v-on:click="close()"
												>
													CANCELAR
												</button>
											</div>
										</form>
									</div>

									<div class="tab-item flex xs12" v-if="upload">
										<div class="" v-if="uploadStep === 1">
											<br />

											<p>
												Você pode cadastrar automaticamente vários alunos
												enviando uma lista de alunos formatada corretamente.<br />
												Leia as seguintes instruções sobre como formatar o
												arquivo:
											</p>

											<div class="va-row">
												<div class="flex xs6">
													<b>1. Formato do arquivo:</b>
													Arquivo .CSV

													<br /><br />

													<b>2. Colunas</b> (campo obrigatório):

													<ul>
														<li>Nome da turma</li>
														<li>Período</li>
														<li>Tipo de turma</li>
														<li>Tamanho da turma</li>
														<li>Prazo</li>
														<li>Responsável</li>
														<li>E-mail</li>

														<!-- <li>Nome do estudante</li>
                                <li>Level</li>
                                <li>Gênero</li> -->
													</ul>
													<!-- Não obrigatório
                              <ul>
                                <li>Data de aniversário</li>
                              </ul> -->

													<br />
													<b>3. Exemplo de como preencher a lista</b>
												</div>

												<div class="flex xs12">
													<div class="upload-box">
														<h3>Baixe o arquivo-modelo aqui</h3>
														<a
															href="https://docs.google.com/spreadsheets/d/1zHAxiQ_xTY5TgdvoACLPfKfkbkwU_uTC2Nljk-Mihv8/edit#gid=0"
															target="_blank"
															class="btn btn-primary cancel"
															><span class="fa fa-chevron-down"></span> FAZER
															DOWNLOAD</a
														>
													</div>
												</div>

												<!-- <div class="flex md12 text-right">
                              <button @click="changeStepsUpload(2)" class="btn btn-primary right">AVANÇAR</button>
                            </div> -->
											</div>
										</div>

										<div class="" v-if="uploadStep === 2">
											<div class="upload-box">
												<h3>
													Faça o upload da versão mais atualizada do seu arquivo
													de turmas
												</h3>
												<button
													v-on:click="clickOnFile()"
													class="btn btn-primary cancel"
												>
													<span class="fa fa-chevron-up"></span> FAZER UPLOAD
												</button>

												<!-- <button v-on:click="clickOnFile()"
                                      class="btn btn-change">
                                      UPLOAD PLANILHA
                                  </button>
                                   -->
												<input
													type="file"
													id="files"
													class="hide"
													ref="files"
													v-on:change="handleFileUpload()"
												/>
											</div>

											<div class="flex xs12 no-padding-right">
												<button
													type="button"
													class="btn btn-primary right cancel"
													v-on:click="close()"
												>
													CANCELAR
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	const axios = require("axios");

	export default {
		name: "grade-edit-manager",
		directives: { mask },
		components: {
			// vuescroll
			// VueScrollTo
			// DataVisualisationTab,
		},

		props: ["grade", "school", "typeSend"],

		data() {
			return {
				openAddressField: false,
				cepSearch: false,

				grades_types: [],
				teachers: [],
				products: [],

				newTeacher: false,

				selectedGrade: null,
				selectedTeacher: null,

				selectedProductMain: null,
				selectedProductSecondary: null,

				name: null,

				user: {
					email: null,
					name: null,
				},

				upload: false,
				uploadStep: 1,
			};
		},

		computed: {
			...mapGetters({
				managerCredential: "managerCredential",
				//consultantCredential: 'consultantCredential',
				// ...
			}),
		},

		mounted() {
			const self = this;
			let token = self.managerCredential.token;

			let config = {
				headers: {
					Authorization: "Bearer " + token,
				},
			};

			//this.grade.school_id = this.school.id
			axios
				.get(process.env.VUE_APP_ROOT_API + "/grades_types", config)
				.then((response) => {
					var list = [];

					response.data.data.grade_types.forEach((element) => {
						var itemList = {
							key: element.id,
							value: element.name,
						};
						//if (list.indexOf(element.shippingAddressId) === -1) {
						list.push(itemList);
						//}
					});

					self.grades_types = list;
				})
				.catch((e) => {
					this.errors.push(e);
				});

			let project_id = self.managerCredential.currentAdoption.id;

			axios
				.get(process.env.VUE_APP_ROOT_API + "/teachers/" + project_id, config)
				.then((response) => {
					var list = [];

					response.data.data.teachers.forEach((element) => {
						var itemList = {
							key: element.id,
							value: element.name,
						};
						//if (list.indexOf(element.shippingAddressId) === -1) {
						list.push(itemList);
						//}
					});

					self.teachers = list;
					self.teachers.push({
						key: "novo",
						value: "Adicionar novo professor",
					});
				})
				.catch((e) => {
					this.errors.push(e);
				});

			axios
				.get(
					process.env.VUE_APP_ROOT_API + "/products/available/" + project_id,
					config
				)
				.then((response) => {
					var list = [];

					response.data.data.products.forEach((element) => {
						var itemList = {
							key: element.id,
							value: element.name,
						};
						//if (list.indexOf(element.shippingAddressId) === -1) {
						list.push(itemList);
						//}
					});

					if (self.managerCredential.settings.has_multiple_products_in_grade) {
						list.unshift({
							key: "all",
							value: "Todos - Liberar a escolha pelo professor",
						});
					}

					self.products = list;
				})
				.catch((e) => {
					this.errors.push(e);
				});
		},

		//
		methods: {
			close: function () {
				this.$root.$emit("closeModal");
			},

			addGrade: function (school, grade) {
				this.$root.$emit("addGrade", school, grade);
			},

			editGrade: function (school, grade) {
				this.$root.$emit("editGrade", school, grade);
			},

			clickOnFile() {
				this.$refs.files.click();
			},

			changeStepsUpload(step) {
				this.uploadStep = step;
			},

			changeUploadStatus(status) {
				this.upload = status;
			},

			onChangeGradeType(event) {
				this.selectedGrade = event.target.value;
				//this.address = event.target.value
			},

			onChangeProduct(event) {
				this.selectedProductMain = event.target.value;
				//this.address = event.target.value
			},

			onChangeProductSecondary(event) {
				this.selectedProductSecondary = event.target.value;
				//this.address = event.target.value
			},

			onChangeTeacher(event) {
				const self = this;

				if (event.target.value === "novo") {
					self.newTeacher = true;
					self.selectedTeacher = event.target.value;
				} else {
					self.newTeacher = false;
					self.selectedTeacher = event.target.value;
				}
				//this.address = event.target.value
			},

			onChangePeriod(event) {
				this.grade.period_id = event.target.value;
				//this.address = event.target.value
			},

			onChangeGender(event) {
				this.grade.teacher.gender = event.target.value;
				//this.address = event.target.value
			},

			onChangeReceiveEmail(event) {
				this.grade.teacher.send_email = event.target.value;
				//this.address = event.target.value
			},

			submitFiles() {
				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(process.env.VUE_APP_ROOT_API + "/grades/import", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + this.userCredential.token,
						},
					})
					.then(function () {
						alert("Upload feito com sucesso");
					})
					.catch(function () {
						alert("Erro, upload falhou");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			// updateSubscriptionSend () {
			//   this.subscriptionsData.forEach(element => {
			//     if (element.shippingAddressId === this.address.id) {
			//       let subscriptionId = { 'id': element.id }
			//       this.subscriptionSend.subscriptions.push(subscriptionId)
			//     }
			//   })
			// },

			created: function () {
				const self = this;

				if (
					this.school.address.zip_postal_code.length === 9 &&
					self.cepSearch === false
				) {
					self.cepSearch = true;

					axios
						.get(
							`https://api.pagar.me/1/zipcodes/${this.school.address.zip_postal_code}`
						)
						.then((res) => {
							this.school.address.state = res.data.state;
							this.school.address.city = res.data.city;
							this.school.address.district = res.data.neighborhood;
							this.school.address.address1 = res.data.street;

							if (res.data.street === undefined) {
								this.openAddressField = true;
							}

							self.cepSearch = false;
						})
						.catch((error) => {
							this.openAddressField = true;
							self.cepSearch = true;

							//console.log(error)
						});
				}
			},

			updateAndVerifyCep({ e, target }) {
				this.school.address.zip_postal_code = target.value;
				this.created();
			},

			selectSubscription(id, index) {
				this.subscriberEditing = true;
				let subscriptionId = { id: id };

				// alert(this.subscriptionsData[index].shippingAddressId);
				// this.subscriptionsData[index].shippingAddressId = this.address.id

				var index = this.subscriptionSend.subscriptions.indexOf(
					this.subscriptionSend.subscriptions.find((c) => {
						return c.id === id;
					})
				);
				if (index > -1) {
					this.subscriptionSend.subscriptions.splice(index, 1);
				} else {
					this.subscriptionSend.subscriptions.push(subscriptionId);
				}
			},

			getSelectedId(id) {
				let response = false;

				let objectId = this.subscriptionSend.subscriptions.find((c) => {
					return c.id === id;
				});
				if (objectId) {
					if (objectId.id === id) {
						response = true;
					}
				}

				// if(id == this.address.id){
				//   response = true
				// }

				return response;
			},

			deleteAddress() {
				const self = this;

				axios
					.delete(
						process.env.VUE_APP_ROOT_API +
							"/customer/address/" +
							this.address.id
					)
					.then((response) => {
						self.$router.push({ name: "adresses" });
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			saveGrade() {
				const self = this;

				let token = self.managerCredential.token;

				let config = {
					headers: {
						Authorization: "Bearer " + token,
					},
				};

				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line
						let url =
							self.typeSend == "put"
								? process.env.VUE_APP_ROOT_API + "/grades/" + self.grade.id
								: process.env.VUE_APP_ROOT_API + "/grades";

						var selectProducts = [self.selectedProductMain];

						if (
							self.selectedProductSecondary !== null &&
							self.selectedProductSecondary !== ""
						) {
							var selectProducts = [
								self.selectedProductMain,
								self.selectedProductSecondary,
							];
						}

						let request = {
							grade_type_id: self.selectedGrade,
							name: self.name,
							school_id: self.managerCredential.currentAdoption.school_id,
							project_id: self.managerCredential.currentAdoption.id,
							client_id: self.managerCredential.user.client_id,
							products: selectProducts,
							user_id: self.selectedTeacher,
						};

						if (self.newTeacher) {
							request.user = self.user;
						}

						// let data = this.jsonCopy(self.grade)
						// data.teacher = this.grade.teacher

						// var dateDeadline = data.deadline;
						// data.deadline = this.formatDate(dateDeadline);

						// if(data.teacher.birthdate && data.teacher.birthdate !== ""){
						//   var dateBirthdate = data.teacher.birthdate;
						//   data.teacher.birthdate = this.formatDate(dateBirthdate);
						//   data.school_id = this.school.id
						// }

						axios({
							method: self.typeSend.toUpperCase(),
							url: url,
							headers: config.headers,
							data: request,
						})
							.then(function (response) {
								if (response.data.success !== false) {
									response.data.data.grade.teacher =
										response.data.data.grade.teacher.name;
									response.data.data.grade.books_completed = 0;
									response.data.data.grade.books_ordered = 0;
									response.data.data.grade.status = "em execução";
									//response.data.data.grade.deadline = "em execução";

									if (self.typeSend == "put") {
										self.editGrade(response.data.data.grade);
										self.close();
									} else {
										self.addGrade(response.data.data.grade);
										self.close();
									}
								} else {
									self.errors.push(response.data.errors[0]);
								}
							})
							.catch(function (error) {
								//console.log(error)

								let fieldError = {
									field: "error",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},

			formatDate(hour) {
				var hourFormated = hour.split("/");
				return hourFormated[2] + "-" + hourFormated[1] + "-" + hourFormated[0];
				//return "YYYY-MM-DD";
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},

			jsonCopy(src) {
				return JSON.parse(JSON.stringify(src));
			},
		},
	};
</script>
<style lang="scss" scoped>
	.hide {
		display: none;
	}
	.upload-box {
		border: 1px dashed #ccc;
		text-align: center;
		border-width: 2px;
		border-radius: 10px;
		padding-bottom: 40px;
	}

	// .tab-item {
	// 	border: 1px solid #ccc;
	// }

	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.fiscal-notes ::v-deep .form-group:last-child {
		margin-bottom: 10px !important;
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// Default of page

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 30px 30px 10px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
</style>
