<template>
	<div class="my-account">
		<div class="va-row">
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding">
							<div class>
								<div class="va-row">
									<div class="flex md12 no-padding">
										<div class="flex md12 item-steps">
											<button
												class="btn-clear"
												:class="project.step === 1 ? 'active' : ''"
											>
												1. Produto
											</button>
											|
											<button
												class="btn-clear"
												:class="project.step === 2 ? 'active' : ''"
											>
												2.
												{{
													consultantCredential.settings.hide_payment_data
														? "Responsável"
														: "Faturamento"
												}}
											</button>
											|
											<button
												class="btn-clear"
												:class="project.step === 3 ? 'active' : ''"
											>
												3. Entrega
											</button>
										</div>
									</div>

									<hr class="header-line" />

									<div class="flex xs12 no-padding" v-if="!upload">
										<div class="flex xs12 md12 no-padding">
											<div class v-if="project.step === 1">
												<form @submit.prevent="saveStep1" class="va-row">
													<div class="va-row">
														<div class="flex md12 no-padding">
															<div class="va-row">
																<div class="flex md12">
																	<h4>Informações gerais</h4>
																</div>
															</div>

															<input-normal
																type="text"
																label="Nome da escola"
																name="name"
																v-validate="'required'"
																:disabled="false"
																:data-vv-as="'nome'"
																:value="project.name"
																:error="errors.first('name')"
																@input="project.name = $event.target.value"
															/>

															<select-normal
																:items="consultantList"
																:value="project.user_id"
																label="Selecione um consultor"
																name="user_id"
																@change="onChangeConsultantMethod($event)"
															/>

															<select-normal
																:items="[
																	{ key: true, value: 'Sim' },
																	{ key: false, value: 'Não' },
																]"
																:value="project.teacher_book"
																label="Professor irá receber livro?"
																name="typeName"
																@change="onChangeTeacherBook($event)"
															/>
														</div>

														<div class="flex md12 no-padding">
															<h4>Data e cronograma</h4>

															<input-normal
																type="text"
																label="Data do evento de adoção"
																name="deadline"
																v-validate="'required'"
																v-mask="['##/##/####']"
																:required="true"
																:disabled="false"
																:data-vv-as="'data limite'"
																:value="project.deadline"
																:error="errors.first('deadline')"
																@input="updateDataSchedule"
															/>
														</div>
													</div>

													<div class="va-row">
														<div class="flex md12 no-padding">
															<h4>Produtos</h4>

															<div class="flex md12 no-padding products-limit">
																<div
																	v-for="(product, index) in products"
																	:key="index"
																	class="va-row"
																>
																	<div
																		class="flex no-padding"
																		:class="
																			getSelectedId(product.id)
																				? 'md10'
																				: 'md12'
																		"
																	>
																		<RadioButton
																			class="box-payment box-method"
																			:value="false"
																			:selected="getSelectedId(product.id)"
																			@click="selectProduct(product.id)"
																		>
																			<div>{{ product.name }}</div>
																		</RadioButton>
																	</div>

																	<div
																		class="flex md2 product-input-box"
																		v-if="getSelectedId(product.id)"
																	>
																		<input-normal
																			type="number"
																			label="Qtd."
																			:name="'product' + product.id"
																			v-validate="'required'"
																			:disabled="false"
																			:data-vv-as="'quantidade'"
																			:value="getValueProductExtra(product)"
																			:error="
																				errors.first('product' + product.id)
																			"
																			@input="
																				updateProductQuantity(
																					$event,
																					product.id
																				)
																			"
																		/>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="flex md12"
															v-if="
																managerCredential.settings.hide_payment_data ===
																	false ||
																!consultantCredential.settings.hide_payment_data
															"
														>
															<h4>Valores</h4>

															<input-normal
																type="number"
																label="Total de livros"
																name="books"
																v-validate="'required'"
																:disabled="true"
																:data-vv-as="'número de livros'"
																:value="project.books"
																:error="errors.first('books')"
																@input="updatePriceQuantity"
															/>

															<input-money
																type="text"
																label="Preço unitário do livro"
																name="book_price"
																id="input-price"
																v-validate="'required'"
																:disabled="false"
																:data-vv-as="'preço do livros'"
																:value="project.book_price"
																:error="errors.first('book_price')"
																@input="updatePrice"
															/>

															<div class="flex md12 dates">
																<label>Valor total da adoção</label>
																<span>R$ {{ finalPrice }}</span>
															</div>
														</div>
													</div>

													<div class="flex md12 text-right">
														<div class="error text-center">
															{{ errors.first("error-step-1") }}
														</div>
														<button type="submit" class="btn btn-primary right">
															AVANÇAR
														</button>
														<button
															type="button"
															class="btn btn-primary right cancel"
															v-on:click="close()"
														>
															CANCELAR
														</button>
													</div>
												</form>
											</div>

											<div v-if="project.step === 2">
												<form @submit.prevent="saveStep2" class="va-row">
													<div class="va-row">
														<div
															class="flex md6"
															v-if="
																!consultantCredential.settings.hide_payment_data
															"
														>
															<h4>Informações fiscais</h4>

															<input-normal
																id="input-razao-social"
																type="text"
																label="Razão social"
																name="name"
																v-validate="'required'"
																:disabled="false"
																:data-vv-as="'nome'"
																:required="'true'"
																:value="project.razao_social"
																:error="errors.first('name')"
																@input="
																	project.razao_social = $event.target.value
																"
															/>

															<input-normal
																id="input-cnpj"
																type="cpf"
																label="CNPJ"
																name="document"
																v-validate="'required'"
																v-mask="[
																	'###.###.###-##',
																	'##.###.###/####-##',
																]"
																:disabled="false"
																:required="'true'"
																:data-vv-as="'CPF/CNPJ'"
																:value="project.cnpj"
																:error="errors.first('document')"
																@input="project.cnpj = $event.target.value"
															/>

															<input-normal
																id="input-ie"
																type="text"
																label="Inscrição estadual"
																name="ie"
																v-validate="'required'"
																:required="'true'"
																:disabled="false"
																:data-vv-as="'incrição'"
																:value="project.ie"
																:error="errors.first('ie')"
																@input="project.ie = $event.target.value"
															/>
														</div>

														<div
															class="flex md6"
															v-if="
																!consultantCredential.settings.hide_payment_data
															"
														>
															<h4>Endereço de faturamento</h4>

															<input-normal
																id="input-cep"
																type="cep"
																label="CEP"
																name="cep"
																:required="'true'"
																v-mask="['#####-###']"
																:disabled="cepSearch"
																:value="project.address.zip_postal_code"
																@input="updateAndVerifyCep"
															/>

															<div
																class="flex md12 no-padding"
																v-if="openAddressField"
															>
																<select-normal
																	:items="[
																		{ value: 'Acre', key: 'AC' },
																		{ value: 'Alagoas', key: 'AL' },
																		{ value: 'Amapá', key: 'AP' },
																		{ value: 'Amazonas', key: 'AM' },
																		{ value: 'Bahia', key: 'BA' },
																		{ value: 'Ceará', key: 'CE' },
																		{ value: 'Distrito Federal', key: 'DF' },
																		{ value: 'Espírito Santo', key: 'ES' },
																		{ value: 'Goiás', key: 'GO' },
																		{ value: 'Maranhão', key: 'MA' },
																		{ value: 'Mato Grosso', key: 'MT' },
																		{ value: 'Mato Grosso do Sul', key: 'MS' },
																		{ value: 'Minas Gerais', key: 'MG' },
																		{ value: 'Pará', key: 'PA' },
																		{ value: 'Paraíba', key: 'PB' },
																		{ value: 'Paraná', key: 'PR' },
																		{ value: 'Pernambuco', key: 'PE' },
																		{ value: 'Piauí', key: 'PI' },
																		{ value: 'Rio de Janeiro', key: 'RJ' },
																		{ value: 'Rio Grande do Norte', key: 'RN' },
																		{ value: 'Rio Grande do Sul', key: 'RS' },
																		{ value: 'Rondônia', key: 'RO' },
																		{ value: 'Roraima', key: 'RR' },
																		{ value: 'Santa Catarina', key: 'SC' },
																		{ value: 'São Paulo', key: 'SP' },
																		{ value: 'Sergipe', key: 'SE' },
																		{ value: 'Tocantins', key: 'TO' },
																	]"
																	:value="project.address.state"
																	label="Selecione um estado"
																	name="typeName"
																	@change="onChangeState($event)"
																/>

																<input-normal
																	type="text"
																	label="Endereço"
																	name="address1"
																	:value="project.address.address1"
																	:disabled="false"
																	@input="
																		project.address.address1 =
																			$event.target.value
																	"
																/>
																<input-normal
																	type="text"
																	label="Bairro"
																	name="district"
																	:value="project.address.district"
																	:disabled="false"
																	@input="
																		project.address.district =
																			$event.target.value
																	"
																/>
																<input-normal
																	type="text"
																	label="Cidade"
																	name="city"
																	:value="project.address.city"
																	:disabled="false"
																	@input="
																		project.address.city = $event.target.value
																	"
																/>
																<input-normal
																	type="number"
																	label="Número"
																	name="number"
																	:maxlength="5"
																	:max="9999"
																	:required="'true'"
																	:disabled="false"
																	:value="project.address.address_number"
																	@input="
																		project.address.address_number =
																			$event.target.value
																	"
																/>
															</div>
														</div>
													</div>

													<div class="flex md12"></div>
													<h4>Informações do Responsável</h4>
													<br />

													<input-normal
														type="text"
														label="Nome"
														name="name"
														v-validate="'required'"
														:disabled="false"
														:data-vv-as="'nome'"
														:value="project.contact_name"
														:error="errors.first('name')"
														@input="project.contact_name = $event.target.value"
													/>

													<input-normal
														type="text"
														label="E-mail"
														name="email"
														v-validate="'required'"
														:disabled="false"
														:data-vv-as="'contact_email'"
														:value="project.contact_email"
														:error="errors.first('email')"
														@input="project.contact_email = $event.target.value"
													/>

													<input-normal
														type="text"
														label="Telefone"
														name="contact_phone"
														:required="'true'"
														v-mask="['(##) ####-####', '(##) #####-####']"
														:disabled="false"
														:value="project.contact_phone"
														@input="project.contact_phone = $event.target.value"
													/>

													<select-normal
														:items="contractMethods"
														:value="project.contract_method_id"
														label="Tipo de contrato"
														name="typeName"
														:required="'true'"
														@change="onChangeContractMethod($event)"
													/>

													<select-normal
														:items="paymentMethods"
														:value="project.payment_method_id"
														label="Forma de pagamento"
														name="typeName"
														:required="'true'"
														@change="onChangePaymentMethod($event)"
													/>

													<div class="flex md12 text-right">
														<div class="error text-center">
															{{ errors.first("error-step-2") }}
														</div>
														<button type="submit" class="btn btn-primary right">
															AVANÇAR
														</button>
														<button
															type="button"
															class="btn btn-primary right cancel"
															v-on:click="close()"
														>
															TERMINAR DEPOIS
														</button>
													</div>
												</form>
											</div>
										</div>

										<div v-if="project.step === 3">
											<h4
												v-if="
													managerCredential.settings.hide_payment_data ===
														false ||
													consultantCredential.settings.hide_payment_data
												"
											>
												Endereço de entrega
											</h4>

											<div
												v-if="
													managerCredential.settings.hide_payment_data ===
														false ||
													consultantCredential.settings.hide_payment_data
												"
											>
												O endereço de entrega já está definido no contrato
											</div>

											<form @submit.prevent="saveStep3" class="va-row">
												<input-normal
													type="cep"
													label="CEP"
													name="cep"
													:required="'true'"
													v-mask="['#####-###']"
													:disabled="cepSearch"
													:value="project.shipping_address.zip_postal_code"
													@input="updateAndVerifyCepShipping"
													v-if="
														managerCredential.settings.hide_payment_data ===
															false ||
														!consultantCredential.settings.hide_payment_data
													"
												/>

												<div
													class="flex md12 no-padding"
													v-if="
														(openShippingAddressField &&
															managerCredential.settings.hide_payment_data ===
																false) ||
														!consultantCredential.settings.hide_payment_data
													"
												>
													<select-normal
														:items="[
															{ value: 'Acre', key: 'AC' },
															{ value: 'Alagoas', key: 'AL' },
															{ value: 'Amapá', key: 'AP' },
															{ value: 'Amazonas', key: 'AM' },
															{ value: 'Bahia', key: 'BA' },
															{ value: 'Ceará', key: 'CE' },
															{ value: 'Distrito Federal', key: 'DF' },
															{ value: 'Espírito Santo', key: 'ES' },
															{ value: 'Goiás', key: 'GO' },
															{ value: 'Maranhão', key: 'MA' },
															{ value: 'Mato Grosso', key: 'MT' },
															{ value: 'Mato Grosso do Sul', key: 'MS' },
															{ value: 'Minas Gerais', key: 'MG' },
															{ value: 'Pará', key: 'PA' },
															{ value: 'Paraíba', key: 'PB' },
															{ value: 'Paraná', key: 'PR' },
															{ value: 'Pernambuco', key: 'PE' },
															{ value: 'Piauí', key: 'PI' },
															{ value: 'Rio de Janeiro', key: 'RJ' },
															{ value: 'Rio Grande do Norte', key: 'RN' },
															{ value: 'Rio Grande do Sul', key: 'RS' },
															{ value: 'Rondônia', key: 'RO' },
															{ value: 'Roraima', key: 'RR' },
															{ value: 'Santa Catarina', key: 'SC' },
															{ value: 'São Paulo', key: 'SP' },
															{ value: 'Sergipe', key: 'SE' },
															{ value: 'Tocantins', key: 'TO' },
														]"
														:value="project.shipping_address.state"
														label="Selecione um estado"
														name="typeName"
														@change="onChangeStateShipping($event)"
													/>

													<input-normal
														type="text"
														label="Endereço"
														name="address1"
														:value="project.shipping_address.address1"
														:disabled="false"
														@input="
															project.shipping_address.address1 =
																$event.target.value
														"
													/>
													<input-normal
														type="text"
														label="Bairro"
														name="district"
														:value="project.shipping_address.district"
														:disabled="false"
														@input="
															project.shipping_address.district =
																$event.target.value
														"
													/>
													<input-normal
														type="text"
														label="Cidade"
														name="city"
														:value="project.shipping_address.city"
														:disabled="false"
														@input="
															project.shipping_address.city =
																$event.target.value
														"
													/>
													<input-normal
														type="number"
														label="Número"
														name="number"
														:maxlength="5"
														:max="9999"
														:required="'true'"
														:disabled="false"
														:value="project.shipping_address.address_number"
														@input="
															project.shipping_address.address_number =
																$event.target.value
														"
													/>
												</div>

												<div
													class="flex md12 text-center"
													v-if="
														managerCredential.settings.hide_payment_data ===
															false ||
														!consultantCredential.settings.hide_payment_data
													"
												>
													<h4>ou</h4>

													<button
														class="btn btn-primary"
														@click.stop.prevent="useSameAddress()"
													>
														MESMO ENDEREÇO DE FATURAMENTO
													</button>
												</div>

												<div class="flex md12 text-right">
													<div class="error text-center">
														{{ errors.first("error-step-3") }}
													</div>

													<button type="submit" class="btn btn-primary right">
														SALVAR
													</button>
													<button
														type="button"
														class="btn btn-primary right cancel"
														v-on:click="close()"
														v-if="
															managerCredential.settings.hide_payment_data ===
																false ||
															!consultantCredential.settings.hide_payment_data
														"
													>
														TERMINAR DEPOIS
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
								<hr />
							</div>

							<div class="flex xs12" v-if="upload">
								<div class v-if="uploadStep === 1">
									<!-- <h3>Maternal 2 - Período Manhã - 2017</h3> -->

									<br />

									<p>
										Você pode cadastrar automaticamente vários alunos enviando
										uma lista de alunos formatada corretamente.
										<br />Leia as seguintes instruções sobre como formatar o
										arquivo:
									</p>

									<div class="va-row">
										<div class="flex xs6">
											<b>1. Formato do arquivo:</b>

											Arquivo .CSV
											<br />
											<br />

											<b>2. Colunas</b>
											(campo obrigatório):
											<ul>
												<li>Nome da escola</li>
												<li>Tipo da escola</li>
												<li>Endereço</li>
												<li>Numero</li>
												<li>Bairro</li>
												<li>Complemento</li>
												<li>CEP</li>
												<li>Cidade</li>
												<li>Estado</li>
												<li>Responsável</li>
												<li>E-mail</li>
												<li>Telefone</li>
											</ul>

											<br />
											<b> 3. Exemplo de como preencher a lista </b>
										</div>

										<div class="flex xs12">
											<div class="upload-box">
												<h3>Baixe o arquivo-modelo aqui</h3>
												<a
													href="https://docs.google.com/spreadsheets/d/17RO-TSMUU2jD5vxFdEEdlds10Cm07rKvvP-lwprnH2Q/edit#gid=0"
													target="blank"
													class="btn btn-primary cancel"
												>
													<span class="fa fa-chevron-down"></span> FAZER
													DOWNLOAD
												</a>
											</div>
										</div>

										<div class="flex md12 text-right">
											<button
												@click="changeStepsUpload(2)"
												class="btn btn-primary right"
											>
												AVANÇAR
											</button>
										</div>
									</div>
								</div>

								<div class v-if="uploadStep === 2">
									<div class="upload-box">
										<h3>
											Faça o upload da versão mais atualizada do seu arquivo de
											escolas
										</h3>
										<button
											v-on:click="clickOnFile()"
											class="btn btn-primary cancel"
										>
											<span class="fa fa-chevron-up"></span> FAZER UPLOAD
										</button>

										<!-- <button v-on:click="clickOnFile()"
                                      class="btn btn-change">
                                      UPLOAD PLANILHA
                                  </button>
                    -->
										<input
											type="file"
											id="files"
											class="hide"
											ref="files"
											v-on:change="handleFileUpload()"
										/>
									</div>

									<div class="flex xs12 no-padding-right">
										<button
											type="button"
											class="btn btn-primary right cancel"
											v-on:click="close()"
										>
											CANCELAR
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	const axios = require("axios");

	export default {
		name: "adoption-edit",
		directives: { mask },
		components: {
			// vuescroll
			// VueScrollTo
			// DataVisualisationTab,
		},

		data() {
			return {
				products: [],
				productsExtra: [],

				salesPersons: [],
				adoptionEdit: null,

				contractMethods: [],
				paymentMethods: [],
				consultantList: [],

				project_id: null,

				openAddressField: false,
				openShippingAddressField: false,

				cepSearch: false,
				types: [],

				school_types: [],
				payment_methods: [],

				upload: false,
				uploadStep: 1,

				grades: [],
				gradesTypes: [],

				datesDeadline: null,
				finalPrice: 0,

				paymentDetails: {
					document: "",
					installment: [],
				},

				currentGrade: null,

				extras: [],

				money: {
					decimal: ",",
					thousands: ".",
					prefix: "R$ ",
					suffix: "",
					precision: 2,
					masked: false /* doesn't work with directive */,
				},

				project: {
					project_id: null,

					name: "",
					deadline: "",
					books: 0,
					book_price: 0.0,
					teacher_book: null,
					user_id: null,
					products: [],

					cnpj: "",
					address: {
						district: "",
						address_number: "",
						state: "",
						city: "",
						address1: "",
						address2: "",
						zip_postal_code: "",
					},
					contact_name: "",
					contact_email: "",
					contact_phone: "",
					project_id: "",
					contract_method_id: 1,
					payment_method_id: 1,
					ie: "",
					razao_social: "",

					shipping_address: {
						district: "",
						address_number: "",
						state: "",
						city: "",
						address1: "",
						address2: "",
						zip_postal_code: "",
					},

					user: {
						name: "",
					},

					type: "post",
					step: 1,
				},
			};
		},

		computed: {
			...mapGetters({
				consultantCredential: "consultantCredential",
				// ...
			}),
		},

		created() {
			const self = this;

			this.$store.watch(
				function (state) {
					return state.app.consultantCredential;
				},
				function () {
					self.setVuex();
				},
				{
					immediate: true,
					deep: true, //add this if u need to watch object properties change etc.
				}
			);
		},

		mounted() {
			const self = this;

			this.getProducts();
			//this.getSchedule();

			this.getContractMethods();
			this.getPaymentMethods();
			this.getSalesPerson();
		},

		methods: {
			setVuex() {
				const self = this;

				self.finalPrice = 0;
				self.productsExtra = [];

				if (
					self.consultantCredential.currentAdoption &&
					self.consultantCredential.currentAdoption.length !== 0
				) {
					self.project.id = self.consultantCredential.currentAdoption.id;

					self.project.name = self.consultantCredential.currentAdoption.name;
					self.project.deadline =
						self.consultantCredential.currentAdoption.deadline;
					self.project.books = self.consultantCredential.currentAdoption.books;
					self.project.book_price =
						"R$ " +
						self.consultantCredential.currentAdoption.book_price.toFixed(2);

					self.project.teacher_book =
						self.consultantCredential.currentAdoption.teacher_book;
					self.project.user_id =
						self.consultantCredential.currentAdoption.user_id;
					self.project.products =
						self.consultantCredential.currentAdoption.products;
					self.project.cnpj = self.consultantCredential.currentAdoption.cnpj;

					// SET PRODUCTS

					var listProducts = [];
					if (self.consultantCredential.currentAdoption.products) {
						self.consultantCredential.currentAdoption.products.forEach(
							(element) => {
								var newProduct = { id: element.id, quantity: element.quantity };
								listProducts.push(newProduct);
							}
						);
					}

					self.productsExtra = listProducts;

					// SET VALUE
					self.calculateValue();
					self.project.type = self.consultantCredential.currentAdoption.type;

					if (self.consultantCredential.currentAdoption.address) {
						self.project.address = {
							district:
								self.consultantCredential.currentAdoption.address.district,
							address_number:
								self.consultantCredential.currentAdoption.address
									.address_number,
							state: self.consultantCredential.currentAdoption.address.state,
							city: self.consultantCredential.currentAdoption.address.city,
							address1:
								self.consultantCredential.currentAdoption.address.address1,
							address2:
								self.consultantCredential.currentAdoption.address.address2,
							zip_postal_code:
								self.consultantCredential.currentAdoption.address
									.zip_postal_code,
						};
					}

					self.project.contact_name =
						self.consultantCredential.currentAdoption.contact_name;
					self.project.contact_email =
						self.consultantCredential.currentAdoption.contact_email;
					self.project.contact_phone =
						self.consultantCredential.currentAdoption.contact_phone;

					self.project.contract_method_id =
						self.consultantCredential.currentAdoption.contract_method_id;
					self.project.payment_method_id =
						self.consultantCredential.currentAdoption.payment_method_id;

					self.project.ie = self.consultantCredential.currentAdoption.ie;
					self.project.razao_social =
						self.consultantCredential.currentAdoption.razao_social;

					if (self.consultantCredential.currentAdoption.shipping_address) {
						self.project.shipping_address = {
							district:
								self.consultantCredential.currentAdoption.shipping_address
									.district,
							address_number:
								self.consultantCredential.currentAdoption.shipping_address
									.address_number,
							state:
								self.consultantCredential.currentAdoption.shipping_address
									.state,
							city: self.consultantCredential.currentAdoption.shipping_address
								.city,
							address1:
								self.consultantCredential.currentAdoption.shipping_address
									.address1,
							address2:
								self.consultantCredential.currentAdoption.shipping_address
									.address2,
							zip_postal_code:
								self.consultantCredential.currentAdoption.shipping_address
									.zip_postal_code,
						};

						self.openShippingAddressField;
					}

					self.project.user.name = self.consultantCredential.user.name;
					self.project.step = self.consultantCredential.currentAdoption.step;

					self.project.project_id =
						self.consultantCredential.currentAdoption.project_id;
				} else {
					self.project = {
						project_id: null,

						name: "",
						deadline: "",
						books: 0,
						book_price: 0.0,
						teacher_book: null,
						user_id: null,
						products: [],

						cnpj: "",
						address: {
							district: "",
							address_number: "",
							state: "",
							city: "",
							address1: "",
							address2: "",
							zip_postal_code: "",
						},
						contact_name: "",
						contact_email: "",
						contact_phone: "",
						project_id: null,
						contract_method_id: 1,
						payment_method_id: 1,
						ie: "",
						razao_social: "",

						shipping_address: {
							district: "",
							address_number: "",
							state: "",
							city: "",
							address1: "",
							address2: "",
							zip_postal_code: "",
						},

						user: {
							name: "",
						},

						type: "post",
						step: 1,
					};
				}
			},

			useSameAddress() {
				const self = this;

				self.openShippingAddressField = true;
				self.project.shipping_address = {
					district: self.project.address.district,
					address_number: self.project.address.address_number,
					state: self.project.address.state,
					city: self.project.address.city,
					address1: self.project.address.address1,
					address2: self.project.address.address2,
					zip_postal_code: self.project.address.zip_postal_code,
				};
			},

			getConsultants() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				// GET PRODUCTS
				axios
					.get(process.env.VUE_APP_ROOT_API + "/contract_methods", config)
					.then((response) => {
						var list = [];

						response.data.data.users.forEach((element) => {
							var itemList = {
								key: element.id,
								value: element.name,
							};

							list.push(itemList);
						});

						self.consultantList = list;
					})
					.catch((e) => {
						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			getContractMethods() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				// GET PRODUCTS
				axios
					.get(process.env.VUE_APP_ROOT_API + "/contract_methods", config)
					.then((response) => {
						var list = [];

						response.data.data.contracts.forEach((element) => {
							var itemList = {
								key: element.id,
								value: element.name,
							};

							list.push(itemList);
						});

						self.contractMethods = list;
					})
					.catch((e) => {
						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			getPaymentMethods() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				// GET PRODUCTS
				axios
					.get(process.env.VUE_APP_ROOT_API + "/payment_methods", config)
					.then((response) => {
						var list = [];

						response.data.data.payments.forEach((element) => {
							var itemList = {
								key: element.id,
								value: element.name,
							};

							list.push(itemList);
						});

						self.paymentMethods = list;
					})
					.catch((e) => {
						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			created: function () {
				const self = this;

				if (self.project.address.zip_postal_code.length === 9) {
					//self.cepSearch = true

					axios
						.get(
							`https://api.pagar.me/1/zipcodes/${this.project.address.zip_postal_code}`
						)
						.then((res) => {
							self.project.address.district = res.data.neighborhood;
							self.project.address.state = res.data.state;
							self.project.address.city = res.data.city;
							self.project.address.address1 = res.data.street;

							self.openAddressField = true;
						})
						.catch((error) => {
							self.openAddressField = true;
							//self.cepSearch = true

							//console.log(error)
						});
				}
			},

			createdShipping: function () {
				const self = this;

				if (self.project.shipping_address.zip_postal_code.length === 9) {
					//self.cepSearch = true

					axios
						.get(
							`https://api.pagar.me/1/zipcodes/${this.project.shipping_address.zip_postal_code}`
						)
						.then((res) => {
							self.project.shipping_address.district = res.data.neighborhood;
							self.project.shipping_address.state = res.data.state;
							self.project.shipping_address.city = res.data.city;
							self.project.shipping_address.address1 = res.data.street;

							self.openShippingAddressField = true;
						})
						.catch((error) => {
							self.openShippingAddressField = true;
							//self.cepSearch = true

							//console.log(error)
						});
				}
			},

			updateAndVerifyCep({ e, target }) {
				this.project.address.zip_postal_code = target.value;
				this.created();
			},

			updateAndVerifyCepShipping({ e, target }) {
				this.project.shipping_address.zip_postal_code = target.value;
				this.createdShipping();
			},

			jsonCopy(src) {
				return JSON.parse(JSON.stringify(src));
			},

			updateDataSchedule({ e, target }) {
				this.project.deadline = target.value;
				this.getSchedule();
			},

			updatePriceQuantity({ e, target }) {
				this.project.books = target.value;
				this.calculateValue();
			},

			updatePrice({ e, target }) {
				this.project.book_price = target.value;
				this.calculateValue();
			},

			calculateValue() {
				const self = this;

				var finalPrice = 0;
				self.productsExtra.forEach((element) => {
					finalPrice += parseInt(element.quantity);
				});

				self.project.books = finalPrice;

				var currency = parseFloat(
					this.project.book_price
						.toString()
						.replace("R$ ", "")
						.replace(/\./g, "")
						.replace(",", ".")
				);
				var value = currency * self.project.books;
				self.finalPrice = (Math.round(value * 100) / 100).toFixed(2);
			},

			getSalesPerson() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				// GET PRODUCTS
				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/salesperson?page=1&limit=100&sort=name asc",
						config
					)
					.then((response) => {
						var list = [];

						response.data.data.users.forEach((element) => {
							var itemList = {
								key: element.id,
								value: element.name,
							};

							list.push(itemList);
						});

						self.consultantList = list;
					})
					.catch((e) => {
						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			getSchedule() {
				const self = this;

				if (self.project.deadline.length === 10) {
					let config = {
						headers: {
							Authorization: "Bearer " + this.consultantCredential.token,
						},
					};

					let request = { date: self.project.deadline };

					// GET PRODUCTS
					axios
						.post(
							process.env.VUE_APP_ROOT_API + "/projects/schedule",
							request,
							config
						)
						.then((response) => {
							self.datesDeadline = response.data.data.dates;
						})
						.catch((e) => {
							let fieldError = {
								field: "error",
								msg: error.response.data.message,
								rule: "error", // optional
							};
							self.$validator.errors.add(fieldError);
						});
				}
			},

			getProducts() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				// GET PRODUCTS
				axios
					.get(process.env.VUE_APP_ROOT_API + "/products", config)
					.then((response) => {
						self.products = response.data.data.products;
					})
					.catch((e) => {
						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			selectProduct(id) {
				//alert(id);
				this.subscriberEditing = true;

				let subscriptionId = { id: id, quantity: 1 };

				var index = this.productsExtra.indexOf(
					this.productsExtra.find((c) => {
						return c.id === subscriptionId.id;
					})
				);

				if (index > -1) {
					this.productsExtra.splice(index, 1);
				} else {
					this.productsExtra.push(subscriptionId);
				}

				this.calculateValue();
			},

			getSelectedId(id) {
				let response = false;
				let object = this.productsExtra.find((c) => {
					return c.id === id;
				});

				if (object) {
					if (object.id === id) {
						response = true;
					}
				}

				return response;
			},

			updateProductQuantity(e, product) {
				const self = this;

				if (parseInt(e.target.value) <= 0) {
					e.target.value = 1;
				}

				let object = self.productsExtra.find((c) => {
					return c.id === product;
				});
				var index = self.productsExtra.indexOf(object);
				self.productsExtra.fill(
					(object.quantity = parseInt(e.target.value)),
					index,
					index++
				);

				self.calculateValue();
			},

			getValueProductExtra(product) {
				let object = this.productsExtra.find((c) => {
					return c.id === product.id;
				});
				return object.quantity;
			},

			onChangeMainProduct(event, grade) {
				grade.main = event.target.value;
			},

			onChangeTeacherBook(event) {
				this.project.teacher_book = Boolean(event.target.value);
			},

			close: function () {
				this.$root.$emit("closeModal");
			},

			addProject: function (project) {
				this.$root.$emit("addProject", project);
			},

			editProject: function (project, step) {
				this.$root.$emit("editProject", project, step);
			},

			clickOnFile() {
				this.$refs.files.click();
			},

			changeStepsUpload(step) {
				this.uploadStep = step;
			},

			changeUploadStatus(status) {
				this.upload = status;
			},

			onChangeGrade(event) {
				this.currentGrade = event.target.value;
				//this.address = event.target.value
			},

			onChangeType(event) {
				this.school.type = event.target.value;
				//this.address = event.target.value
			},

			// onChangeTeacher (event) {
			//   this.project.user_id = event.target.value
			//   //this.address = event.target.value
			// },

			onChangeProject(event) {
				this.project.project_id = event.target.value;
				//this.address = event.target.value
			},

			onChangePayment(event) {
				this.project.payment_method_id = event.target.value;
				//this.address = event.target.value
			},

			onChangeState(event) {
				this.project.address.state = event.target.value;
				//this.address = event.target.value
			},

			onChangeConsultantMethod(event) {
				this.project.user_id = event.target.value;
			},

			onChangeStateShipping(event) {
				this.project.shipping_address.state = event.target.value;
				//this.address = event.target.value
			},

			onChangeContractMethod(event) {
				this.project.contract_method_id = event.target.value;
			},

			onChangePaymentMethod(event) {
				this.project.payment_method_id = event.target.value;
			},

			onChangeInstallment(event) {
				const self = this;

				//self.numInstallments = event.target.value
				self.paymentDetails.installment = [];

				var currency = parseFloat(
					this.project.book_price
						.replace("R$ ", "")
						.replace(/\./g, "")
						.replace(",", ".")
				);
				var finalPrice = currency * self.project.books;
				var result = (finalPrice / parseInt(event.target.value)).toFixed(2);

				self.$forceUpdate();

				this.$nextTick(() => {
					for (var i = 1; i <= event.target.value; i++) {
						let installment = {
							dueDate: "",
							value: result,
						};
						self.paymentDetails.installment.push(installment);
					}

					self.$forceUpdate();
				});
			},

			submitFiles() {
				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + this.userCredential.token,
						},
					})
					.then(function () {
						alert("Upload feito com sucesso");
						window.location.reload();
					})
					.catch(function () {
						alert("Erro, upload falhou, entre em contato com nossa equipe.");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			saveStep1() {
				const self = this;
				self.errors.items = [];

				this.$validator.validateAll().then((result) => {
					if (result) {
						let url =
							self.project.type == "put"
								? process.env.VUE_APP_ROOT_API +
								  "/projects/step_1/" +
								  self.project.project_id
								: process.env.VUE_APP_ROOT_API + "/projects/step_1";

						var data = {
							name: self.project.name,
							deadline: self.project.deadline,
							books: self.project.books,
							book_price: parseFloat(
								self.project.book_price
									.toString()
									.replace("R$ ", "")
									.replace(/\./g, "")
									.replace(",", ".")
							),
							teacher_book: self.project.teacher_book,
							user_id: self.project.user_id,
							products: self.productsExtra,
						};

						let config = {
							headers: {
								Authorization: "Bearer " + this.consultantCredential.token,
							},
						};

						axios({
							method: self.project.type.toUpperCase(),
							url: url,
							headers: config.headers,
							data: data,
						})
							.then(function (response) {
								self.project.project_id = response.data.data.project.id;

								if (self.project.type == "post") {
									self.$root.$emit("addProject", response.data.data.project);
									self.project.step = 2;
								} else {
									self.$root.$emit(
										"editProject",
										response.data.data.project,
										1
									);
								}
							})
							.catch(function (error) {
								let fieldError = {
									field: "error-step-1",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},

			saveStep2() {
				const self = this;
				self.errors.items = [];

				self.$validator.validateAll().then((result) => {
					if (result) {
						let url =
							self.project.type == "put"
								? process.env.VUE_APP_ROOT_API +
								  "/projects/step_2/" +
								  self.project.project_id
								: process.env.VUE_APP_ROOT_API + "/projects/step_2";

						var data = {
							name: self.project.name,
							cnpj: self.project.cnpj,
							address: {
								district: self.project.address.district,
								address_number: self.project.address.address_number,
								state: self.project.address.state,
								city: self.project.address.city,
								address1: self.project.address.address1,
								address2: "",
								zip_postal_code: self.project.address.zip_postal_code,
							},

							contact_name: self.project.contact_name,
							contact_email: self.project.contact_email,
							contact_phone: self.project.contact_phone,

							project_id: self.project.project_id,

							contract_method_id: self.project.contract_method_id,
							payment_method_id: self.project.payment_method_id,

							ie: self.project.ie,
							razao_social: self.project.razao_social,
						};

						let config = {
							headers: {
								Authorization: "Bearer " + this.consultantCredential.token,
							},
						};

						axios({
							method: self.project.type.toUpperCase(),
							url: url,
							headers: config.headers,
							data: data,
						})
							.then(function (response) {
								self.project.step = 3;
								self.$root.$emit("editProject", response.data.data.project, 2);
							})
							.catch(function (error) {
								let fieldError = {
									field: "error-step-2",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},

			saveStep3() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				self.errors.items = [];

				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line
						let url =
							self.project.type == "put"
								? process.env.VUE_APP_ROOT_API +
								  "/projects/step_3/" +
								  self.project.project_id
								: process.env.VUE_APP_ROOT_API + "/projects/step_3/";

						var data = {
							shipping_address: self.project.shipping_address,
							project_id: self.project.project_id,
						};

						axios({
							method: self.project.type.toUpperCase(),
							url: url,
							headers: config.headers,
							data: data,
						})
							.then(function (response) {
								self.$root.$emit("editProject", response.data.data.project, 3);
								self.close();
							})
							.catch(function (error) {
								let fieldError = {
									field: "error-step-3",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},

			formatDate(hour) {
				var hourFormated = hour.split("/");
				return hourFormated[2] + "-" + hourFormated[1] + "-" + hourFormated[0];
				//return "YYYY-MM-DD";
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			// scrollParent (slug) {
			//   this.$router.push({ name: slug })
			// },
		},
	};
</script>
<style lang="scss" scoped>
	// .products-limit{
	//   max-height: 240px;
	//   overflow-y: scroll;
	//   overflow-x: hidden;
	// }

	.item-steps {
		// border-bottom: 1px solid #ccc;
		font-size: 22px;
		margin-bottom: 10px;
		padding-top: 0px !important;
	}

	.btn-clear {
		padding: 0px;
		background-color: transparent;
		border: 0px;
		font-size: 22px;

		&.active {
			color: #4965bc;
		}
	}

	h3 {
		font-size: 16px !important;
		margin: 15px 0px !important;
		width: 100%;
	}

	.dates {
		label {
			width: 100%;
		}
	}

	::v-deep input[type="text"]:disabled {
		background: rgb(240, 235, 245);
	}
	.products-list {
		border: 1px solid #ccc;
		border-radius: 10px;
		margin-bottom: 15px;
	}
	.grade-product-line {
		border: 1px solid #afa2ba;
	}
	.field-add {
		border: 1px solid #afa2ba;
	}

	.hide {
		display: none;
	}
	.upload-box {
		border: 1px dashed #ccc;
		text-align: center;
		border-width: 2px;
		border-radius: 10px;
		padding-bottom: 40px;
	}

	.tab-item {
		border: 1px solid #ccc;
	}

	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.fiscal-notes ::v-deep .form-group:last-child {
		margin-bottom: 10px !important;
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// Default of page

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 30px 30px 10px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}

	.vuestic-modal {
		.modal-dialog {
			max-width: 720px !important;
		}
	}

	.product-input-box {
		padding-bottom: 0px !important;

		::v-deep .box-input-item .floating-label {
			margin-bottom: 0px !important;
		}
	}
</style>
