<template>
    <div class="my-account">

        <div class="section">
            <div class="va-row">
                <div class="flex xs6 no-padding section-header">
                    <span class="section-name">Informações Gerais</span>
                </div>
            </div>
            <div class="va-row">
                <input-normal type="text" label="Nome do Cliente" id="name" name="name" v-validate="'required'"
                    :disabled="false" :data-vv-as="'name'" :value="client.name" :error="errors.first('name')"
                    @input="client.name = $event.target.value" />
            </div>

            <div class="va-row">
                <div class="flex no-padding">
                    <div class="file-upload-container" v-if="hasLogo == false">
                        <input type="file" id="clientLogo" class="file-input" ref="clientLogoFile"
                            @change="onFileChange($event)" />
                        <label for="clientLogo" class="file-label" @click.prevent.stop="showFileChooser">
                            <span class="file-icon">📄</span>
                            <span class="file-text">{{ clientLogoFileName || 'Escolher Logo' }}</span>
                            <button v-if="clientLogoFileName" @click.prevent.stop="removeFile('planoAula')"
                                class="close-button">
                                <i class="fa fa-close"></i>
                            </button>
                        </label>
                    </div>
                    <img v-if="hasLogo == true" :src="clientLogoS3" alt="" />
                </div>
            </div>
        </div>

        <div class="section">
            <div class="va-row">
                <div class="flex xs6 no-padding section-header">
                    <span class="section-name">Informações Fiscais</span>
                </div>
            </div>
            <input-normal id="input-razao-social" type="text" label="Razão social" name="name" v-validate="'required'"
                :disabled="false" :data-vv-as="'nome'" :required="'true'" :value="settings.default_razao_social"
                :error="errors.first('name')" @input="
                    settings.default_razao_social = $event.target.value
                    " />

            <input-normal id="input-cnpj" type="cpf" label="CNPJ" name="document" v-validate="'required'" v-mask="[
                '###.###.###-##',
                '##.###.###/####-##',
            ]" :disabled="false" :required="'true'" :data-vv-as="'CPF/CNPJ'" :value="settings.default_cnpj"
                :error="errors.first('document')" @input="settings.default_cnpj = $event.target.value" />
        </div>

        <div class="section">
            <div class="va-row">
                <div class="flex xs6 no-padding section-header">
                    <span class="section-name">Endereço padrão</span>
                </div>
            </div>
            <input-normal id="input-cep" type="cep" label="CEP" name="cep" :required="'true'" v-mask="['#####-###']"
                :value="settings.default_zip_postal_code" @input="updateAndVerifyCep" />
            <div class="va-row" v-if="openAddressField">
                <div class="flex xs9 no-padding">
                    <div class="address">
                        <div class="va-row">
                            <div class="flex xs12 no-padding">
                                {{ settings.default_address1 }}
                            </div>
                        </div>
                        <div class="va-row">
                            <div class="flex xs12 no-padding">
                                {{ settings.default_district + ", " + settings.default_city + ", " +
                                    settings.default_state }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex xs3 no-padding">
                    <input-normal type="number" label="Número" id="default_address_number" name="default_address_number"
                        v-validate="'required'" :disabled="false" :data-vv-as="'default_address_number'"
                        :value="settings.default_address_number" :error="errors.first('default_address_number')"
                        @input="settings.default_address_number = $event.target.value" />
                </div>
            </div>
        </div>

        <div class="section">
            <div class="va-row">
                <div class="flex xs6 no-padding section-header">
                    <span class="section-name">Configurações</span>
                </div>
            </div>

            <div class="va-row">
                <div class="flex xs4 pd-5">
                    <input-normal type="number" label="Limite inicial de livros" id="book_limit_initial"
                        name="book_limit_initial" v-validate="'required'" :disabled="false"
                        :data-vv-as="'book_limit_initial'" :value="settings.book_limit_initial"
                        :error="errors.first('book_limit_initial')" @input="handleBookLimitInitialInput" min="0" />
                </div>
                <div class="flex xs4 pd-5">
                    <input-normal type="number" label="Limite de livros" id="book_limit" name="book_limit"
                        v-validate="'required'" :disabled="false" :data-vv-as="'book_limit'"
                        :value="settings.book_limit" :error="errors.first('book_limit')" @input="handleBookLimitInput"
                        min="0" />
                </div>
                <div class="flex xs4 pd-5">
                    <input-normal type="number" label="Preço Mínimo do Livro" id="minimumBookPrice"
                        name="minimumBookPrice" v-validate="'required'" :disabled="false" data-vv-as="minimumBookPrice"
                        :value="settings.minimum_book_price" :error="errors.first('minimumBookPrice')"
                        @input="handleMinimumBookPriceInput" min="0" />
                </div>
            </div>

            <div class="va-row">
                <div class="flex xs12 no-padding">
                    <input-normal type="text" label="Dedicatória padrão" id="default_dedication"
                        name="default_dedication" v-validate="'required'" :disabled="false"
                        :data-vv-as="'default_dedication'" :value="settings.default_dedication"
                        :error="errors.first('default_dedication')"
                        @input="settings.default_dedication = $event.target.value" />
                </div>
            </div>
        </div>



        <div class="flex md12 text-right">
            <div class="error text-center">
                {{ errors.first("error") }}
            </div>
            <button type="submit" class="btn btn-primary right" :disabled="isFormSending" v-on:click="send()">
                SALVAR
            </button>
            <button type="button" class="btn btn-primary right cancel" v-on:click="close()">
                CANCELAR
            </button>
        </div>
        <div v-if="showNotification" class="notification-container">
            <div v-for="(errorMessage, index) in notificationErrorMessage" :key="index"
                class="notification notification-error" :style="{ bottom: `${30 + index * 70}px` }">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import { keyBy } from "lodash";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

const axios = require("axios");

export default {
    name: "client-edit",
    directives: { mask },
    props: ["clientId"],
    components: {
    },
    computed: {
        ...mapGetters({
            managerCredential: "managerCredential"
        })
    },
    mounted() {
    },
    data() {
        return {
            client: {
                name: ""
            },
            settings: {
                default_razao_social: "",
                default_cnpj: "",
                book_limit_initial: 0,
                book_limit: 0,
                minimum_book_price: 0,
                default_dedication: "",
                default_address1: "",
                default_address2: "",
                default_address_number: "",
                default_city: "",
                default_district: "",
                default_state: "",
                default_zip_postal_code: "",
            },
            clientLogoFileUri: "",
            clientLogoFileName: "",
            hasDefaultAddress: false,
            openAddressField: false,
            hasLogo: false,
            clientLogoS3: "",
            showNotification: false,
            notificationErrorMessage: [],
            isFormSending: false
        };
    },
    methods: {
        handleBookLimitInitialInput(event) {
            let value = parseInt(event.target.value);
            if (value < 0) {
                this.settings.book_limit_initial = 0;
            } else {
                this.settings.book_limit_initial = value;
            }
        },
        handleBookLimitInput(event) {
            let value = parseInt(event.target.value);
            if (value < 0) {
                this.settings.book_limit = 0;
            } else {
                this.settings.book_limit = value;
            }
        },
        handleMinimumBookPriceInput(event) {
            let value = parseFloat(event.target.value);
            if (value < 0) {
                this.settings.minimum_book_price = 0;
            } else {
                this.settings.minimum_book_price = value;
            }
        },
        updateAndVerifyCep({ e, target }) {
            if (target.value !== "") {
                this.settings.default_zip_postal_code = target.value;
                this.created();
            } else {
                this.handleHasAddress(true);
            }
            
        },

        created() {
            const self = this;

            if (self.settings.default_zip_postal_code.length === 9) {

                axios
                    .get(
                        `https://api.pagar.me/1/zipcodes/${self.settings.default_zip_postal_code}`
                    )
                    .then((res) => {

                        self.settings.default_address1 = res.data.street;
                        self.settings.default_city = res.data.city;
                        self.settings.default_district = res.data.neighborhood;
                        self.settings.default_state = res.data.state;

                        self.openAddressField = true;
                    })
                    .catch((error) => {
                        self.openAddressField = false;
                    });
            }
        },
        handleHasAddress(value) {
            const self = this;
            if (value) {
                self.settings.default_address1 = "";
                self.settings.default_city = "";
                self.settings.default_district = "";
                self.settings.default_state = "";
                self.settings.default_zip_postal_code = "";
                self.settings.default_address_number = "";

                self.openAddressField = false;
            }
        },
        onFileChange(e) {
            const self = this;

            var file = e.target.files[0];

            self.clientLogoFileUri = file;
            self.clientLogoFileName = file.name;
        },
        showFileChooser() {
            const self = this;
            self.$refs.clientLogoFile.click();
        },
        removeFile() {
            const self = this;
            self.clientLogoFileName = '';
            self.$refs.clientLogoFile.value = '';
            self.clientLogoFileUri = null;
        },
        close: function () {
            this.client = { name: "" };
            this.settings = {
                default_razao_social: "",
                default_cnpj: "",
                book_limit_initial: 0,
                book_limit: 0,
                minimum_book_price: 0,
                default_dedication: "",
                default_address1: "",
                default_address2: "",
                default_address_number: "",
                default_city: "",
                default_district: "",
                default_state: "",
                default_zip_postal_code: "",
            };
            this.clientLogoFileUri = "";
            this.clientLogoFileName = "";
            this.hasDefaultAddress = false;
            this.openAddressField = false;
            this.shippingMethods = [];
            this.hasLogo = false;
            this.clientLogoS3 = "";
            this.$emit("closeModal");
        },
        getClientById() {
            const self = this;
            let config = {
                headers: {
                    Authorization: "Bearer " + self.managerCredential.token,
                },
            };
            axios
                .get(process.env.VUE_APP_ROOT_API + "/clients_special_contract/getById/" + self.clientId, config)
                .then((response) => {
                    const responsedClient = response.data.data[0];

                    const settingsData = responsedClient.settings.reduce((acc, item) => {
                        acc[item.name] = item.value;
                        return acc;
                    }, {});

                    self.client.name = responsedClient.name;

                    self.settings.default_razao_social = settingsData.default_razao_social;
                    self.settings.default_cnpj = settingsData.default_cnpj;
                    self.settings.default_zip_postal_code = settingsData.default_zip_postal_code;
                    self.settings.default_address_number = settingsData.default_address_number;
                    self.settings.book_limit_initial = settingsData.book_limit_initial;
                    self.settings.book_limit = settingsData.book_limit;
                    self.settings.minimum_book_price = settingsData.minimum_book_price;
                    self.settings.default_dedication = settingsData.default_dedication;

                    if (settingsData.default_address1 !== "" || settingsData.default_address2 !== "" || settingsData.default_address_number !== "" || settingsData.default_city !== "" || settingsData.default_district !== "" || settingsData.default_state !== "" || settingsData.default_zip_postal_code !== "") {
                        self.settings.default_address1 = settingsData.default_address1;
                        self.settings.default_address2 = settingsData.default_address2;
                        self.settings.default_address_number = settingsData.default_address_number;
                        self.settings.default_city = settingsData.default_city;
                        self.settings.default_district = settingsData.default_district;
                        self.settings.default_state = settingsData.default_state;
                        self.settings.default_zip_postal_code = settingsData.default_zip_postal_code;
                        self.hasDefaultAddress = true;
                        self.openAddressField = true;
                    }

                    if (responsedClient.logo != null) {
                        self.hasLogo = true;
                        self.clientLogoS3 = responsedClient.logo;
                    }

                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        send() {
            const self = this;
            const payload = self.createPayload();
            self.isFormSending = true;
            if (self.clientId === null) {
                self.makePost(payload);
            } else {
                self.makePut(payload);
            }
        },
        createPayload() {
            const self = this;
            const formData = new FormData();

            Object.entries(self.client).forEach(([key, value]) => {
                formData.append(`client[${key}]`, value);
            });

            if (self.clientLogoFileName) {
                formData.append('client[logo]', self.clientLogoFileUri);
            }

            const settingsFormatted = Object.entries(self.settings).map(([key, value]) => ({
                name: key,
                value: value
            }));

            for (var i = 0; i < settingsFormatted.length; i++) {
                formData.append(`settings[${i}][name]`, settingsFormatted[i].name);
                formData.append(`settings[${i}][value]`, settingsFormatted[i].value);
            }

            return formData;
        },
        makePost(data) {
            const self = this;
            axios
                .post(process.env.VUE_APP_ROOT_API + "/clients_special_contract/add", data, {
                    headers: {
                        Authorization: "Bearer " + self.managerCredential.token,
                    },
                })
                .then(function (response) {
                    self.updateUserClientsList(response.data.data);
                })
                .catch(function (error) {
                    self.mountErrorMessage(error);
                    self.showNotificationError();
                });
        },
        makePut(data) {
            const self = this;
            axios
                .post(process.env.VUE_APP_ROOT_API + "/clients_special_contract/edit/" + self.clientId, data, {
                    headers: {
                        Authorization: "Bearer " + self.managerCredential.token,
                    },
                })
                .then(function (response) {
                    self.updateUserClientsList(response.data.data);
                })
                .catch(function (error) {
                    self.mountErrorMessage(error);
                    self.showNotificationError();
                });
        },
        mountErrorMessage(error) {
            const self = this;
            try {
                let errorMessage;
                if (error.response.data.message.startsWith("{")) {
                    const parsedMessage = JSON.parse(error.response.data.message);
                    errorMessage = parsedMessage.validation_errors;
                } else {
                    errorMessage = { message: error.response.data.message };
                }
                if (errorMessage.message) {
                    self.notificationErrorMessage = [errorMessage.message.replace(/\\"/g, '"')];
                } else {
                    let errorMessages = Object.values(errorMessage);
                    for (let i = 0; i < errorMessages.length; i++) {
                        self.notificationErrorMessage.push(errorMessages[i].replace(/\\"/g, '"'));
                    }
                }
            } catch (e) {
                self.notificationErrorMessage = "Ocorreu um erro ao processar a mensagem.";
            }
        },
        showNotificationError() {
            const self = this;
            self.showNotification = true;
            self.isFormSending = false;
            setTimeout(() => {
                self.showNotification = false;
                self.notificationErrorMessage = [];
            }, 5000);
        },
        updateUserClientsList(clients) {
            const self = this;
            self.isFormSending = false;
            self.managerCredential.available_clients = clients;
            self.$store.commit("SAVE_MANAGER_CREDENTIALS", self.managerCredential);
            self.$router.go(0);
        }
    },
    watch: {
        clientId(newId) {
            if (newId) {
                this.getClientById();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.file-input {
    display: none;
}

.file-label {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f0f0f0;
    border: 1px dashed #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    margin: 0;
}

.file-label:hover {
    background-color: #e0e0e0;
    border-color: #aaa;
}

.file-icon {
    font-size: 24px;
    margin-right: 10px;
}

.file-text {
    font-size: 14px;
    color: #333;
}

.close-button {
    //background-color: red; /* Escolha a cor desejada */
    color: red;
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    font-size: 12px;
    top: -6px;
    right: -6px;
    border: 1px solid #ccc;
    border-radius: 50%;
}

.section-name {
    font-weight: bolder;
}

.section-header {
    margin-bottom: 20px;
}

.section {
    margin-bottom: 20px;
}

.pd-5 {
    padding: 5px !important;
}

.toggle-name {
    font-weight: bolder;
    font-size: 14px;
}

.toggle-description {
    font-size: 12px;
    color: #c3bfc7;
}

.toggle {
    padding: 0px !important;
    text-align: right;
}

.toggle-container {
    margin-top: 15px !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

.address {
    color: #99989b;
}

.has-default-address-toggle {
    text-align: right;
    padding-left: 16px !important;
}

.notification-container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Espaço entre as notificações */
}

.notification {
    background-color: #df463e;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    width: 300px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
}

/* Para evitar sobreposição ao aparecerem várias */
.notification:nth-child(n) {
    animation-delay: calc(0.2s * var(--index));
}

.notification-error {
    background-color: #df463e;
}
</style>