<template>
	<label
		class="radio-button-wrapper"
		:class="[{ 'is-selected': selected }, { 'is-disabled': disabled }]"
	>
		<input
			:data-value="value"
			:value="value"
			type="radio"
			class="radio-button"
			v-on="listeners"
		/>
		<div class="radio-button-icon"></div>
		<div class="radio-button-content">
			<slot />
		</div>
	</label>
</template>

<script>
	/**
 * Element description
 *

#####Breakpoints
<span class="flag flag-default" markdown="1">lg</span>

 */
	export default {
		name: "RadioButton",
		status: "under-review",
		release: "1.0.1",
		props: {
			border: {
				type: String,
				default: "rounded",
				required: false,
				validator: function (value) {
					return (
						["rounded", "circle", "square", "roundest"].indexOf(value) !== -1
					);
				},
			},
			level: {
				type: String,
				default: "floor",
				required: false,
				validator: function (value) {
					return ["floor", "1"].indexOf(value) !== -1;
				},
			},
			selected: {
				type: Boolean,
				required: false,
				default: false,
			},
			disabled: {
				type: Boolean,
				required: false,
				default: true,
			},
			value: {
				type: Boolean,
				required: false,
				default: null,
			},
		},
		computed: {
			listeners() {
				const vm = this;
				return Object.assign({}, this.$listeners, {
					click: function (event) {
						vm.$emit("click", event, vm.value);
					},
				});
			},
		},
	};
</script>

<style lang="scss">
	.radio-button {
		//@include reset;
		position: absolute;
		opacity: 0;
		z-index: -1;

		&-icon {
			height: 28px;
			width: 28px;
			background: $color-grey-lighter;
			border: 4px solid $color-white;
			box-shadow: 0 0 0 1px $color-grey-lighter;
			border-radius: $radius-circle;
			margin-right: 10px;
			transition: all $small-simple-animations-duration $standard-easing;

			@media (max-width: 990px) {
				max-height: 23px;
				max-width: 23px;
			}

			@media (max-width: 375px) {
				max-height: 19px;
				max-width: 19px;
			}
		}

		&-content {
			@media (max-width: 990px) {
				font-size: 14px;
				line-height: 17px;
				font-weight: 700;
				padding-right: 10px;
			}
		}

		&-wrapper {
			font-family: $font-text;
			border-radius: 5px;
			padding: 20px;
			border: 1px solid $color-grey-lighter;
			margin-bottom: 0;
			border-radius: 5px;
			z-index: 1;
			transition: all $small-simple-animations-duration $standard-easing;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			background: white;

			@media (max-width: 990px) {
				padding: 15px 10px;
			}

			&:hover {
				outline: none;
				border-color: #4965bc;
				cursor: pointer;

				.radio-button-icon {
					box-shadow: 0 0 0 1px #4965bc;
				}
			}

			&[disabled] {
				background-color: $color_grey_lightest;
				border-color: $color_grey_light;
				color: $color_grey_dark;

				.radio-button-icon {
					box-shadow: 0 0 0 1px $color-grey-dark;
				}
			}

			&:active,
			&.is-selected {
				// box-shadow: 0 0 1px 1px $color-blue-green;
				box-shadow: none;
				border: 2px solid #4965bc;

				.radio-button-icon {
					background-color: #4965bc;
					box-shadow: 0 0 0 1px #4965bc;
				}
			}
		}
	}
</style>
<docs>
  ```jsx
  <div>
    <div style="display: flex;">
      <RadioButton>
        <div style="">
          Circular
        </div>
      </RadioButton>
      <div style="width: 20px">
      </div>
      <RadioButton selected="true">
        <div style="">
          Circular
        </div>
      </RadioButton>
    </div>
  </div>
  ```
</docs>
