<template>
  <div class="my-account">
    <div class="va-row">
      <div class="flex md12 xs12 no-padding">
        <div class="ui-grid">
          <div class="my-data-table va-row" id="cards">
            <div class="flex xs12 no-padding">
              <div class="flex md12 xs12 no-padding">
                <div class="ui-grid">
                  <div class="ui-grid">
                    <div class="flex md12 no-padding">
                      <div class="flex md12 no-padding">
                        <div
                          v-for="(product, index) in products"
                          :key="index"
                          class="va-row"
                        >
                          <div
                            class="flex no-padding"
                            :class="getSelectedId(product.id) ? 'md10' : 'md12'"
                          >
                            <RadioButton
                              class="box-payment box-method"
                              :value="false"
                              :selected="getSelectedId(product.id)"
                              @click="selectProduct(product.id)"
                            >
                              <div>
                                <span class="nome-da-marca">{{
                                  product.brand.name
                                }}</span
                                ><br />{{ product.name }}
                              </div>
                            </RadioButton>
                          </div>

                          <div
                            class="flex md2 product-input-box"
                            v-if="getSelectedId(product.id)"
                          >
                            <input-normal
                              type="number"
                              label="Qtd."
                              :name="'product' + product.id"
                              v-validate="'required'"
                              :disabled="false"
                              :data-vv-as="'quantidade'"
                              :value="getValueProductExtra(product)"
                              :error="errors.first('product' + product.id)"
                              @input="updateProductQuantity($event, product.id)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="flex md12 btn-edit-area margin-button-edit">
                        <button
                          class="btn btn-primary cancel"
                          v-on:click="closeProductEdit()"
                        >
                          CANCELAR
                        </button>
                        <button
                          class="btn btn-change"
                          v-on:click="saveProductsEdit()"
                        >
                          CONFIRMAR
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="">
								<div class="va-row">
									<div class="error-label" v-if="errors.length > 0">
										{{ errors[0].message }}
									</div>
									Você tem certeza de que deseja excluir esta adoção?<br />
									<div class="flex md12 text-right">
										<button
											class="btn btn-primary right"
											v-on:click="deleteItem()"
										>
											EXCLUIR
										</button>
										<button
											type="button"
											class="btn btn-primary right cancel"
											v-on:click="close()"
										>
											CANCELAR
										</button>
									</div>
								</div>
							</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { required, minLength, between } from 'vuelidate/lib/validators'
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

const axios = require("axios");

export default {
  name: "books-edit",
  directives: { mask },
  components: {
    // vuescroll
    // VueScrollTo
    // DataVisualisationTab,
  },

  props: ["products", "productsExtra", "projectInfos"],

  data() {
    return {
      openAddressField: false,
      cepSearch: false,
      types: [
        {
          key: 1,
          value: "Pública",
        },
        {
          key: 2,
          value: "Particular",
        },
        {
          key: 3,
          value: "Autônoma",
        },
      ],
      projects: [
        {
          key: 1,
          value: "Projeto 1",
        },
        {
          key: 2,
          value: "Projeto 2",
        },
        {
          key: 3,
          value: "Projeto 3",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      managerCredential: "managerCredential",
      // ...
    }),
  },

  mounted() {
    const self = this;
  },

  //
  methods: {
    updateProductQuantity(e, product) {
      const self = this;

      if (parseInt(e.target.value) <= 0) {
        e.target.value = 1;
      }

      let object = self.productsExtra.find((c) => {
        return c.id === product;
      });
      var index = self.productsExtra.indexOf(object);
      self.productsExtra.fill(
        (object.quantity = parseInt(e.target.value)),
        index,
        index++
      );
    },

    selectProduct(id) {
      //alert(id);
      this.subscriberEditing = true;

      let subscriptionId = { id: id, quantity: 1 };

      var index = this.productsExtra.indexOf(
        this.productsExtra.find((c) => {
          return c.id === subscriptionId.id;
        })
      );

      if (index > -1) {
        this.productsExtra.splice(index, 1);
      } else {
        this.productsExtra.push(subscriptionId);
      }
    },

    closeProductEdit() {
      const self = this;
      self.$emit("closeEditBooks");
    },

    saveProductsEdit() {
      const self = this;

      let url = process.env.VUE_APP_ROOT_API + "/projects/edit_books";

      var data = {
        project_id: self.projectInfos.project_id,
        products: self.productsExtra,
      };

      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };

      axios
        .post(url, data, config)
        .then((response) => {
          //self.disableProductsEdit();
          //self.getProjectDetails();
          //alert("Salvou e fechou o modal");
          self.$emit("closeAndSaveBooksEdit");
        })
        .catch((e) => {
          alert(e.response.data.message);
        });
    },

    getValueProductExtra(product) {
      let object = this.productsExtra.find((c) => {
        return c.id === product.id;
      });
      return object.quantity;
    },

    getSelectedId(id) {
      let response = false;
      let object = this.productsExtra.find((c) => {
        return c.id === id;
      });

      if (object) {
        if (object.id === id) {
          response = true;
        }
      }

      return response;
    },

    close: function () {
      this.$root.$emit("closeModal");
    },

    deleteItem() {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };

      axios
        .delete(
          process.env.VUE_APP_ROOT_API + "/projects/" + this.project.id,
          config
        )
        .then((response) => {
          self.$router.push({ name: "projects" });
          self.deleteProject();
          self.close();
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    deleteProject: function () {
      this.$root.$emit("deleteProject", this.project);
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 580px) {
  .btn-primary {
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 5px;
    }
  }
}

.fiscal-notes ::v-deep .form-group:last-child {
  margin-bottom: 10px !important;
}

.error-label {
  color: red;
  text-align: left;
  margin-bottom: 10px;
}
small {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-indent: 15px;
  margin-top: -5px;
  display: block;
  margin-bottom: 10px;
  // padding-left: 15px;
}
h5 {
  font-family: "Nunito";
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
}
.address-info {
  margin-bottom: 15px;
}
.editing {
  margin-top: 15px;
}
.radio-button-wrapper {
  padding: 14px 11px;
  margin-top: 10px;

  @media (max-width: 990px) {
    padding: 20px 19px;
    margin-top: 10px;
  }
}
.header-menu-club {
  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}
.add-new-card {
  border-radius: 10px;
  border: dashed 2px #2cd3d8;
  background-color: #ffffff;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.5;
  letter-spacing: 0.7px;
  text-align: center;
  color: #4965bc;
  margin-bottom: 20px;
  padding: 15px 0px;
  cursor: pointer;

  .plus {
    font-family: "Nunito";
    font-size: 35px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    width: 40px;
    height: 40px;
    transform: rotate(-180deg);
    border-radius: 40px;
    border: solid 1px #4965bc;
    background-color: #4965bc;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    line-height: 36px;
  }
}

// Default of page

h4 {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
  margin-bottom: 0;
}
.header-menu {
  background-color: white;

  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}
.my-data {
  /* Facebook bar */
  .facebook-tag {
    height: 65px;
    border-radius: 5px;
    background-color: #3a5998;
    color: white;
    padding: 14px 20px;
    margin-bottom: 20px;

    svg {
      font-size: 26px;
      margin-right: 0px;
      float: left;

      path {
        fill: #fff;
      }
    }

    .name {
      display: inline-block;
      margin-top: 1px;
      line-height: 37px;
    }

    .facebook-toggle {
      float: right;
      display: block;
      margin-top: 5px;
    }
  }
}

.change-password {
  border-radius: 10px;
  border: solid 2px #f0ebf5;
  background-color: #ffffff;
  padding: 24px 20px;
  position: relative;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 7px;
    font-family: "Roboto";

    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  h5 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #afa2ba;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .floated-icon {
    float: left;
    width: 36px;
    height: 36px;
    font-size: 30px;

    svg {
      position: relative;
      top: -5px;
      left: -3px;

      path {
        fill: #afa2ba;
      }
    }
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }
}

.my-data-table {
  h4 {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
.fiscal-notes {
  border-radius: 10px;
  border: solid 1px #f0ebf5;
  background-color: #ffffff;
  position: relative;
  border: solid 2px #f0ebf5;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .body-spacing {
    padding: 30px 30px 10px 30px;

    @media (max-width: 990px) {
      padding: 25px 15px 15px 15px;
    }

    //  div:last-child{
    //   margin-bottom: 0px;
    // }
  }

  hr {
    border-top: solid 1px #f0ebf5;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .dual-inputs {
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  .company-code {
    text-transform: capitalize;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }

  // .panel:last-child{
  //   margin-bottom: 0px;
  // }
}

.address-boxes {
  border-radius: 10px;
  border: solid 1px #f0ebf5;
  background-color: #ffffff;
  position: relative;
  border: solid 2px #f0ebf5;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  svg {
    path {
      fill: #afa2ba;
    }
  }

  .clube {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .body-spacing {
    padding: 30px 30px;

    @media (max-width: 990px) {
      padding: 25px 15px 15px 15px;
    }
  }

  hr {
    border-top: solid 2px #f0ebf5;
  }

  .dual-inputs {
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }

  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }

  .address-row {
    padding: 16px 0px;
    position: relative;
    border-bottom: solid 1px #f0ebf5;
    b {
      font-size: 16px;
      color: #000000;
    }
    :last-child {
      border-bottom: none;
    }
  }
}

.address-row {
  position: relative;
}

.more-cards {
  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }
}

.panel {
  // height: 200px;
  border-radius: 10px;
  border: solid 2px #9378f0;
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 20px;

  .header-panel {
    padding: 0px 20px;
    background-color: #9378f0;
    line-height: 33px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .clube img {
    margin-top: -5px;
  }

  b {
    font-size: 16px;
    color: #000000;
  }

  .panel-body {
    position: relative;
    padding: 10px 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #afa2ba;
    margin: 0px !important;

    .flex.xs1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 6.33333%;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 6.33333%;
    }

    b {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #000000;
    }

    .editor {
      right: 18px;
      top: 20%;
    }
  }
}

.subscription-box {
  // padding: 30px !important;

  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #000000;

  .toggled {
    margin-top: 10px;
  }
}
.newsletter {
  // margin-top:40px;
  padding: 31px;
  border: solid 2px #f0ebf5;

  @media (max-width: 990px) {
    margin-top: 0px;
    padding: 15px;
  }
}

// Custom Component
.v-switch-button {
  ::v-deep &:before {
    content: "";
    display: block;
    position: relative;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 14.5px;
    margin-left: auto;
    margin-right: auto;
    top: 9px;
  }
}

.vue-js-switch {
  &.toggled {
    .v-switch-button {
      ::v-deep &:before {
        content: "";
        display: block;
        position: relative;
        background-color: #7ed321;
        width: 6px;
        height: 6px;
        border-radius: 14.5px;
        margin-left: auto;
        margin-right: auto;
        top: 9px;
      }
    }
  }
}

.name-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}
.lastname-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}

.btn-edit-area {
  text-align: right;
  padding-right: 0px !important;

  .cancel {
    margin-right: 8px;
  }
}

.box-input-item .floating-input,
.box-input-item .floating-select {
  height: 60px;
}

::v-deep .box-input-item .floating-label {
  margin-bottom: 0px !important;
}

.product-input-box {
  padding-bottom: 0px !important;
}

::v-deep .radio-button-content {
  font-weight: 400;
}
.nome-da-marca {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #68596e;
}
.margin-button-edit {
  margin-top: 16px;
}
</style>
