<template>
    <div class="status-bar">
        <div class="va-row" :class="['box-status', subscription.subscriptionStatus]" v-if="!(subscription.subscriptionStatus === 'Ativa')">
            <div class="flex xs12 md6">
                <div class="ico-ddh">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" v-if="subscription.SubscriptionStatus === 'Pausada' || subscription.SubscriptionStatus === 'Pausado' || subscription.subscriptionStatus === 'Vencida'">
                        <path fill="#000" fill-rule="evenodd" d="M18.937 25.28c-.247.252-.59.399-.937.399-.348 0-.69-.147-.94-.4a1.383 1.383 0 0 1 0-1.92c.25-.253.59-.397.94-.397.347 0 .69.144.937.397a1.375 1.375 0 0 1 0 1.92zm-1.975-10.34A1.05 1.05 0 0 1 18 13.876a1.05 1.05 0 0 1 1.038 1.062v6.337A1.05 1.05 0 0 1 18 22.34a1.05 1.05 0 0 1-1.038-1.063V14.94zM28.68 25.031L19.606 8.951A1.85 1.85 0 0 0 17.997 8c-.664 0-1.277.362-1.61.95L7.25 25.149a1.94 1.94 0 0 0 0 1.901c.332.589.945.951 1.61.951H27.141C28.168 28 29 27.149 29 26.099c0-.395-.118-.763-.32-1.067z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" v-if="subscription.SubscriptionStatus === 'Pendente'">
                        <path fill="#000" fill-rule="evenodd" d="M13.73 7h9.54L30 13.73v9.54L23.27 30h-9.54L7 23.27v-9.54L13.73 7zm5.807 17.35a1.47 1.47 0 0 0 .432-1.038 1.471 1.471 0 0 0-2.51-1.041 1.48 1.48 0 0 0-.428 1.04c0 .386.155.765.429 1.038.276.274.655.432 1.04.432s.764-.158 1.037-.432zM17.35 13.157v6.859a1.15 1.15 0 1 0 2.3 0v-6.859a1.15 1.15 0 1 0-2.3 0z" />
                    </svg>
                </div>
                <div>
                    Status: {{subscription.subscriptionStatus}}
                </div>
            </div>
            <div class="flex xs12 md6 text-right buttons-actions" :class="[subscription.subscriptionStatus]">

                <div v-if="subscription.subscriptionStatus === 'Boleto-Pendente'">
                    <button class="btn btn-primary">VISUALIZAR BOLETO</button>
                </div>

            </div>
        </div>

        <div class="va-row status-info" :class="[subscription.subscriptionStatus]">
            <div class="flex xs12 md3 box-text-status">
                <div class="ico-ddh">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" v-if="subscription.subscriptionStatus === 'Ativa'">
                        <path fill="#000" fill-rule="evenodd" d="M26.383 12.728c-.433-.47-.986-.727-1.48-.728-.293 0-.569.092-.776.29l-2.306 2.179v-.002l-3.136 2.963c-.055.052-.093.109-.13.16l-3.008 2.841-3.545-3.855c-.217-.235-.526-.347-.858-.347-.47 0-.992.217-1.424.625-.465.44-.719 1-.72 1.5 0 .298.092.576.287.788l3.25 3.533 1.823 1.981c.214.235.52.344.85.344.147 0 .295-.021.448-.061l.085.002c.292.001.566-.087.776-.284l5.44-5.14c.054-.052.094-.11.13-.162l4.567-4.315c.234-.22.344-.532.344-.87 0-.474-.215-1.002-.617-1.442z" />
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" v-if="subscription.subscriptionStatus === 'Pausada' || subscription.SubscriptionStatus === 'Pausado' || subscription.subscriptionStatus === 'Vencida'">
                        <path fill="#000" fill-rule="evenodd" d="M18.937 25.28c-.247.252-.59.399-.937.399-.348 0-.69-.147-.94-.4a1.383 1.383 0 0 1 0-1.92c.25-.253.59-.397.94-.397.347 0 .69.144.937.397a1.375 1.375 0 0 1 0 1.92zm-1.975-10.34A1.05 1.05 0 0 1 18 13.876a1.05 1.05 0 0 1 1.038 1.062v6.337A1.05 1.05 0 0 1 18 22.34a1.05 1.05 0 0 1-1.038-1.063V14.94zM28.68 25.031L19.606 8.951A1.85 1.85 0 0 0 17.997 8c-.664 0-1.277.362-1.61.95L7.25 25.149a1.94 1.94 0 0 0 0 1.901c.332.589.945.951 1.61.951H27.141C28.168 28 29 27.149 29 26.099c0-.395-.118-.763-.32-1.067z" />
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" v-if="subscription.subscriptionStatus === 'Pendente'">
                        <path fill="#000" fill-rule="evenodd" d="M13.73 7h9.54L30 13.73v9.54L23.27 30h-9.54L7 23.27v-9.54L13.73 7zm5.807 17.35a1.47 1.47 0 0 0 .432-1.038 1.471 1.471 0 0 0-2.51-1.041 1.48 1.48 0 0 0-.428 1.04c0 .386.155.765.429 1.038.276.274.655.432 1.04.432s.764-.158 1.037-.432zM17.35 13.157v6.859a1.15 1.15 0 1 0 2.3 0v-6.859a1.15 1.15 0 1 0-2.3 0z" />
                    </svg>

                </div>
                <div class="phrase">
                    <span class="space-status">Status</span>
                    <span class="space-description"><b>{{subscription.subscriptionStatus}}</b></span>
                </div>
            </div>

            <div class="flex xs12 md3 box-text-status">
                <div class="ico-ddh">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                        <path fill="#000" fill-rule="evenodd" d="M24 11h2a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V13a2 2 0 0 1 2-2h2v-1a2 2 0 1 1 4 0v1h4v-1a2 2 0 1 1 4 0v1zm2 6H10v8.2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V17z" />
                    </svg>
                </div>
                <div class="phrase">
                    <span class="space-status">Plano</span>
                    <span class="space-description"><b>{{subscription.planName}}</b></span>
                </div>
            </div>

            <div class="flex xs12 md3 box-text-status">
                <div class="ico-ddh ico-plan">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                        <path fill="#000" fill-rule="evenodd" d="M13.783 21.579l4.314-9.899 4.313 9.899 6.17-3.979L26.478 28H9.717L7.613 17.6l6.17 3.979zM7.6 13.6h.026a1.6 1.6 0 1 1 0 3.2H7.6a1.6 1.6 0 1 1 0-3.2zM18.084 8h.026a1.6 1.6 0 1 1 0 3.2h-.026a1.6 1.6 0 1 1 0-3.2zm11.29 5.6h.026a1.6 1.6 0 1 1 0 3.2h-.026a1.6 1.6 0 1 1 0-3.2z" />
                    </svg>
                </div>
                <div class="phrase">
                    <span class="space-status">Data de cobrança</span>
                    <span class="space-description"><b>{{subscription.dueDate}}</b></span>
                </div>
            </div>

            <div class="flex xs12 md3 text-right price-box-info parcel">
                <span class="space-status"><b>{{subscription.planInstallments}}x de {{subscription.amountInstallments}}</b></span>
                <span class="space-description">({{subscription.amount}})</span>
            </div>
        </div>
    </div>
</template>

<script>
// const axios = require('axios');

export default {
  name: 'status-bar',
  props: {
    subscription: {
      type: Object,
      required: true,
      default: null,
    }
  },
  data () {
    return {
      inputVal: null
    }
  },
  mounted () {
    this.inputVal = this.$attrs.value
  },
  computed: {
    getName () {
      if (this.$attrs && this.$attrs.name) return this.$attrs.name
      return this.label ? this.label.replace(new RegExp(' ', 'g'), '').toLowerCase() : ''
    },
    showHelper () {
      return this.hasError || (this.helper && this.helper.length > 0)
    },
    hasError () {
      return this.error && this.error.length > 0
    },

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>

.parcel{
    b{
    font-weight: 900 !important;
  }
}

.price-box-info{
  padding-top: 20px !important;

 .space-status{
   width:100% !important;
 }
 .space-description{
   width:100% !important;
 }
  @media(max-width: 990px){
    text-align: right !important;

    // .space-status{
    //   float:none !important;
    //   width: auto !important;
    //   display: inline-block !important;
    //   margin-right: 5px;
    // }
    // .space-description{
    //   float:none !important;
    //   width: auto !important;
    //   display: inline-block !important;
    // }

  }

}
.status-bar{
    width: 100%;
}
.box-status{
  width:100%;
  height: 90px;
  border-radius: 11px;
  color:white;
  line-height: 76px;
  margin: 0px auto;
  padding: 0px 10px;
  margin: 0px 0px !important;
  margin-bottom: 20px !important;

  .ico-ddh{
    float: left;
    margin-top: 6px;
    margin-right: 10px;
    // margin-left: 10px;

    svg{
      font-size: 26px;
      margin-right: 0px;
      float: left;
      width: 60px;
      height: 60px;

      path{
        fill: #fff;
      }
    }

  }

  &.Pendente{
    background-color: #ea2f38;
    color:white;
  }

  &.Pausado{
    background-color: #feaf00;
    color:white;
  }
  &.Pausada{
    background-color: #feaf00;
    color:white;
  }
  &.Vencida{
    background-color: #ea2f38;
    color:white;
  }

  .buttons-actions{
    padding-bottom: 0px;

    .btn{
      margin-left: 10px;
      background-image: none;
    }

    &.Pendente{
      .btn{
        background-color: #bc1526;
      }
    }

    &.Pausado{
      .btn{
        background-color: #f76f00;
      }
    }

    &.Pausada{
      .btn{
        background-color: #f76f00;
      }
    }

    &.Vencida{
      .btn{
        background-color: #bc1526;
      }
    }

  }
}

.status-info{
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #ada1b9;

  .box-text-status{
    @media(max-width: 990px){
      padding: 2px 7px !important;
    }
  }

  .space-status{
    width:100%;
    display: block;

    @media(max-width: 990px){
      width:50%;
      float:left;
    }
  }

  .space-description{
    width:100%;
    display: block;

    @media(max-width: 990px){
      width: 50%;
      float: left;
      text-align: right;
    }
  }

  b{
    color:#000;
    font-weight: 400;
  }

  .ico-ddh{
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #1ad3d9;
    text-align: center;
    line-height: 46px;
    margin-right: 10px;

    @media(max-width: 990px){
      background: none !important;
      width: 24px;
      height: 21px;
    }
    svg{
      font-size: 26px;
      margin-right: 0px;
      display: inline-block;

      @media(max-width: 990px){
        margin-top: -20px;
        margin-left: -6px;
      }
      path{
        fill: #fff;

        @media(max-width: 990px){
          fill: #1ad3d9;
        }
      }
    }

    &.ico-plan{
      background-color: #1ad3d9 !important;

      @media(max-width: 990px){
        background-color: transparent !important;
      }
    }
  }

  .phrase{
    padding-right: 10px;
    padding-top: 7px;
    float: left;
    line-height: 1.36;

    @media(max-width: 990px){
      //width: 100%;
      width: calc(100% - 36px);
      padding-right: 0px;
    }
  }

  &.Pendente{
    .ico-ddh{
      background-color: #ea2f38;
    }
  }

  &.Pausado{
    .ico-ddh{
      background-color: #feaf00;
    }
  }

  &.Pausada{
    .ico-ddh{
      background-color: #feaf00;
    }
  }

  &.Vencida{
    .ico-ddh{
      background-color: #ea2f38;
    }
  }
}
</style>
