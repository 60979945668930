<template>
	<div class="vuestic-tabs">
		<div>
			<nav class="nav nav-pills">
				<div
					class="nav-item-ddh"
					v-for="(value, key) in items"
					:class="{ active: value.isActive }"
					:key="key"
				>
					<span
						class="nav-link"
						@click="setActive(value.label, value.slug, key)"
						><h5>{{ value.label }}</h5></span
					>
				</div>
			</nav>
			<!-- <div class="track">
        <div :class="underscoreClass"></div>
      </div> -->
		</div>

		<!-- <div class="tab-content">
      <div
        class="tab-pane"
        :class="{active: name === currentActive}"
        v-for="name in names"
        :key="name"
      >
        <slot :name="name"></slot>
      </div>
    </div> -->
	</div>
</template>

<script>
	// d-none and d-lg-flex were deleted, bug will be fixed in the nearest update
	export default {
		name: "menu-tabs",
		props: {
			items: {
				type: Array,
				required: false,
			},
		},
		computed: {
			// underscoreClass () {
			//   const self = this
			//   this.items.forEach(function (element, i) {
			//     if (element.isActive) {
			//       // this.setActive(element.label, element.slug, i);
			//       // this.underscoreClass();
			//       self.setActive(element.label, element.slug, i)
			//     }
			//   })
			//   return 'underscore-' + this.items.length + '-' + this.currentIndex
			// },
		},
		methods: {
			setActive(name, slug, key) {
				this.currentActive = name;
				this.currentIndex = key;
				this.$emit("scrollTo", slug);

				this.items.forEach(function (element, i) {
					element.isActive = false;
				});
			},
		},
		data() {
			return {
				currentActive: this.items[0].label,
				currentIndex: this.items[0].label,
			};
		},
	};
</script>

<style lang="scss">
	.nav-item-ddh {
		&.active {
			.nav-link {
				color: #4965bc;
				border-bottom: 5px solid #4965bc;
			}
		}

		.nav-link {
			border-radius: 0px;
			color: #afa2ba;
			border-bottom: 5px solid #fff;
			cursor: pointer;
			padding: 10px 10px;
			margin-right: 15px;

			@media (max-width: 374px) {
				margin-right: 5px;
			}
		}
		h5 {
			text-align: center;
			font-size: 16px;
			font-weight: 200 !important;
			margin-bottom: 10px;
			margin-top: 10px;

			@media (max-width: 990px) {
				margin-bottom: 5px;
				font-size: 14px;
			}

			@media (max-width: 425px) {
				margin-bottom: 5px;
				font-size: 11px;
			}

			@media (max-width: 374px) {
				margin-bottom: 5px;
				font-size: 10px;
			}
		}
	}
	.vuestic-tabs {
		background-color: white;
		.simple-select {
			padding-top: 1.5rem;
		}
		.nav {
			margin: 0;
			padding-top: 2.25rem;
			.nav-item {
				// flex-grow: 1;
				text-align: center;
				padding: 0;
				min-width: 150px;

				@media (max-width: 480px) {
					min-width: auto;
				}
				.nav-link {
					padding: 0;
					font-weight: 600 !important;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.75;
					letter-spacing: 0.9px;
					color: #afa2ba;
					transition: all 0.3s ease;
					text-align: center;

					h5 {
						text-align: center;
						font-size: 16px;
						font-weight: 600 !important;
						margin-bottom: 20px;
						margin-top: 10px;

						@media (max-width: 990px) {
							font-size: 14px;
							margin-bottom: 10px;
						}
					}
				}
				&:hover {
					cursor: pointer;
					.nav-link {
						color: #afa2ba;
					}
				}
				&.active {
					.nav-link {
						color: #4965bc;
					}
				}
			}
		}
		.track {
			overflow: hidden;
			width: 100%;
			height: 4px;
			position: relative;
			div[class^="underscore-"] {
				background-color: #1ad3d9;
				height: 4px;
				position: absolute;
			}
			$koeff: 0.8;
			@for $all from 1 through 10 {
				div[class^="underscore-#{$all}"] {
					// width: $width * $koeff * 100%;
					transition: left 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
				}
				@for $place from 0 through $all {
					.underscore-#{$all}-#{$place} {
						// left: ($place + (1 - $koeff)/2) * $width * 100%;
					}
				}
			}
		}
		.tab-content {
			padding-bottom: $tab-content-pb;
			padding-top: $tab-content-pt;
			> .tab-pane {
				width: 100%;
			}
		}
	}
</style>
