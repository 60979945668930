<template>
	<vuestic-layout v-layout>
		<main
			slot="content"
			id="content"
			class="va-layout gutter--lg fluid no-padding"
			role="main"
		>
			<router-view />
		</main>
	</vuestic-layout>
</template>

<script>
	import VuesticLayout from "../../vuestic-theme/vuestic-components/vuestic-layout/VuesticLayout";
	import AppNavbar from "./app-navbar/AppNavbar";
	import AppSidebar from "./app-sidebar/AppSidebar";
	import AppBreadcrumbs from "./app-breadcrumbs/AppBreadcrumbs";
	import Layout from "../../vuestic-theme/vuestic-directives/Layout";
	import { mapGetters } from "vuex";
	const axios = require("axios");

	export default {
		name: "app-layout",
		components: {
			VuesticLayout,
			AppNavbar,
			AppSidebar,
			AppBreadcrumbs,
		},
		directives: {
			layout: Layout,
		},
		updated: function () {
			// this.addResize();
			window.addEventListener("resize", this.resizeMenu);
		},
		mounted() {},
		data() {
			return {
				opened: true,
				isMobile: false,

				name: "",
				lastName: "",

				isSubscriber: false,

				currentRoute: null,
				// errors: []
			};
		},
		watch: {
			$route(to, from) {
				this.resizeMenu();
			},
		},
		methods: {
			addResize() {},
			toggleSidebar(opened) {
				this.opened = opened;
			},
			detectSize() {
				var menuStatus = false;
				if (window.innerWidth < 990) {
					menuStatus = true;
				}
				return menuStatus;
			},
			resizeMenu() {
				// var menuStatus = false
				if (window.innerWidth < 990) {
					this.isMobile = true;
					this.opened = false;
				} else {
					this.isMobile = false;
					this.opened = true;
				}
				// return menuStatus
			},
		},
		computed: {
			...mapGetters(["isLoading"]),
		},
	};
</script>

<style lang="scss">
	.encerrar {
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 80%;
		text-align: center;
		bottom: 10%;
	}
	.logout {
		&:hover {
			color: red !important;
		}
	}
	.no-border-bottom {
		border-bottom: none !important;
	}
	.clube-dentro {
		padding-bottom: 20px !important;
	}
	.btn-know-more {
		border-radius: 40px;
		border: 1px solid #f1edf5;
		background-color: #fff;
		opacity: 1 !important;
		color: #3bd2d6 !important;
		margin-left: 25px !important;
		font-weight: 900 !important;
		display: block !important;
		width: auto;
		padding: 13px 15px !important;
		float: left;
		margin-bottom: 10px;
	}
	.ddh-sidebar {
		float: left;
		max-width: 230px;
		width: 100%;
		height: calc(100vh);
		position: relative;
		overflow: hidden;

		.sidebar-fixed {
			position: fixed;
			width: 100%;
			background-color: #1ad3d9;
			max-width: 230px;
			height: 100%;

			@media (max-width: 990px) {
				width: 100%;
				max-width: 100%;
			}
		}

		&.hide-sidebar {
			height: 89px;

			@media (max-width: 990px) {
				height: 51px;
			}

			.sidebar-fixed {
				width: 100%;
				position: relative;
				max-width: 100%;
			}
		}

		@media (max-width: 990px) {
			max-width: 100%;
			width: 100%;
			overflow: overlay;
			z-index: 2;
			position: relative;
		}

		.logo {
			height: 89px;
			text-align: center;
			padding-top: 22px;
			border-bottom: 1px solid #0dbec4;
			margin-bottom: 10px;
			display: block;

			@media (max-width: 990px) {
				margin-bottom: 0px;
				padding-top: 10px;
				height: 51px !important;

				svg {
					width: 100%;
					max-width: 90px;
				}
			}
			svg {
				margin: 0 auto;
			}
		}

		.menu-control {
			position: absolute;
			left: 20px;
			top: 8px;
			color: white;
			font-size: 24px;
		}
	}

	.ddh-sidebar-items {
		position: relative;
		height: calc(100vh);

		&.ddh-sidebar-items ul {
			list-style: none;
			padding-left: 0px;
			border-bottom: 1px solid #0dbec4;
			padding-bottom: 10px;

			li {
				a {
					opacity: 0.7;
					position: relative;
					height: 50px;
					padding-left: 25px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					// cursor: pointer;
					text-decoration: none;
					color: white;
					font-weight: 400;

					&:hover {
						color: white;
						opacity: 1;
					}

					&.router-link-active {
						// background-color:#4965bc;
						opacity: 1;
						color: white;

						:before {
							content: "";
							position: absolute;
							width: 5px;
							background-color: #fff;
							left: 0;
							height: 100%;
							top: 0px;
						}
					}
				}

				.person-menu {
					opacity: 0.85;
					position: relative;
					height: 64px;
					padding-left: 25px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					cursor: default;
					text-decoration: none;
					// background-color: #4965bc;
					color: white;
					padding-left: 0px;

					a {
						//padding-left: 0px;

						// &.router-link-active{
						//   span{
						//     &:before{
						//       display: none;
						//     }
						//   }
						// }
					}
				}
				svg {
					opacity: 0.85;
					margin-left: -5px;
					margin-right: 10px;
					path {
						fill: #fff;
					}
				}
			}
		}

		.clube-dentro {
			span {
				opacity: 0.7;
				position: relative;
				height: 50px;
				padding-left: 25px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				// cursor: pointer;
				text-decoration: none;
				color: white;
				font-weight: 400;
				font-size: 14px;
				font-weight: bold;
			}
			li:first-child a {
				font-size: 14px;
			}

			li a {
				&.router-link-active {
					// background-color:#4965bc;
					opacity: 1;
					color: white;

					&:before {
						display: block;
						content: "";
						position: absolute;
						width: 5px;
						background-color: #fff;
						left: 0;
						height: 100%;
						top: 0px;
					}
				}
			}

			a {
				height: 40px !important;
			}
		}
	}

	.ddh-header {
		width: 100%;
		height: 89px;
		background-color: #4965bc;

		@media (max-width: 990px) {
			width: 100%;
			height: 126px;
			background-color: #4965bc;
			display: none;
		}
		.info-person {
			float: right;
			margin-right: 30px;
			margin-top: 20px;
			cursor: pointer;
			text-transform: uppercase;
			z-index: 9;
			position: relative;

			@media (max-width: 990px) {
				margin: 0 auto;
				margin-top: 12px;
				float: none;
				display: block;
				text-align: center;
			}

			&:hover {
				.ico {
					opacity: 0.65;
				}
				.person-name span {
					opacity: 0.65;
				}
				.person-name .menu-dropdown {
					display: block;
				}
			}

			// &:hover{
			//   .menu-dropdown{
			//     display: block;
			//   }
			// }
			.ico-person {
				// float: left;
				float: left;
				line-height: 44px;

				@media (max-width: 990px) {
					float: none;
					width: 100%;
					text-align: center;
				}
			}

			.person-name {
				margin-right: 15px;

				@media (max-width: 990px) {
					display: inline-block;
					position: relative;
				}
				.ico {
					float: left;
					margin-right: 10px;

					svg {
						path {
							fill: #ffffff;
						}
					}
				}

				.menu-dropdown {
					display: none;
					position: absolute;
					top: 50px;
					// border-top: 10px solid #907feb;
					border-radius: 10px;
					width: 260px;
					border-radius: 10px;
					-webkit-box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
					box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
					background-color: #ffffff;
					padding: 20px;
					padding-top: 0px;
					padding-bottom: 10px;
					right: 15px;

					@media (max-width: 990px) {
						right: 0;
						left: -41px;
						margin-right: auto;
						margin-left: auto;
						top: 50px;
						z-index: 2;
					}

					a {
						font-family: Nunito;
						font-size: 16px;
						font-weight: 600;
						font-style: normal;
						font-stretch: normal;
						letter-spacing: normal;
						color: #68596e;
						// border-bottom: 1px solid #dfdae4;
						width: 100%;
						display: block;
						height: 57px;
						position: relative;
						line-height: 60px;
						text-transform: initial;

						&:hover {
							color: #1ad3d9;
						}
						&:last-child {
							border-top: 1px solid #dfdae4;
							border-bottom: 0px;
							font-size: 14px;

							&:before {
								display: none;
							}
						}

						&:before {
							content: "\F054";
							color: #1ad3d9;
							position: absolute;
							top: 24px;
							width: 4px;
							height: 4px;
							font: normal normal normal 14px/1 FontAwesome;
							right: 9px;
							margin-left: auto;
							margin-right: auto;
						}
					}

					&:before {
						content: "\F0D8";
						color: #fff;
						position: absolute;
						top: -10px;
						width: 4px;
						height: 4px;
						font: normal normal normal 14px/1 FontAwesome;
						right: 39%;

						@media (max-width: 990px) {
							right: 0px;
							left: 0px;
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
			}

			span {
				float: left;
				height: 52px;
				font-family: Roboto;
				font-size: 14px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: 3.5;
				letter-spacing: 0.8px;
				text-align: right;
				color: #ffffff;

				&:before {
					content: "";
				}
			}

			.ico-cart {
				position: relative;
				width: 50px;
				height: 50px;
				border-radius: 100px;
				background-color: #ffffff;
				float: right;
				text-align: center;
				padding-top: 7px;

				svg {
					// position: relative;
					// left: -3px;
					// top: 7px;

					path {
						fill: #afa2ba;
					}
				}

				span {
					position: absolute;
					width: 20px;
					height: 30px;
					top: 8px;
					left: 2px;
					right: 0;
					font-size: 9px;
					text-align: center;
					margin-right: auto;
					margin-left: auto;
				}
			}
		}
	}
</style>
