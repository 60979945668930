<template>
	<div class="my-account">
		<div class="va-row">
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding">
							<div class="">
								<div class="va-row">
									<!-- <div class="error-label" v-if="errors.length > 0">
                          {{errors[0].message}}
                      </div> -->

									<!-- <div class="tab">
										<button
											:class="upload ? '' : 'active'"
											@click="changeUploadStatus(false)"
										>
											Cadastrar consultor
										</button>
									</div> -->

									<div class="flex xs12 no-padding" v-if="!upload">
										<!-- {{grade}} -->
										<form @submit.prevent="save" class="va-row">
											<div class="flex xs12 md12 no-padding">
												<input-normal
													type="text"
													label="Nome"
													name="name"
													v-validate="'required'"
													:disabled="false"
													:data-vv-as="'nome'"
													:value="people.name"
													:error="errors.first('name')"
													:required="true"
													@input="people.name = $event.target.value"
												/>

												<input-normal
													type="text"
													label="E-mail"
													name="email"
													v-validate="'required'"
													:disabled="false"
													:data-vv-as="'email'"
													:value="people.email"
													:error="errors.first('email')"
													:required="true"
													@input="people.email = $event.target.value"
												/>

												<input-normal
													type="text"
													label="Telefone de contato"
													name="phone"
													v-validate="'required'"
													v-mask="['(##) ####-####', '(##) #####-####']"
													:disabled="false"
													:data-vv-as="'telefone'"
													:value="people.phone"
													:error="errors.first('phone')"
													:required="true"
													@input="people.phone = $event.target.value"
													v-model="people.phone"
												/>

												<input-normal
													type="cpf"
													label="CPF/CNPJ"
													name="document"
													v-validate="'required'"
													v-mask="['###.###.###-##', '##.###.###/####-##']"
													:disabled="false"
													:data-vv-as="'CPF/CNPJ'"
													:value="people.document"
													:error="errors.first('document')"
													:required="true"
													@input="people.document = $event.target.value"
												/>

												<select-normal
													:items="sectors_list"
													:value="people.sector_id"
													label="Selecione a região"
													name="typeName"
													:required="true"
													@change="onChangeSector($event)"
												/>

												<input-normal
													type="text"
													label="Matrícula"
													name="external_id"
													:disabled="false"
													:data-vv-as="'external_id'"
													:value="people.external_id"
													:error="errors.first('external_id')"
													:required="true"
													@input="people.external_id = $event.target.value"
												/>
											</div>

											<hr />

											<div class="flex md12 text-right">
												<div class="error error-label text-center">
													{{ errors.first("error") }}
												</div>

												<button
													type="button"
													class="btn btn-primary cancel"
													v-on:click="close()"
												>
													CANCELAR
												</button>

												<button
													type="submit"
													class="btn right"
													v-if="typeSend !== 'post'"
												>
													SALVAR EDIÇÃO
												</button>

												<button type="submit" class="btn right" v-else>
													ADICIONAR
												</button>
											</div>
										</form>
									</div>

									<div class="flex xs12 no-padding" v-if="upload">
										<div class="" v-if="uploadStep === 1">
											<!-- <h3>Maternal 2 - Período Manhã - 2017</h3> -->

											<br />

											<p>
												Você pode cadastrar automaticamente vários alunos
												enviando uma lista de alunos formatada corretamente.<br />
												Leia as seguintes instruções sobre como formatar o
												arquivo:
											</p>

											<div class="va-row">
												<div class="flex xs6">
													<b>1. Formato do arquivo:</b>
													Arquivo .CSV

													<br /><br />

													<b>2. Colunas</b> (campo obrigatório):
													<ul>
														<li>Nome da escola</li>
														<li>Tipo da escola</li>
														<li>Endereço</li>
														<li>Numero</li>
														<li>Bairro</li>
														<li>Complemento</li>
														<li>CEP</li>
														<li>Cidade</li>
														<li>Estado</li>
														<li>Responsável</li>
														<li>E-mail</li>
														<li>Telefone</li>

														<!-- <li>Nome do estudante</li>
                                <li>Level</li>
                                <li>Gênero</li> -->
													</ul>
													<!-- Não obrigatório
                              <ul>
                                <li>Data de aniversário</li>
                              </ul> -->

													<br />
													<b>3. Exemplo de como preencher a lista</b>
													<!-- Nome: Maria, Nelson, Diego, Maura
                              Level: Maternal I, Maternal II
                              Gênero: Masuclino, Feminino, M, F
                              Aniversário: 06/05/1988, 06-05-1988, 0605 -->
												</div>

												<div class="flex xs12">
													<div class="upload-box">
														<h3>Baixe o arquivo-modelo aqui</h3>
														<a
															href="https://docs.google.com/spreadsheets/d/17RO-TSMUU2jD5vxFdEEdlds10Cm07rKvvP-lwprnH2Q/edit#gid=0"
															target="blank"
															class="btn btn-primary cancel"
															><span class="fa fa-chevron-down"></span> FAZER
															DOWNLOAD</a
														>
													</div>
												</div>

												<div class="flex md12 text-right">
													<button
														@click="changeStepsUpload(2)"
														class="btn btn-primary right"
													>
														AVANÇAR
													</button>
												</div>
											</div>
										</div>

										<div class="" v-if="uploadStep === 2">
											<div class="upload-box">
												<h3>
													Faça o upload da versão mais atualizada do seu arquivo
													de escolas
												</h3>
												<button
													v-on:click="clickOnFile()"
													class="btn btn-primary cancel"
												>
													<span class="fa fa-chevron-up"></span> FAZER UPLOAD
												</button>

												<!-- <button v-on:click="clickOnFile()"
                                      class="btn btn-change">
                                      UPLOAD PLANILHA
                                  </button>
                                   -->
												<input
													type="file"
													id="files"
													class="hide"
													ref="files"
													v-on:change="handleFileUpload()"
												/>
											</div>

											<div class="flex xs12 no-padding-right">
												<button
													type="button"
													class="btn btn-primary right cancel"
													v-on:click="close()"
												>
													CANCELAR
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	const axios = require("axios");

	export default {
		name: "people-edit",
		directives: { mask },
		components: {
			// vuescroll
			// VueScrollTo
			// DataVisualisationTab,
		},

		props: ["people", "typeSend"],

		data() {
			return {
				openAddressField: false,
				cepSearch: false,
				types: [],

				upload: false,
				uploadStep: 1,

				sectors_list: [],
			};
		},

		computed: {
			...mapGetters({
				managerCredential: "managerCredential",
				// ...
			}),
			// people: function() {
			//   return this.people;
			// },
		},

		watch: {
			// getPeople: function () {
			//   this.people = this.people
			// },
		},

		mounted() {
			const self = this;

			let config = {
				headers: {
					Authorization: "Bearer " + this.managerCredential.token,
				},
			};

			axios
				.get(process.env.VUE_APP_ROOT_API + "/company_sectors", config)
				.then((response) => {
					response.data.data.sectors.forEach((element) => {
						var item = { key: element.id, value: element.name };
						self.sectors_list.push(item);
					});
				})
				.catch((e) => {
					this.errors.push(e);
				});
		},

		//
		methods: {
			onChangeSector(event) {
				this.people.sector_id = event.target.value;
				//this.address = event.target.value
			},

			close: function () {
				this.$root.$emit("closeModal");
			},

			addPeople: function (people) {
				this.$root.$emit("addPeople", people);
			},

			editPeople: function (people) {
				this.$root.$emit("editPeople", people);
			},

			clickOnFile() {
				this.$refs.files.click();
			},

			changeStepsUpload(step) {
				this.uploadStep = step;
			},

			changeUploadStatus(status) {
				this.upload = status;
			},

			onChangeGender(event) {
				this.people.gender = event.target.value;
				//this.address = event.target.value
			},

			onChangeType(event) {
				this.school.type = event.target.value;
				//this.address = event.target.value
			},

			onChangeProject(event) {
				this.school.project_id = event.target.value;
				//this.address = event.target.value
			},

			onChangeState(event) {
				this.school.address.stateId = event.target.value;
				//this.address = event.target.value
			},

			submitFiles() {
				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + this.managerCredential.token,
						},
					})
					.then(function () {
						alert("Upload feito com sucesso");
						window.location.reload();
					})
					.catch(function () {
						alert("Erro, upload falhou, entre em contato com nossa equipe.");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			getSelectedId(id) {
				let response = false;

				let objectId = this.subscriptionSend.subscriptions.find((c) => {
					return c.id === id;
				});
				if (objectId) {
					if (objectId.id === id) {
						response = true;
					}
				}

				// if(id == this.address.id){
				//   response = true
				// }

				return response;
			},

			save() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};
				this.errors.clear();

				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line
						let url =
							self.typeSend == "put"
								? process.env.VUE_APP_ROOT_API +
								  "/salesperson/" +
								  self.people.id
								: process.env.VUE_APP_ROOT_API + "/salesperson/";

						let data = self.people;
						if (self.typeSend == "put") {
							data = {
								name: this.people.name,
								email: this.people.email,
								phone: this.people.phone,

								document: this.people.document,
								sector_id: this.people.sector_id,
								external_id: this.people.external_id,
								// username:this.people.username,
								// birthdate: this.formatDate(this.people.birthdate),

								// gender: this.people.gender,
							};
							//delete data.address.created_at;
						}

						axios({
							method: self.typeSend.toUpperCase(),
							url: url,
							headers: config.headers,
							data: data,
						})
							.then(function (response) {
								if (response.data.success !== false) {
									//self.$router.push({ name: 'schools' })

									if (self.typeSend == "put") {
										self.editPeople(response.data.data.user);
										self.close();
									} else {
										self.addPeople(response.data.data.user);
										self.close();
									}

									//self.percentCall = 0
								} else {
									//self.errors.push(response.data.errors[0]);
								}
							})
							.catch(function (error) {
								//console.log(error)

								let fieldError = {
									field: "error",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},

			formatDate(hour) {
				var hourFormated = hour.split("/");
				return hourFormated[2] + "-" + hourFormated[1] + "-" + hourFormated[0];
				//return "YYYY-MM-DD";
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},
		},
	};
</script>
<style lang="scss" scoped>
	.hide {
		display: none;
	}
	.upload-box {
		border: 1px dashed #ccc;
		text-align: center;
		border-width: 2px;
		border-radius: 10px;
		padding-bottom: 40px;
	}

	.tab-item {
		border: 1px solid #ccc;
	}

	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.fiscal-notes ::v-deep .form-group:last-child {
		margin-bottom: 10px !important;
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// Default of page

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 30px 30px 10px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
</style>
