<template>
	<div class="vuestic-modal">
		<transition name="modal" :duration="duration">
			<div v-show="show" class="modal-container">
				<div class="modal" @click.self="clickMask">
					<div class="modal-dialog modal-follow">
						<div class="modal-content">
							<!--Container-->
							<div class="modal-body">
								<div class="va-row">
									<div class="flex md8 xs12 no-padding">
										<h1>Atualizar nome</h1>

										<i
											class="ion ion-md-close close-modal"
											v-if="closeIconShown"
											@click.prevent="cancel"
										/>

										<p>
											O nome no livro personalizado<br />
											{{ bookName }}<br />
											foi alterado para:<br />
											<b class="uppercase">{{ characterName }}</b>
											<br /><br />
											Você deseja atualizar somente este<br />
											ou todos os próximos Kits?
										</p>
										<!-- <p>O nome no livro {{bookName}} foi alterado para:<br>
                      <b>{{characterName}}</b>
                    </p> -->
									</div>

									<!-- https://builder.dentrodahistoria.com.br/api/builder/getpreview/ddh5d264d5831ef6?page=1&w=500&v=0&nome=jenifer&noCache=true -->
									<div class="flex md4 xs12 img-book-name">
										<img
											:src="
												'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
												bookImage +
												'?page=1&w=533&v=0&nome=' +
												characterName +
												'&noCache=true'
											"
											width="100%"
											alt=""
											class=""
										/>
									</div>
								</div>

								<!-- <b>Número do pedido:</b> {{bookName}}<br>
                <b>Data do pedido:</b> {{bookName}}<br>
                <br>
                <b>Código de rastreamento:</b> <a :href="codeUrl" target="_blank">{{bookName}}</a> -->

								<hr />
								<slot></slot>
							</div>
							<!--Footer-->
							<div class="modal-footer">
								<slot name="footer text-right">
									<button
										class="btn btn-primary btn-detail"
										@click="updateAll()"
									>
										ATUALIZAR TODOS
									</button>
									<button class="btn btn-primary" @click="update()">
										ATUALIZAR SOMENTE ESTE
									</button>
									<!-- <button type="button" v-if="!noButtons" :class="'btn btn-primary btn-detail'">
                    DETALHES DO PEDIDO
                  </button> -->
									<!-- <button type="button" v-if="!noButtons" :class="okClass"
                          @click="ok" :disabled="okDisabled">
                    {{ okText }}
                  </button> -->
								</slot>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-backdrop"></div>
			</div>
		</transition>
	</div>
</template>

<script>
	const axios = require("axios");

	export default {
		name: "name-modal",
		props: {
			transition: {
				type: String,
				default: "modal",
			},
			small: {
				type: Boolean,
				default: false,
			},
			large: {
				type: Boolean,
				default: false,
			},
			force: {
				type: Boolean,
				default: false,
			},
			okText: {
				type: String,
				default: "CONFIRM",
			},
			cancelText: {
				type: String,
				default: "CANCEL",
			},
			okClass: {
				type: String,
				default: "btn btn-primary",
			},
			cancelClass: {
				type: String,
				default: "btn btn-secondary",
			},
			closeIconShown: {
				type: Boolean,
				default: true,
			},
			okDisabled: {
				type: Boolean,
				default: false,
			},
			cancelDisabled: {
				type: Boolean,
				default: false,
			},
			noButtons: {
				type: Boolean,
				default: false,
			},

			subscriptionId: {
				type: Number,
				default: 0,
			},
			subscriptionStackId: {
				type: Number,
				default: 0,
			},

			bookName: {
				type: String,
				default: "false",
			},
			bookImage: {
				type: String,
				default: "false",
			},
			characterName: {
				type: String,
				default: "false",
			},
			BuilderCharacterId: {
				type: String,
				default: "false",
			},
			BuilderProductId: {
				type: String,
				default: "false",
			},
			oldName: {
				type: String,
				default: "false",
			},
		},
		data() {
			return {
				show: false,
				duration: 500,
				randomMath: Math.floor(Math.random() * 1000),
			};
		},
		computed: {
			modalClass() {
				return {
					"modal-lg": this.large,
					"modal-sm": this.small,
				};
			},
		},
		created() {
			if (this.show) {
				document.body.className += " modal-open";
			}
		},
		beforeDestroy() {
			document.body.className = document.body.className.replace(
				/\s?modal-open/,
				""
			);
		},
		watch: {
			show(value) {
				if (value) {
					this.randomMath = Math.floor(Math.random() * 1000);
					document.body.className += " modal-open";
				} else {
					window.setTimeout(() => {
						document.body.className = document.body.className.replace(
							/\s?modal-open/,
							""
						);
					}, this.duration);
				}
			},
		},
		methods: {
			listenKeyUp(event) {
				if (event.key === "Escape") {
					this.cancel();
				}
			},
			ok() {
				this.$emit("ok");
				this.show = false;
				window.removeEventListener("keyup", this.listenKeyUp);
			},
			cancel() {
				this.$emit("cancel");
				this.show = false;
				window.removeEventListener("keyup", this.listenKeyUp);
			},
			clickMask() {
				if (!this.force) {
					this.cancel();
				}
			},
			open() {
				this.show = true;
				this.randomMath = Math.floor(Math.random() * 1000);
				window.addEventListener("keyup", this.listenKeyUp);
			},
			close() {
				this.$emit("cancel");
				this.show = false;
				window.removeEventListener("keyup", this.listenKeyUp);
			},

			update() {
				const router = this.$router;

				var request = {
					CharacterName: this.characterName,
					BuilderCharacterId: this.BuilderCharacterId,
				};

				axios
					.put(
						process.env.VUE_APP_ROOT_API +
							"/subscriptions/" +
							this.subscriptionId +
							"/stack/" +
							this.subscriptionStackId +
							"/character",
						request
					)
					.then(function (response) {
						console.log(response);
						router.push({ name: "next-kits" });
					})
					.catch(function (error) {
						console.log(error);
						router.push({ name: "next-kits" });
					});
			},

			updateAll() {
				const router = this.$router;
				var request = {
					CharacterName: this.characterName,
					BuilderCharacterId: this.BuilderCharacterId,
				};

				axios
					.put(
						process.env.VUE_APP_ROOT_API +
							"/subscriptions/" +
							this.subscriptionId +
							"/stack/character/all",
						request
					)
					.then(function (response) {
						console.log(response);
						router.push({ name: "next-kits" });
					})
					.catch(function (error) {
						console.log(error);
						router.push({ name: "next-kits" });
					});
			},
		},
	};
</script>

<style lang="scss">
	hr {
		margin: 10px -1.5rem;
		margin-bottom: 0px;
	}
	.uppercase {
		text-transform: uppercase;
	}
	.modal-body {
		padding-bottom: 15px;

		p {
			line-height: 21px;
		}
	}
	.img-book-name img {
		@media (max-width: 480px) {
			width: 50%;
			display: block;
			margin: 0 auto;
		}
	}
	.btn-detail {
		border-radius: 40px;
		border: solid 1px #dfdae4 !important;
		background-image: none !important;
		background-color: #fff;
		color: #3bd3d6;
		margin-bottom: 20px;
	}
	h1 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		margin-top: 0px;
		margin-bottom: 15px;
	}
	b {
		font-weight: 900;
	}

	hr {
		margin-bottom: 0px;
	}
	.modal-follow {
		max-width: 460px;
		border-radius: 10px !important;
		background-color: #ffffff;
	}
	//Modals
	$modal-header-padding-x: $widget-padding;
	$modal-header-padding-y: 0;
	$modal-header-height: $widget-header-height;
	$modal-header-border: $widget-header-border;
	$modal-content-border-width: 0;
	$modal-content-border-radius: 0;
	$modal-inner-padding: 25px;
	$modal-footer-btns-padding-bottom: 20px;
	$modal-footer-btns-margin-x: 10px;
	$modal-md: 650px;
	$modal-lg: 850px;

	.modal-content {
		border-radius: 10px !important;
	}
	::v-deep .modal-content {
		border-radius: 10px !important;
	}
	.vuestic-modal {
		height: 0;
		width: 0;

		// For Transitioning
		.modal {
			display: block;
		}

		.modal-dialog,
		.modal-backdrop {
			transition: all 0.5s ease;
		}

		.modal-enter .modal-dialog,
		.modal-leave-active .modal-dialog {
			opacity: 0;
			transform: translateY(-30%);
		}

		.modal-enter .modal-backdrop,
		.modal-leave-active .modal-backdrop {
			opacity: 0;
		}

		.modal-backdrop {
			opacity: 0.8;
		}

		//Modal styles

		.modal-header {
			height: $modal-header-height;
			padding: $modal-header-padding-y $modal-header-padding-x;
			border-bottom: $modal-header-border;
			font-size: $font-size-larger;
			display: flex;
			align-items: center;
		}

		.close-modal {
			margin-left: 1rem;
			font-size: $font-size-large;
			line-height: $font-size-large;
			cursor: pointer;

			display: none;

			@media (max-width: 990px) {
				display: block;
				position: absolute;
				right: 20px;
				top: 15px;
			}
		}

		.modal-content {
			border-radius: $modal-content-border-radius;
		}

		.modal-footer {
			justify-content: flex-end;
			padding: 0 $modal-inner-padding;
			padding-bottom: calc(
				#{$modal-inner-padding} - #{$modal-footer-btns-padding-bottom}
			);
			flex-wrap: wrap;
			padding-bottom: 15px;

			.btn {
				margin: 10px 5px;
				margin-top: 0px;
				margin-bottom: 0px;

				@media (max-width: 990px) {
					width: 100%;
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 0px;
					}
				}

				&:last-child {
					margin-right: 0px;
				}
			}
			// .btn {
			//   margin: 0 $modal-footer-btns-margin-x $modal-footer-btns-padding-bottom $modal-footer-btns-margin-x;
			// }
		}

		.modal-dialog {
			width: 96% !important;
			max-width: 600px;
			// box-shadow: $modal-content-box-shadow-sm-up;
			border-radius: 10px !important;
			overflow: hidden !important;

			@media (min-width: 990px) {
				margin-top: 13%;
			}
		}
	}

	// ::v-deep .close-modal{
	//   display: none;
	// }
</style>
