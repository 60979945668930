<template>
	<div class="my-account">
		<div class="va-row">
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding">
							<div class="">
								<div class="va-row">
									<!-- <div class="error-label" v-if="errors.length > 0">
                          {{errors[0].message}}
                      </div> -->

									<div class="tab">
										<button
											:class="upload ? '' : 'active'"
											@click="changeUploadStatus(false)"
										>
											Cadastrar Adoção
										</button>
										<button
											:class="upload ? 'active' : ''"
											@click="changeUploadStatus(true)"
											v-if="typeSend === 'post'"
										>
											Cadastrar Múltiplos
										</button>
									</div>

									<div class="tab-item flex xs12" v-if="!upload">
										<!-- {{grade}} -->

										<div class="flex xs12 md12 no-padding">
											<div class="" v-if="step1">
												<form @submit.prevent="saveStep1" class="va-row">
													<input-normal
														type="text"
														label="Nome"
														name="name"
														v-validate="'required'"
														:disabled="false"
														:data-vv-as="'nome'"
														:value="project.name"
														:error="errors.first('name')"
														@input="project.name = $event.target.value"
													/>

													<input-normal
														type="text"
														label="Descrição"
														name="descripition"
														v-validate="'required'"
														:disabled="false"
														:data-vv-as="'descrição'"
														:value="project.descripition"
														:error="errors.first('descripition')"
														@input="project.descripition = $event.target.value"
													/>

													<input-normal
														type="text"
														label="Data Limite"
														name="deadline"
														v-validate="'required'"
														v-mask="['##/##/####']"
														:disabled="false"
														:data-vv-as="'data limite'"
														:value="project.deadline"
														:error="errors.first('deadline')"
														@input="project.deadline = $event.target.value"
													/>

													<input-normal
														type="number"
														label="Ano"
														name="year"
														v-validate="'required'"
														:disabled="false"
														:data-vv-as="'ano'"
														:value="project.year"
														:error="errors.first('year')"
														@input="project.year = $event.target.value"
													/>

													<div class="va-row no-padding">
														<div class="flex xs4 no-padding-left">
															<input-normal
																type="number"
																label="Número de escolas"
																name="schools"
																v-validate="'required'"
																:disabled="false"
																:data-vv-as="'número de escolas'"
																:value="project.schools"
																:error="errors.first('email')"
																@input="project.schools = $event.target.value"
															/>
														</div>

														<div class="flex xs4">
															<input-normal
																type="number"
																label="Total de livros"
																name="books"
																v-validate="'required'"
																:disabled="false"
																:data-vv-as="'número de livros'"
																:value="project.books"
																:error="errors.first('books')"
																@input="project.books = $event.target.value"
															/>
														</div>

														<div class="flex xs4 no-padding-right">
															<input-normal
																type="number"
																label="Alunos"
																name="class_size"
																v-validate="'required'"
																:disabled="false"
																:data-vv-as="'alunos'"
																:value="project.class_size"
																:error="errors.first('class_size')"
																@input="
																	project.class_size = $event.target.value
																"
															/>
														</div>
													</div>

													<div class="va-row">
														<select-normal
															:items="[
																{ key: 'true', value: 'Sim' },
																{ key: 'false', value: 'Não' },
															]"
															:value="project.teacher_book"
															label="Professor irá receber livro?"
															name="typeName"
															@change="onChangeTeacherBook($event)"
														/>
													</div>

													<div class="flex md12 text-right">
														<div class="error text-center">
															{{ errors.first("error") }}
														</div>
														<button type="submit" class="btn btn-primary right">
															SALVAR
														</button>
														<button
															type="button"
															class="btn btn-primary right cancel"
															v-on:click="close()"
														>
															CANCELAR
														</button>
													</div>
												</form>
											</div>

											<div v-if="step2">
												<form @submit.prevent="saveStep2" class="va-row">
													<div class="field-add">
														<div class="va-row">
															<div class="flex xs12">
																<h5>Cadastrar Produtos</h5>
															</div>

															<div class="flex xs8">
																<select-normal
																	:items="grades"
																	:value="project.gender"
																	label="Selecione a turma"
																	name="typeName"
																	@change="onChangeGrade($event)"
																/>
															</div>
															<div class="flex xs4">
																<button
																	class="btn btn-primary"
																	type="button"
																	@click.prevent="addGrade()"
																>
																	ADICIONAR
																</button>
															</div>
														</div>
													</div>

													<div
														class="item-grades"
														v-for="(grade, id) in gradesTypes"
														:key="id"
													>
														<div class="va-row grade-product-line">
															<div class="flex xs12">
																<h4>{{ grade.value }}</h4>
															</div>

															<div class="flex xs12">
																<label>Projeto principal:</label>

																<select-normal
																	:items="products"
																	:value="project.gender"
																	label="Selecione o livro principal"
																	name="typeName"
																	@change="onChangeMainProduct($event, grade)"
																/>
															</div>

															<div class="flex xs12">
																<label>Livros Extras: </label>

																<div class="field-add">
																	<div class="va-row">
																		<div class="flex xs8">
																			<select-normal
																				:items="grade.productsExtra"
																				:value="project.gender"
																				label="Selecione a turma"
																				name="typeName"
																				@change="
																					onChangeExtraProduct($event, grade)
																				"
																			/>
																		</div>
																		<div class="flex xs4">
																			<button
																				class="btn btn-primary"
																				type="button"
																				@click.prevent="addExtra(grade)"
																			>
																				ADICIONAR
																			</button>
																		</div>
																	</div>
																</div>

																<div
																	class="item-grades"
																	v-for="(extra, idExtra) in grade.extras"
																	:key="idExtra"
																>
																	{{ extra.value }}
																</div>
															</div>
														</div>
													</div>

													<div class="flex md12 text-right">
														<div class="error text-center">
															{{ errors.first("error") }}
														</div>
														<button type="submit" class="btn btn-primary right">
															SALVAR
														</button>
														<button
															type="button"
															class="btn btn-primary right cancel"
															v-on:click="close()"
														>
															CANCELAR
														</button>
													</div>
												</form>
											</div>

											<div v-if="step3">
												<form @submit.prevent="saveStep3" class="va-row">
													<input-normal
														type="text"
														label="Preço unitário do livro"
														name="book_price"
														v-validate="'required'"
														:disabled="false"
														:data-vv-as="'preço do livros'"
														:value="project.book_price"
														:error="errors.first('book_price')"
														@input="project.book_price = $event.target.value"
														v-money="money"
													/>

													<!-- <input-normal type="text" 
                                  label="Custo de entrega" 
                                  name="shipping_price"
                                  
                                  v-validate="'required'"
                                  
                                  :disabled="false" 
                                  :data-vv-as="'custo da entrega'"
                                  :value="project.shipping_price" 
                                  :error="errors.first('shipping_price')"
                                  @input="project.shipping_price = $event.target.value"
                                  /> -->

													<select-normal
														:items="school_types"
														:value="project.project_id"
														label="Selecione o tipo da escola"
														name="typeName"
														@change="onChangeProject($event)"
													/>

													<select-normal
														:items="payment_methods"
														:value="project.payment_method_id"
														label="Selecione a forma de pagamento"
														name="typeName"
														@change="onChangePayment($event)"
													/>

													<!-- {{project.payment_method_id}} -->

													<div
														class="teste"
														v-if="
															project.payment_method_id === 1 ||
															project.payment_method_id === '1'
														"
													>
														<div class="va-row">
															<div class="flex xs6">
																<!-- <label>CPF/CNPJ</label> -->

																<input-normal
																	type="cpf"
																	label="CPF/CNPJ"
																	name="document"
																	v-validate="'required'"
																	v-mask="[
																		'###.###.###-##',
																		'##.###.###/####-##',
																	]"
																	:disabled="false"
																	:data-vv-as="'CPF/CNPJ'"
																	:value="paymentDetails.document"
																	:error="errors.first('document')"
																	@input="
																		paymentDetails.document =
																			$event.target.value
																	"
																/>
															</div>

															<div class="flex xs6">
																<!-- <label>CPF/CNPJ</label> -->

																<select-normal
																	:items="[
																		{ key: '1', value: '1x' },
																		{ key: '2', value: '2x' },
																	]"
																	:value="paymentDetails.installment.length"
																	label="N° de parcelas"
																	name="typeName"
																	@change="onChangeInstallment($event)"
																/>
															</div>

															<div class="flex xs12 no-padding">
																<!-- {{project.parcel}} -->

																<div
																	class="va-row"
																	v-for="(
																		installment, index
																	) in paymentDetails.installment"
																	:key="index"
																>
																	<div class="flex xs6">
																		<input-normal
																			type="text"
																			label="Data de vencimento"
																			:name="'date-' + index"
																			v-mask="['##/##/####']"
																			v-validate="'required'"
																			:disabled="false"
																			:data-vv-as="'Data boleto'"
																			:value="installment.dueDate"
																			:error="
																				errors.first(
																					'date-' + installment.value
																				)
																			"
																			@input="
																				installment.dueDate =
																					$event.target.value
																			"
																		/>
																	</div>

																	<div class="flex xs6">
																		<input-normal
																			type="text"
																			label="Valor boleto"
																			:name="'value-' + index"
																			v-money="money"
																			v-validate="'required'"
																			:disabled="false"
																			:data-vv-as="'Valor boleto'"
																			:value="installment.value"
																			:error="
																				errors.first(
																					'date-' + installment.value
																				)
																			"
																			@input="
																				installment.value = $event.target.value
																			"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div class="flex md12 text-right">
														<div class="error text-center">
															{{ errors.first("error") }}
														</div>
														<button type="submit" class="btn btn-primary right">
															SALVAR
														</button>
														<button
															type="button"
															class="btn btn-primary right cancel"
															v-on:click="close()"
														>
															CANCELAR
														</button>
													</div>
												</form>
											</div>
										</div>

										<hr />
									</div>

									<div class="tab-item flex xs12" v-if="upload">
										<div class="" v-if="uploadStep === 1">
											<!-- <h3>Maternal 2 - Período Manhã - 2017</h3> -->

											<br />

											<p>
												Você pode cadastrar automaticamente vários alunos
												enviando uma lista de alunos formatada corretamente.<br />
												Leia as seguintes instruções sobre como formatar o
												arquivo:
											</p>

											<div class="va-row">
												<div class="flex xs6">
													<b>1. Formato do arquivo:</b>
													Arquivo .CSV

													<br /><br />

													<b>2. Colunas</b> (campo obrigatório):
													<ul>
														<li>Nome da escola</li>
														<li>Tipo da escola</li>
														<li>Endereço</li>
														<li>Numero</li>
														<li>Bairro</li>
														<li>Complemento</li>
														<li>CEP</li>
														<li>Cidade</li>
														<li>Estado</li>
														<li>Responsável</li>
														<li>E-mail</li>
														<li>Telefone</li>
													</ul>

													<br />
													<b>3. Exemplo de como preencher a lista</b>
												</div>

												<div class="flex xs12">
													<div class="upload-box">
														<h3>Baixe o arquivo-modelo aqui</h3>
														<a
															href="https://docs.google.com/spreadsheets/d/17RO-TSMUU2jD5vxFdEEdlds10Cm07rKvvP-lwprnH2Q/edit#gid=0"
															target="blank"
															class="btn btn-primary cancel"
															><span class="fa fa-chevron-down"></span> FAZER
															DOWNLOAD</a
														>
													</div>
												</div>

												<div class="flex md12 text-right">
													<button
														@click="changeStepsUpload(2)"
														class="btn btn-primary right"
													>
														AVANÇAR
													</button>
												</div>
											</div>
										</div>

										<div class="" v-if="uploadStep === 2">
											<div class="upload-box">
												<h3>
													Faça o upload da versão mais atualizada do seu arquivo
													de escolas
												</h3>
												<button
													v-on:click="clickOnFile()"
													class="btn btn-primary cancel"
												>
													<span class="fa fa-chevron-up"></span> FAZER UPLOAD
												</button>

												<!-- <button v-on:click="clickOnFile()"
                                      class="btn btn-change">
                                      UPLOAD PLANILHA
                                  </button>
                                   -->
												<input
													type="file"
													id="files"
													class="hide"
													ref="files"
													v-on:change="handleFileUpload()"
												/>
											</div>

											<div class="flex xs12 no-padding-right">
												<button
													type="button"
													class="btn btn-primary right cancel"
													v-on:click="close()"
												>
													CANCELAR
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	const axios = require("axios");

	export default {
		name: "project-edit",
		directives: { mask },
		components: {
			// vuescroll
			// VueScrollTo
			// DataVisualisationTab,
		},

		props: ["project", "typeSend"],

		data() {
			return {
				openAddressField: false,
				cepSearch: false,
				types: [],

				school_types: [],
				payment_methods: [],

				upload: false,
				uploadStep: 1,

				grades: [],
				gradesTypes: [],

				products: [],
				productsExtra: [],

				//numInstallments:0,

				paymentDetails: {
					document: "",
					installment: [],
				},

				currentGrade: null,

				extras: [],

				money: {
					decimal: ",",
					thousands: ".",
					prefix: "R$ ",
					suffix: "",
					precision: 2,
					masked: false /* doesn't work with directive */,
				},

				step1: true,
				step2: false,
				step3: false,
			};
		},

		computed: {
			...mapGetters({
				userCredential: "userCredential",
				// ...
			}),
			// people: function() {
			//   return this.people;
			// },
		},

		watch: {
			// getPeople: function () {
			//   this.people = this.people
			// },
		},

		mounted() {
			const self = this;

			let config = {
				headers: {
					Authorization: "Bearer " + this.userCredential.token,
				},
			};

			// GET SCHOOL TYPES
			axios
				.get(process.env.VUE_APP_ROOT_API + "/school_types", config)
				.then((response) => {
					//self.school_types = response.data.data.types

					var list = [];

					response.data.data.types.forEach((element) => {
						var itemList = {
							key: element.id,
							value: element.name,
						};
						//if (list.indexOf(element.shippingAddressId) === -1) {
						list.push(itemList);
						//}
					});

					self.school_types = list;
				})
				.catch((e) => {
					let fieldError = {
						field: "error",
						msg: error.response.data.message,
						rule: "error", // optional
					};
					self.$validator.errors.add(fieldError);
				});

			// GET PAYMENT METHODS
			axios
				.get(process.env.VUE_APP_ROOT_API + "/payment_methods", config)
				.then((response) => {
					var list = [];
					response.data.data.payments.forEach((element) => {
						var itemList = {
							key: element.id,
							value: element.name,
						};

						list.push(itemList);
					});

					self.payment_methods = list;
				})
				.catch((e) => {
					let fieldError = {
						field: "error",
						msg: error.response.data.message,
						rule: "error", // optional
					};
					self.$validator.errors.add(fieldError);
				});

			// GET GRADE TYPES
			axios
				.get(process.env.VUE_APP_ROOT_API + "/grades_types", config)
				.then((response) => {
					//self.payment_methods = response.data.data.payments
					//self.school_types = response.data.data.types
					//self.project.products = response.data.data.products

					var list = [];

					response.data.data.grade_types.forEach((element) => {
						var itemList = {
							key: element.id,
							value: element.name,
						};

						list.push(itemList);
					});

					self.grades = list;
				})
				.catch((e) => {
					let fieldError = {
						field: "error",
						msg: error.response.data.message,
						rule: "error", // optional
					};
					self.$validator.errors.add(fieldError);
				});

			// GET PRODUCTS
			axios
				.get(process.env.VUE_APP_ROOT_API + "/products", config)
				.then((response) => {
					var list = [];

					response.data.data.products.forEach((element) => {
						var itemList = {
							key: element.id,
							value: element.name,
						};

						list.push(itemList);
					});

					self.products = list;
					self.productsExtra = list;
				})
				.catch((e) => {
					let fieldError = {
						field: "error",
						msg: error.response.data.message,
						rule: "error", // optional
					};
					self.$validator.errors.add(fieldError);
				});
		},

		//
		methods: {
			// updatePrice ({ e, target }) {
			//   //let price //= this.project.book_price

			//   // var currency = "$1.100,00";
			//   // currency.replace(/[$.]+/g,"");
			//   // var result = this.project.book_price

			// },

			onChangeMainProduct(event, grade) {
				grade.main = event.target.value;
			},

			onChangeTeacherBook(event) {
				this.project.teacher_book = Boolean(event.target.value);
			},

			onChangeExtraProduct(event, grade) {
				grade.currentExtra = event.target.value;
			},

			addExtra(grade) {
				const self = this;

				grade.productsExtra.forEach((element) => {
					if (element.key === parseInt(grade.currentExtra)) {
						let itemPosition = grade.productsExtra.indexOf(element);

						grade.extras.push(element);
						grade.productsExtra.splice(itemPosition, 1);

						this.$forceUpdate();
					}
				});
			},

			addGrade() {
				const self = this;

				self.grades.forEach((element) => {
					if (element.key === parseInt(self.currentGrade)) {
						let itemPosition = self.grades.indexOf(element);

						self.grades[itemPosition].productsExtra = self.jsonCopy(
							self.products
						);
						self.grades[itemPosition].currentExtra = 0;
						self.grades[itemPosition].extras = [];

						self.gradesTypes.push(self.grades[itemPosition]);
						self.grades.splice(itemPosition, 1);
					}
				});
			},

			close: function () {
				this.$root.$emit("closeModal");
			},

			addProject: function (project) {
				this.$root.$emit("addProject", project);
			},

			editProject: function (project) {
				this.$root.$emit("editProject", project);
			},

			clickOnFile() {
				this.$refs.files.click();
			},

			changeStepsUpload(step) {
				this.uploadStep = step;
			},

			changeUploadStatus(status) {
				this.upload = status;
			},

			onChangeGrade(event) {
				this.currentGrade = event.target.value;
				//this.address = event.target.value
			},

			onChangeType(event) {
				this.school.type = event.target.value;
				//this.address = event.target.value
			},

			onChangeProject(event) {
				this.project.project_id = event.target.value;
				//this.address = event.target.value
			},

			onChangePayment(event) {
				this.project.payment_method_id = event.target.value;
				//this.address = event.target.value
			},

			onChangeState(event) {
				this.school.address.stateId = event.target.value;
				//this.address = event.target.value
			},

			onChangeInstallment(event) {
				const self = this;

				//self.numInstallments = event.target.value
				self.paymentDetails.installment = [];

				var currency = parseFloat(
					this.project.book_price.replace("R$ ", "").replace(",", ".")
				);
				var finalPrice = currency * self.project.books;
				var result = (finalPrice / parseInt(event.target.value)).toFixed(2);

				self.$forceUpdate();

				this.$nextTick(() => {
					for (var i = 1; i <= event.target.value; i++) {
						let installment = {
							dueDate: "",
							value: result,
						};
						self.paymentDetails.installment.push(installment);
					}

					self.$forceUpdate();
				});

				//this.address = event.target.value
			},

			submitFiles() {
				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + this.userCredential.token,
						},
					})
					.then(function () {
						alert("Upload feito com sucesso");
						window.location.reload();
					})
					.catch(function () {
						alert("Erro, upload falhou, entre em contato com nossa equipe.");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			getSelectedId(id) {
				let response = false;

				let objectId = this.subscriptionSend.subscriptions.find((c) => {
					return c.id === id;
				});
				if (objectId) {
					if (objectId.id === id) {
						response = true;
					}
				}

				// if(id == this.address.id){
				//   response = true
				// }

				return response;
			},

			getProducts() {
				const self = this;

				var list = [];

				self.gradesTypes.forEach((element) => {
					var itemList = {
						grade_type_id: element.key,
						main: parseInt(element.main),
						extra: [],
					};

					element.extras.forEach((element) => {
						itemList.extra.push(element.key);
					});

					list.push(itemList);
				});

				return list;
			},

			saveStep1() {
				this.$validator.validateAll().then((result) => {
					if (result) {
						this.step1 = false;
						this.step2 = true;
					}
				});
			},

			saveStep2() {
				this.$validator.validateAll().then((result) => {
					if (result) {
						this.step2 = false;
						this.step3 = true;
					}
				});
			},

			saveStep3() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.userCredential.token,
					},
				};

				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line
						let url =
							self.typeSend == "put"
								? process.env.VUE_APP_ROOT_API + "/projects/" + self.project.id
								: process.env.VUE_APP_ROOT_API + "/projects/";

						//let data = self.project
						//data.products = self.gradesTypes

						var data = {
							name: self.project.name,
							descripition: self.project.descripition,
							deadline: self.project.deadline,
							schools: self.project.schools,
							books: self.project.books,
							book_price: self.project.book_price.replace("R$ ", ""),
							shipping_price: self.project.shipping_price,
							class_size: self.project.class_size,
							school_type_id: self.project.school_type_id,
							year: self.project.year,
							payment_method_id: self.project.payment_method_id,
							teacher_book: self.project.teacher_book,
							user_id: self.project.user_id,
							allow_grade_product_change: true,
							products: self.getProducts(),
							paymentDetails: self.paymentDetails,
						};

						// if(self.typeSend == 'put'){

						//   data = {
						//     name: this.people.name,
						//     email: this.people.email,
						//     // username:this.people.username,
						//     birthdate: this.formatDate(this.people.birthdate),
						//     phone: this.people.phone,
						//     gender: this.people.gender,
						//   }
						//   //delete data.address.created_at;
						// }

						axios({
							method: self.typeSend.toUpperCase(),
							url: url,
							headers: config.headers,
							data: data,
						})
							.then(function (response) {
								if (response.data.success !== false) {
									//self.$router.push({ name: 'schools' })

									if (self.typeSend == "put") {
										self.editProject(response.data.data.project);
										self.close();
									} else {
										self.addProject(response.data.data.project);
										self.close();
									}

									//self.percentCall = 0
								} else {
									//self.errors.push(response.data.errors[0]);
								}
							})
							.catch(function (error) {
								//console.log(error)

								let fieldError = {
									field: "error",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},

			formatDate(hour) {
				var hourFormated = hour.split("/");
				return hourFormated[2] + "-" + hourFormated[1] + "-" + hourFormated[0];
				//return "YYYY-MM-DD";
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},

			jsonCopy(src) {
				return JSON.parse(JSON.stringify(src));
			},
		},
	};
</script>
<style lang="scss" scoped>
	.grade-product-line {
		border: 1px solid #afa2ba;
	}
	.field-add {
		border: 1px solid #afa2ba;
	}

	.hide {
		display: none;
	}
	.upload-box {
		border: 1px dashed #ccc;
		text-align: center;
		border-width: 2px;
		border-radius: 10px;
		padding-bottom: 40px;
	}

	.tab-item {
		border: 1px solid #ccc;
	}

	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.fiscal-notes ::v-deep .form-group:last-child {
		margin-bottom: 10px !important;
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// Default of page

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 30px 30px 10px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
</style>
