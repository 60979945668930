export const VuesticTheme = {
  Primary: 'Primary',
  Danger: 'Danger',
  Info: 'Info',
  Success: 'Success',
  Warning: 'Warning',
  White: 'White',
  Black: 'Black',
}

export const colorConfig = {
  [VuesticTheme.Primary]: '#4ae387',
  [VuesticTheme.Danger]: 'rgb(255, 69, 71)',
  [VuesticTheme.Info]: 'rgb(187, 121, 244)',
  [VuesticTheme.Success]: 'rgb(0, 207, 113)',
  [VuesticTheme.Warning]: 'rgb(255, 208, 0)',
  [VuesticTheme.White]: '#fff',
  [VuesticTheme.Black]: '#000',
}

export const colorArray = Object.values(colorConfig)
