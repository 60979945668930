const toggleWithoutAnimation = state => state.app.sidebar.withoutAnimation
const config = state => state.app.config
const palette = state => state.app.config.palette
const isLoading = state => state.app.isLoading

const userCredential = state => state.app.userCredential

const managerCredential = state => state.app.managerCredential

const consultantCredential = state => state.app.consultantCredential
const principalCredential = state => state.app.principalCredential

const teacherCredential = state => state.app.teacherCredential
const studentCredential = state => state.app.studentCredential
const parentCredential = state => state.app.parentCredential

export {
  toggleWithoutAnimation,
  config,
  palette,
  isLoading,
  
  userCredential,
  
  managerCredential,

  consultantCredential,
  principalCredential,

  teacherCredential,
  studentCredential,

  parentCredential
}
