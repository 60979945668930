<template>
	<div class="select-item">
		<div class="select-group">
			<!-- <Icon
        :class="{ 'has-error': hasError }"
        class="select-icon"
        name="triangle-down"
        size="small"
      /> -->
			<select
				:disabled="disabled"
				:name="getName"
				:class="[{ 'has-error': hasError }, { 'has-value': value }]"
				v-bind="$attrs"
				v-on="$listeners"
				class="select bordered"
				multiple
			>
				<option
					v-for="item in options"
					:key="item.key"
					:value="item.key ? item.key : null"
					:selected="item.key === value"
				>
					{{ item.value }}
				</option>
			</select>

			<!-- <label :for="getName" class="select-label">{{ label }}</label> -->
			<transition name="fade-up">
				<div class="select-helper">
					<span v-if="hasError" class="select-helper-error">
						{{ error }}
					</span>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
	/**
 * Element description
 *

#####Breakpoints
<span class="flag flag-default" markdown="1">lg</span>

 */
	export default {
		name: "select-multiple",
		props: {
			disabled: {
				type: Boolean,
				required: false,
				default: false,
			},
			items: {
				type: Array,
				required: true,
			},
			/**
			 * Chave do elemento recebido em items que esta selecionado
			 */
			value: {
				type: [String, Number, Boolean, Event],
				required: false,
				default: null,
			},
			type: {
				type: String,
				required: false,
				default: "text",
			},
			label: {
				type: String,
				required: true,
			},
			helper: {
				type: String,
				required: false,
				default: null,
			},
			error: {
				type: String,
				required: false,
				default: null,
			},
		},
		computed: {
			options() {
				if (this.label && this.label === "") return this.items;

				return [{ key: null, value: this.label }, ...this.items];
			},
			getName() {
				if (this.$attrs && this.$attrs.name) return this.$attrs.name;
				return this.label
					? this.label.replace(new RegExp(" ", "g"), "").toLowerCase()
					: "";
			},
			showHelper() {
				return this.hasError || (this.helper && this.helper.length > 0);
			},
			hasError() {
				return this.error && this.error.length > 0;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.select-item {
		width: 100%;
	}
	.select-group {
		margin-bottom: 10px;

		&:after {
			content: "\F0DD";
			width: 20px;
			height: 20px;
			position: absolute;
			right: 10px;
			top: 40%;
			color: #000;
			z-index: 0;
			font: normal normal normal 14px/1 FontAwesome;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			z-index: 0;
		}
	}
	.select {
		max-width: 100%;
		box-sizing: border-box;
		-ms-touch-action: manipulation;
		-webkit-appearance: none;
		touch-action: manipulation;
		margin-bottom: 0;
		transition: all 150ms ease;
		padding: 4px 4px;
		display: block;
		width: 100%;
		background-color: transparent;
		height: 65px;
		border-radius: 5px;
		border: solid 1px #dfdae4;
		font-family: Roboto;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;
		padding-left: 20px;
		z-index: 2;
		position: relative;

		&-group {
			position: relative;
		}

		&-label {
			font-family: $font_text;
			transform-origin: top left;
			position: absolute;
			left: 0;
			cursor: text;
			color: $color_black;
			opacity: 0;
			transition-property: padding font-size transform opacity;
			transition-duration: 300ms;
			transition-timing-function: ease;
			pointer-events: none;
		}

		&-helper {
			// position: absolute;
			// bottom: 0;
			// left: 0;
			// font-family: $font_text;
			// z-index: -1px;

			&-error {
				font-family: $font_text;
				color: $color-tomato;
				font-size: 14px;
			}
		}

		&-icon {
			position: absolute;
			right: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			&.has-error {
				fill: $color-tomato;
			}
		}

		&,
		&-label,
		&-helper {
			// padding-left: get-rem-size(map-get($grid-default-base-fonts, "lg"), 20px);
		}

		&.has-value {
			// padding-top: get-rem-size(map-get($grid-default-base-fonts, "lg"), 30px);
		}

		&.has-value + &-label {
			// transform: translateY(get-rem-size(map-get($grid-default-base-fonts, "lg"), 16px) * -1);
			opacity: 1;
			cursor: text;
			// font-size: get-rem-size(map-get($grid-default-base-fonts, "lg"), 12px);
			// padding-top: get-rem-size(map-get($grid-default-base-fonts, "lg"), 12px);
			color: #777;
		}

		&:hover {
			outline: none;
			border-color: $color-grey-dark;
		}

		&:focus {
			outline: none;
			box-shadow: 0 0 0 1px $color-blue-green;
			border-color: $color-blue-green;

			&:not([value]) + .input-text-label {
				color: $color-blue-green;
			}
		}

		&.has-error {
			border-color: $color-tomato;

			+ .select-label {
				color: $color-tomato;
			}

			&:focus {
				box-shadow: 0 0 0 1px $color-tomato;

				+ .select-label {
					color: $color-tomato;
				}
			}
		}

		&[disabled] {
			background-color: $color_grey_lightest;
			border-color: $color_grey_light;
			color: $color_grey;

			+ .select-label {
				color: $color_grey;
			}
		}
	}

	.fade-up-enter-active {
		transition: all 75ms $standard-easing;
	}

	.fade-up-leave-active {
		transition: all 150ms $standard-easing;
	}

	.fade-up-enter, .fade-up-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(-25%);
	}

	.form-group {
		@media (max-width: 990px) {
			padding: 17px 20px;
		}
	}
</style>
