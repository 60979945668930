<template>
	<vuestic-sidebar :hidden="isOpen">
		<template slot="menu">
			<sidebar-link :to="{ name: 'summary' }">
				<span slot="title">
					<span class="ico-menu">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="36"
							height="36"
							viewBox="0 0 36 36"
						>
							<g fill="none" fill-rule="evenodd">
								<path
									fill="#000"
									d="M18.677 7.238a1.07 1.07 0 0 0-1.348 0l-8.915 7.145c-.262.21-.414.528-.414.864v11.64c0 .607.487 1.1 1.086 1.1l5.698.013a1.086 1.086 0 0 0 1.089-1.099v-5.817c0-.608.486-1.102 1.085-1.102h2.084c.602 0 1.088.494 1.088 1.102v5.814c0 .611.49 1.105 1.089 1.102l5.699-.015A1.094 1.094 0 0 0 28 26.883V15.247c0-.336-.152-.657-.414-.864l-8.91-7.145z"
								/>
							</g>
						</svg>
					</span>
					<span>Resumo</span>
				</span>
			</sidebar-link>

			<sidebar-link :to="{ name: 'my-data' }">
				<span slot="title">
					<span class="ico-menu">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="36"
							height="36"
							viewBox="0 0 36 36"
						>
							<g fill="none" fill-rule="evenodd">
								<!-- <path fill="#FFF" d="M-411-206h1440v753H-411z"/> -->
								<path
									fill="#000"
									d="M18 7c3.314 0 6 2.91 6 6.5S21.314 20 18 20s-6-2.91-6-6.5S14.686 7 18 7zm9.571 19.883c-2.728 4.507-16.414 4.507-19.143 0-1.574-2.602 1.445-5.551 4.919-7.146 1.319 1.067 2.926 1.698 4.66 1.698 1.732 0 3.337-.629 4.655-1.694 3.47 1.595 6.483 4.543 4.91 7.142z"
								/>
							</g>
						</svg>
					</span>
					<span>Minha Conta</span>
				</span>
			</sidebar-link>

			<sidebar-link :to="{ name: 'my-shopping' }">
				<span slot="title">
					<span class="ico-menu">
						<!-- <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                <g fill="none" fill-rule="evenodd">
                    <path fill="#000" d="M18 7c3.314 0 6 2.91 6 6.5S21.314 20 18 20s-6-2.91-6-6.5S14.686 7 18 7zm9.571 19.883c-2.728 4.507-16.414 4.507-19.143 0-1.574-2.602 1.445-5.551 4.919-7.146 1.319 1.067 2.926 1.698 4.66 1.698 1.732 0 3.337-.629 4.655-1.694 3.47 1.595 6.483 4.543 4.91 7.142z"/>
                </g>
            </svg> -->
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="36"
							height="36"
							viewBox="0 0 36 36"
						>
							<g fill="none" fill-rule="evenodd">
								<!-- <path fill="#FFF" d="M-366-206h1440v753H-366z"/> -->
								<path
									fill="#000"
									d="M11.386 7h13.3C25.917 7 27 8.088 27 9.394v15.962H12.325c-.94 0-.94 2.322 0 2.322H27C27 28.912 25.916 30 24.687 30H11.386C10.084 30 9 28.912 9 27.606V9.394C9 8.088 10.084 7 11.386 7"
								/>
							</g>
						</svg>
					</span>
					<span>Minhas Compras</span>
				</span>
			</sidebar-link>

			<div class="club-links">
				<a href="/clube" alt=""><span>CLUBE DA DENTRO</span></a>

				<div v-if="subscription">
					<sidebar-link :to="{ name: 'next-kits' }"
						><span slot="title">Próximos Kits</span></sidebar-link
					>
					<sidebar-link :to="{ name: 'delivered-kits' }"
						><span slot="title">Kits já enviados</span></sidebar-link
					>
					<sidebar-link :to="{ name: 'manage-subscribe' }"
						><span slot="title">Gerenciar assinatura</span></sidebar-link
					>
				</div>

				<div v-if="!subscription">
					<sidebar-link :to="{ name: 'next-kits' }" class="btn btn-primary"
						><span slot="title">SAIBA MAIS SOBRE O CLUBE</span></sidebar-link
					>
				</div>
			</div>

			<li class="sidebar-link">
				<a href="/" target="_blank" class="sidebar-link__router-link">
					<span slot="title">
						<span class="ico-menu">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="36"
								height="36"
								viewBox="0 0 36 36"
							>
								<g fill="none" fill-rule="evenodd">
									<!-- <path fill="#FFF" d="M-457-206H983v753H-457z"/> -->
									<path
										fill="#000"
										d="M13.718 13c-.842-.23-.963-.599-1.42-.943-.36-.27-.266-.889-.335-1.343-.07-.453.042-.631.303-1.002.541-.765.944-.817 1.686-.26C14.979 10.223 15.767 12.04 16 13h-2.282zm6.282.229c.26-1.069 1.237-3.655 2.622-4.089.932-.292 1.361-.061 1.601.273.295.41.393.983.316 1.488-.077.506-.444.843-.843 1.143-.51.383-.813.854-1.75 1.111L20 13.23zm5.428-.655a3.544 3.544 0 0 0-.058-4.108 3.547 3.547 0 0 0-2.33-1.422 3.55 3.55 0 0 0-2.654.64c-.747.541-1.746 1.739-2.387 3.01-.6-1.188-1.558-2.41-2.384-3.01a3.548 3.548 0 0 0-2.655-.64 3.546 3.546 0 0 0-2.33 1.422 3.544 3.544 0 0 0-.058 4.108H9.237A2.237 2.237 0 0 0 7 14.806c0 1.13.848 2.065 1.942 2.21v9.275A2.716 2.716 0 0 0 11.658 29h12.684a2.715 2.715 0 0 0 2.716-2.71v-9.274A2.235 2.235 0 0 0 29 14.806a2.236 2.236 0 0 0-2.237-2.232h-1.335z"
									/>
								</g>
							</svg>
						</span>
						<span>Livros Personalizados</span>
					</span>
				</a>
			</li>

			<li class="sidebar-link">
				<a
					href="https://dentrodahistoria.zendesk.com/hc"
					target="_blank"
					class="sidebar-link__router-link"
				>
					<span slot="title">
						<span class="ico-menu">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="36"
								height="36"
								viewBox="0 0 36 36"
							>
								<g fill="none" fill-rule="evenodd">
									<!-- <path fill="#FFF" d="M-502-206H938v753H-502z"/> -->
									<path
										fill="#000"
										d="M21.703 16.799c-.458.38-.917.729-1.408 1.046-.72.475-1.146 1.204-1.146 2.155h-2.946c-.131-1.014-.066-2.155.884-2.884.556-.412 1.145-.76 1.604-1.204.426-.444.85-.983 1.047-1.522.23-.697-.327-1.521-.949-1.743-.917-.317-2.03-.063-2.423.666-.229.412-.36.919-.556 1.426h-2.75c-.295-1.426.524-3.17 1.899-4.025 1.898-1.141 5.14-.888 6.809.57 1.67 1.426 1.637 4.089-.065 5.515M17.965 25C16.842 25 16 24.15 16 23.054 16 21.85 16.807 21 17.965 21c1.158-.035 2.035.85 2.035 2.017C20 24.15 19.158 25 17.965 25M16.738 6.09c-5.4.647-9.543 4.525-10.525 9.634-1.228 6.495 2.977 12.835 9.39 14.159.338.062 3.805.4 6.414-.462 4.418-1.723 7.119-4.894 7.917-9.634.03-.092.43-3.97-1.166-7.11-2.118-4.34-6.782-7.233-12.03-6.587"
									/>
								</g>
							</svg>
						</span>
						<span>Central de ajuda</span>
					</span>
				</a>
			</li>

			<!-- <sidebar-link :to="{ name: 'my-shopping' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon fa fa-shopping-bag"></span>
          <span>{{ $t('menu.myShopping') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{ name: 'personal-data' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon vuestic-icon vuestic-icon-user"></span>
          <span>{{ $t('menu.personalData') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{ name: 'cards' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon fa fa-credit-card-alt"></span>
          <span>{{ $t('menu.cards') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{ name: 'address' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon fa fa-map-marker"></span>
          <span>{{ $t('menu.address') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{ name: 'subscription' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon fa fa-book"></span>
          <span>{{ $t('menu.subscription') }}</span>
        </span>
      </sidebar-link>

      <sidebar-link :to="{ name: 'reffer-friends' }">
        <span slot="title">
          <span class="sidebar-menu-item-icon fa fa-envelope"></span>
          <span>{{ $t('menu.referFriends') }}</span>
        </span>
      </sidebar-link> -->

			<!--
      <sidebar-link
        :to="{ name: 'dashboard' }">
        <span slot="title">
          <span
            class="sidebar-menu-item-icon vuestic-icon vuestic-icon-dashboard"></span>
          <span>{{ $t('menu.myAccount') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link-group>
        <span slot="title">
          <span
            class="sidebar-menu-item-icon vuestic-icon vuestic-icon-statistics"></span>
          <span>{{ $t('menu.statistics') }}</span>
        </span>
        <sidebar-link
          :to="{ name: 'charts' }">
          <span slot="title">
            <span>{{ $t('menu.charts') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'progress-bars' }">
          <span slot="title">
            <span>{{ $t('menu.progressBars') }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>
      <sidebar-link-group>
        <span slot="title">
          <span
            class="sidebar-menu-item-icon vuestic-icon vuestic-icon-forms"></span>
          <span>{{ $t('menu.forms') }}</span>
        </span>
        <sidebar-link
          :to="{ name: 'form-elements' }">
          <span slot="title">
            <span>{{ $t('menu.formElements') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'form-wizards' }">
          <span slot="title">
            <span>{{ $t('menu.formWizards') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'medium-editor' }">
          <span slot="title">
            <span>{{ $t('menu.mediumEditor') }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>
      <sidebar-link
        :to="{ name: 'tables' }">
        <span slot="title">
          <span
            class="sidebar-menu-item-icon vuestic-icon vuestic-icon-tables"></span>
          <span>{{ $t('menu.tables') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link-group>
        <span slot="title">
          <span
            class="sidebar-menu-item-icon vuestic-icon vuestic-icon-ui-elements"></span>
          <span>{{ $t('menu.uiElements') }}</span>
        </span>
        <sidebar-link
          :to="{ name: 'typography' }">
          <span slot="title">
            <span>{{ $t('menu.typography') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'buttons' }">
          <span slot="title">
            <span>{{ $t('menu.buttons') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'collapse' }">
          <span slot="title">
            <span>{{ $t('menu.collapse') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'color-pickers' }">
          <span slot="title">
            <span>{{ $t('menu.colorPickers') }}</span>
          </span>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'filters' }">
          <span slot="title">
            <span>{{ $t('menu.filters') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'timelines' }">
          <span slot="title">
            <span>{{ $t('menu.timelines') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'notifications' }">
          <span slot="title">
            <span>{{ $t('menu.notifications') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'icon-sets' }">
          <span slot="title">
            <span>{{ $t('menu.icons') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'spinners' }">
          <span slot="title">
            <span>{{ $t('menu.spinners') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'grid' }">
          <span slot="title">
            <span>{{ $t('menu.grid') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'modals' }">
          <span slot="title">
            <span>{{ $t('menu.modals') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'file-upload' }">
          <span slot="title">
            <span>{{ $t('menu.fileUpload') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'tags' }">
          <span slot="title">
            <span>{{ $t('menu.tags') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'tree-view' }">
          <span slot="title">
            <span>{{ $t('menu.treeView') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'cards' }">
          <span slot="title">
            <span>{{ $t('menu.cards') }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>
      <sidebar-link
        :to="{ name: 'extra' }">
        <span slot="title">
          <span
            class="sidebar-menu-item-icon vuestic-icon vuestic-icon-extras"></span>
          <span>{{ $t('menu.extra') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link-group>
        <span slot="title">
          <span
            class="sidebar-menu-item-icon vuestic-icon vuestic-icon-auth"></span>
          <span>{{ $t('menu.auth') }}</span>
        </span>
        <sidebar-link
          :to="{ name: 'login' }"
          target="_blank"
          :isChildLink="true">
          <span slot="title">
            <span>{{ $t('menu.login') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'signup' }"
          target="_blank">
          <span slot="title">
            <span>{{ $t('menu.signUp') }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>
      <sidebar-link-group>
        <span slot="title">
          <span
            class="sidebar-menu-item-icon vuestic-icon vuestic-icon-maps"></span>
          <span>{{ $t('menu.maps') }}</span>
        </span>
        <sidebar-link
          :to="{ name: 'google-maps' }">
          <span slot="title">
            <span>{{ 'Google Maps' }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'yandex-maps' }">
          <span slot="title">
            <span>{{ 'Yandex Maps' }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'leaflet-maps' }">
          <span slot="title">
            <span>{{ 'Leaflet Maps' }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'bubble-maps' }">
          <span slot="title">
            <span>{{ $t('Bubble Maps') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'line-maps' }">
          <span slot="title">
            <span>{{ $t('Line Maps') }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>
      <sidebar-link-group>
        <span slot="title">
          <span
            class="sidebar-menu-item-icon vuestic-icon vuestic-icon-files"></span>
          <span>{{ $t('menu.pages') }}</span>
        </span>
        <sidebar-link
          :to="{ name: '404-pages' }">
          <span slot="title">
            <span>{{ $t('404 Pages') }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>

      -->
		</template>
	</vuestic-sidebar>
</template>

<script>
	import VuesticSidebar from "../../../vuestic-theme/vuestic-components/vuestic-sidebar/VuesticSidebar";
	import SidebarLink from "./components/SidebarLink";
	import SidebarLinkGroup from "./components/SidebarLinkGroup";

	export default {
		name: "app-sidebar",
		components: {
			VuesticSidebar,
			SidebarLink,
			SidebarLinkGroup,
		},
		// watch: {
		//   $router (to, from) {
		//     this.isOpen = false
		//   },
		//   $route (to, from) {
		//     this.$nextTick(() => {
		//       this.isOpen = false
		//     })
		//   },
		// },
		props: {
			isOpen: {
				type: Boolean,
				required: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.ico-menu {
		opacity: 0.65;

		svg {
			path {
				fill: white;
			}
		}
	}

	.club-links {
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.64;
		letter-spacing: 2px;
		color: #ffffff;

		span {
			position: relative;
			height: 45px;
			padding-left: 25px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			cursor: pointer;
			text-decoration: none;
			color: #ffffff;
		}

		::v-deep a {
			position: relative;
			height: 32px;
			padding-left: 25px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			cursor: pointer;
			text-decoration: none;
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;

			padding-left: 0px !important;
			font-weight: normal !important;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #ffffff;
			height: 42px;
			opacity: 0.65;
		}
	}
</style>
