<template>
	<vuestic-layout v-layout>
		<div class="ddh-sidebar" :class="opened ? 'show-sidebar' : 'hide-sidebar'">
			<div class="sidebar-fixed">
				<div>
					<div class="menu-control" v-if="isMobile">
						<i class="" v-if="!opened" @click="toggleSidebar(true)">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="36"
								height="36"
								viewBox="0 0 36 36"
							>
								<g fill="#fff" fill-rule="evenodd" transform="translate(7 9)">
									<rect width="22" height="3" rx="1.5" />
									<rect width="22" height="3" y="8" rx="1.5" />
									<path
										d="M0 17.5c0-.828.67-1.5 1.495-1.5h19.01c.826 0 1.495.666 1.495 1.5 0 .828-.67 1.5-1.495 1.5H1.495A1.494 1.494 0 0 1 0 17.5z"
									/>
								</g>
							</svg>
						</i>
						<i class="" v-if="opened" @click="toggleSidebar(false)">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="37"
								height="36"
								viewBox="0 0 37 36"
							>
								<g
									fill="#fff"
									fill-rule="evenodd"
									transform="translate(9.867 9)"
								>
									<rect
										width="22"
										height="3"
										x="-2"
										y="8"
										rx="1.5"
										transform="rotate(45 9 9.5)"
									/>
									<rect
										width="22"
										height="3"
										x="-2"
										y="8"
										rx="1.5"
										transform="scale(-1 1) rotate(45 0 -12.228)"
									/>
								</g>
							</svg>
						</i>
					</div>
					<span class="logo">
						<img
							width="127"
							:src="require('@/assets/logo-ddh-white@2x.png')"
							alt="Dentro Educa"
							class
						/>
					</span>
				</div>

				<div class="ddh-sidebar-items">
					<ul>
						<li>
							<div class="person-menu" v-if="isMobile">
								<router-link :to="{ name: 'summary' }">
									<span class="ico-menu">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink"
											width="36"
											height="36"
											viewBox="0 0 36 36"
										>
											<defs>
												<path
													id="a"
													d="M9.868 26.825A11.968 11.968 0 0 1 6 18c0-6.627 5.373-12 12-12s12 5.373 12 12c0 3.49-1.49 6.632-3.868 8.825a8.73 8.73 0 0 0-16.264 0zM18 11.455a4.364 4.364 0 1 0 0 8.727 4.364 4.364 0 0 0 0-8.727z"
												/>
											</defs>
											<g fill="none" fill-rule="evenodd">
												<!-- <path fill="#FFF" d="M-568-206H872v753H-568z"/> -->
												<g>
													<circle
														cx="18"
														cy="18"
														r="12"
														stroke="#fff"
														stroke-width="2"
													/>
													<use fill="#fff" xlink:href="#a" />
												</g>
											</g>
										</svg>
									</span>
									<span>{{ consultantCredential.user.name }}</span>
								</router-link>
							</div>
						</li>

						<li>
							<router-link :to="{ name: 'dashboard-consultant' }">
								<span class="ico-menu">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="icon d-block mr-10"
										width="32"
										height="32"
										viewBox="0 0 32 32"
									>
										<title>Ícone de Usuário</title>
										<g
											fill="none"
											fill-rule="evenodd"
											transform="translate(2 2)"
										>
											<circle
												cx="14"
												cy="14"
												r="14.5"
												stroke="#01DFA1"
												fill="#01DFA1"
											></circle>
											<path
												fill="#FFF"
												d="M4.06 23.859A13.957 13.957 0 0 0 14 28c3.887 0 7.403-1.584 9.94-4.141a8.003 8.003 0 0 0-7.758-6.04h-4.364a8.003 8.003 0 0 0-7.758 6.04zm9.85-17.495h.18a5 5 0 0 1 5 5v.181a5 5 0 0 1-5 5h-.18a5 5 0 0 1-5-5v-.181a5 5 0 0 1 5-5z"
											></path>
										</g>
									</svg>
								</span>
								<span>Dashboard</span>
							</router-link>
						</li>

						<li>
							<router-link :to="{ name: 'adoption-consultant' }">
								<span class="ico-menu">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="36"
										height="36"
										viewBox="0 0 36 36"
									>
										<g fill="none" fill-rule="evenodd">
											<path
												fill="#000"
												d="M13.718 13c-.842-.23-.963-.599-1.42-.943-.36-.27-.266-.889-.335-1.343-.07-.453.042-.631.303-1.002.541-.765.944-.817 1.686-.26C14.979 10.223 15.767 12.04 16 13h-2.282zm6.282.229c.26-1.069 1.237-3.655 2.622-4.089.932-.292 1.361-.061 1.601.273.295.41.393.983.316 1.488-.077.506-.444.843-.843 1.143-.51.383-.813.854-1.75 1.111L20 13.23zm5.428-.655a3.544 3.544 0 0 0-.058-4.108 3.547 3.547 0 0 0-2.33-1.422 3.55 3.55 0 0 0-2.654.64c-.747.541-1.746 1.739-2.387 3.01-.6-1.188-1.558-2.41-2.384-3.01a3.548 3.548 0 0 0-2.655-.64 3.546 3.546 0 0 0-2.33 1.422 3.544 3.544 0 0 0-.058 4.108H9.237A2.237 2.237 0 0 0 7 14.806c0 1.13.848 2.065 1.942 2.21v9.275A2.716 2.716 0 0 0 11.658 29h12.684a2.715 2.715 0 0 0 2.716-2.71v-9.274A2.235 2.235 0 0 0 29 14.806a2.236 2.236 0 0 0-2.237-2.232h-1.335z"
											></path>
										</g>
									</svg>
								</span>
								<span>Minhas Adoções</span>
							</router-link>
						</li>
					</ul>

					<div class="encerrar">
						<img
							:src="consultantCredential.user.client_logo"
							width="120"
							alt="Logo"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="ddh-header">
			<div class="info-person">
				<div class="ico-person">
					<div class="person-name">
						<div class="ico">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="36"
								height="36"
								viewBox="0 0 36 36"
							>
								<defs>
									<path
										id="a"
										d="M9.868 26.825A11.968 11.968 0 0 1 6 18c0-6.627 5.373-12 12-12s12 5.373 12 12c0 3.49-1.49 6.632-3.868 8.825a8.73 8.73 0 0 0-16.264 0zM18 11.455a4.364 4.364 0 1 0 0 8.727 4.364 4.364 0 0 0 0-8.727z"
									/>
								</defs>
								<g fill="none" fill-rule="evenodd">
									<!-- <path fill="#FFF" d="M-568-206H872v753H-568z"/> -->
									<g>
										<circle
											cx="18"
											cy="18"
											r="12"
											stroke="#fff"
											stroke-width="2"
										/>
										<use fill="#fff" xlink:href="#a" />
									</g>
								</g>
							</svg>
						</div>

						<span
							>{{ consultantCredential.user.name }}
							<i class="fa fa-chevron-down"></i
						></span>

						<div class="menu-dropdown">
							<router-link :to="{ name: 'main' }" class="logout">
								Encerrar sessão
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>

		<main
			slot="content"
			id="content"
			class="va-layout gutter--lg fluid no-padding"
			role="main"
		>
			<!-- <app-breadcrumbs/>
      <vuestic-pre-loader
        v-show="isLoading"
        class="pre-loader"
      /> -->
			<router-view />
		</main>
		<!-- <span slot="footer">
      ©2019 - Dentro Educa
    </span> -->
	</vuestic-layout>
</template>

<script>
	import VuesticLayout from "../../vuestic-theme/vuestic-components/vuestic-layout/VuesticLayout";
	import AppNavbar from "./app-navbar/AppNavbar";
	import AppSidebar from "./app-sidebar/AppSidebar";
	import AppBreadcrumbs from "./app-breadcrumbs/AppBreadcrumbs";
	import Layout from "../../vuestic-theme/vuestic-directives/Layout";
	import { mapGetters } from "vuex";
	const axios = require("axios");

	export default {
		name: "app-layout",
		components: {
			VuesticLayout,
			AppNavbar,
			AppSidebar,
			AppBreadcrumbs,
		},
		directives: {
			layout: Layout,
		},
		updated: function () {
			// this.addResize();
			window.addEventListener("resize", this.resizeMenu);
		},
		mounted() {},
		data() {
			return {
				opened: true,
				isMobile: false,

				name: "",
				lastName: "",

				isSubscriber: false,

				currentRoute: null,
				// errors: []
			};
		},
		watch: {
			$route(to, from) {
				this.resizeMenu();
			},
		},
		methods: {
			addResize() {},
			toggleSidebar(opened) {
				this.opened = opened;
			},
			detectSize() {
				var menuStatus = false;
				if (window.innerWidth < 990) {
					menuStatus = true;
				}
				return menuStatus;
			},
			resizeMenu() {
				// var menuStatus = false
				if (window.innerWidth < 990) {
					this.isMobile = true;
					this.opened = false;
				} else {
					this.isMobile = false;
					this.opened = true;
				}
				// return menuStatus
			},
		},
		computed: {
			...mapGetters(["isLoading"]),

			...mapGetters({
				consultantCredential: "consultantCredential",
				// ...
			}),
		},
	};
</script>

<style lang="scss">
	.encerrar {
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 80%;
		text-align: center;
		bottom: 13%;
	}
	.logout {
		&:hover {
			color: red !important;
		}
	}
	.no-border-bottom {
		border-bottom: none !important;
	}
	.clube-dentro {
		padding-bottom: 20px !important;
	}
	.btn-know-more {
		border-radius: 40px;
		border: 1px solid #f1edf5;
		background-color: #fff;
		opacity: 1 !important;
		color: #3bd2d6 !important;
		margin-left: 25px !important;
		font-weight: 900 !important;
		display: block !important;
		width: auto;
		padding: 13px 15px !important;
		float: left;
		margin-bottom: 10px;
	}
	.ddh-sidebar {
		float: left;
		max-width: 230px;
		width: 100%;
		height: calc(100vh);
		position: relative;
		overflow: hidden;

		.sidebar-fixed {
			position: fixed;
			width: 100%;
			background-color: #1ad3d9;
			max-width: 230px;
			height: 100%;

			@media (max-width: 990px) {
				width: 100%;
				max-width: 100%;
			}
		}

		&.hide-sidebar {
			height: 89px;
			overflow: hidden;

			@media (max-width: 990px) {
				height: 51px;
			}

			.sidebar-fixed {
				width: 100%;
				position: relative;
				max-width: 100%;
			}
		}

		@media (max-width: 990px) {
			max-width: 100%;
			width: 100%;
			overflow: overlay;
			z-index: 2;
			position: relative;
		}

		.logo {
			height: 89px;
			text-align: center;
			padding-top: 22px;
			border-bottom: 1px solid #0dbec4;
			margin-bottom: 10px;
			display: block;

			@media (max-width: 990px) {
				margin-bottom: 0px;
				padding-top: 10px;
				height: 51px !important;

				svg {
					width: 100%;
					max-width: 90px;
				}
			}
			svg {
				margin: 0 auto;
			}
		}

		.menu-control {
			position: absolute;
			left: 20px;
			top: 8px;
			color: white;
			font-size: 24px;
		}
	}

	.ddh-sidebar-items {
		position: relative;
		height: calc(100vh);

		&.ddh-sidebar-items ul {
			list-style: none;
			padding-left: 0px;
			border-bottom: 1px solid #0dbec4;
			padding-bottom: 10px;

			li {
				a {
					opacity: 0.7;
					position: relative;
					height: 50px;
					padding-left: 25px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					// cursor: pointer;
					text-decoration: none;
					color: white;
					font-weight: 400;

					&:hover {
						color: white;
						opacity: 1;
					}

					&.router-link-active {
						// background-color:#4965bc;
						opacity: 1;
						color: white;

						:before {
							content: "";
							position: absolute;
							width: 5px;
							background-color: #fff;
							left: 0;
							height: 100%;
							top: 0px;
						}
					}
				}

				.person-menu {
					opacity: 0.85;
					position: relative;
					height: 64px;
					padding-left: 25px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					cursor: default;
					text-decoration: none;
					// background-color: #4965bc;
					color: white;
					padding-left: 0px;

					a {
						//padding-left: 0px;

						// &.router-link-active{
						//   span{
						//     &:before{
						//       display: none;
						//     }
						//   }
						// }
					}
				}
				svg {
					opacity: 0.85;
					margin-left: -5px;
					margin-right: 10px;
					path {
						fill: #fff;
					}
				}
			}
		}

		.clube-dentro {
			span {
				opacity: 0.7;
				position: relative;
				height: 50px;
				padding-left: 25px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				// cursor: pointer;
				text-decoration: none;
				color: white;
				font-weight: 400;
				font-size: 14px;
				font-weight: bold;
			}
			li:first-child a {
				font-size: 14px;
			}

			li a {
				&.router-link-active {
					// background-color:#4965bc;
					opacity: 1;
					color: white;

					&:before {
						display: block;
						content: "";
						position: absolute;
						width: 5px;
						background-color: #fff;
						left: 0;
						height: 100%;
						top: 0px;
					}
				}
			}

			a {
				height: 40px !important;
			}
		}
	}

	.ddh-header {
		width: 100%;
		height: 89px;
		background-color: #4965bc;

		@media (max-width: 990px) {
			width: 100%;
			height: 126px;
			background-color: #4965bc;
			display: none;
		}
		.info-person {
			float: right;
			margin-right: 30px;
			margin-top: 20px;
			cursor: pointer;
			text-transform: uppercase;
			z-index: 9;
			position: relative;

			@media (max-width: 990px) {
				margin: 0 auto;
				margin-top: 12px;
				float: none;
				display: block;
				text-align: center;
			}

			&:hover {
				.ico {
					opacity: 0.65;
				}
				.person-name span {
					opacity: 0.65;
				}
				.person-name .menu-dropdown {
					display: block;
				}
			}

			// &:hover{
			//   .menu-dropdown{
			//     display: block;
			//   }
			// }
			.ico-person {
				// float: left;
				float: left;
				line-height: 44px;

				@media (max-width: 990px) {
					float: none;
					width: 100%;
					text-align: center;
				}
			}

			.person-name {
				margin-right: 15px;

				@media (max-width: 990px) {
					display: inline-block;
					position: relative;
				}
				.ico {
					float: left;
					margin-right: 10px;

					svg {
						path {
							fill: #ffffff;
						}
					}
				}

				.menu-dropdown {
					display: none;
					position: absolute;
					top: 50px;
					// border-top: 10px solid #907feb;
					border-radius: 10px;
					width: 260px;
					border-radius: 10px;
					-webkit-box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
					box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
					background-color: #ffffff;
					padding: 20px;
					padding-top: 0px;
					padding-bottom: 10px;
					right: 15px;

					@media (max-width: 990px) {
						right: 0;
						left: -41px;
						margin-right: auto;
						margin-left: auto;
						top: 50px;
						z-index: 2;
					}

					a {
						font-family: Nunito;
						font-size: 16px;
						font-weight: 600;
						font-style: normal;
						font-stretch: normal;
						letter-spacing: normal;
						color: #68596e;
						// border-bottom: 1px solid #dfdae4;
						width: 100%;
						display: block;
						height: 57px;
						position: relative;
						line-height: 60px;
						text-transform: initial;

						&:hover {
							color: #1ad3d9;
						}
						&:last-child {
							border-top: 1px solid #dfdae4;
							border-bottom: 0px;
							font-size: 14px;

							&:before {
								display: none;
							}
						}

						&:before {
							content: "\F054";
							color: #1ad3d9;
							position: absolute;
							top: 24px;
							width: 4px;
							height: 4px;
							font: normal normal normal 14px/1 FontAwesome;
							right: 9px;
							margin-left: auto;
							margin-right: auto;
						}
					}

					&:before {
						content: "\F0D8";
						color: #fff;
						position: absolute;
						top: -10px;
						width: 4px;
						height: 4px;
						font: normal normal normal 14px/1 FontAwesome;
						right: 39%;

						@media (max-width: 990px) {
							right: 0px;
							left: 0px;
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
			}

			span {
				float: left;
				height: 52px;
				font-family: Roboto;
				font-size: 14px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: 3.5;
				letter-spacing: 0.8px;
				text-align: right;
				color: #ffffff;

				&:before {
					content: "";
				}
			}

			.ico-cart {
				position: relative;
				width: 50px;
				height: 50px;
				border-radius: 100px;
				background-color: #ffffff;
				float: right;
				text-align: center;
				padding-top: 7px;

				svg {
					// position: relative;
					// left: -3px;
					// top: 7px;

					path {
						fill: #afa2ba;
					}
				}

				span {
					position: absolute;
					width: 20px;
					height: 30px;
					top: 8px;
					left: 2px;
					right: 0;
					font-size: 9px;
					text-align: center;
					margin-right: auto;
					margin-left: auto;
				}
			}
		}
	}
</style>
