<template>
	<div class="flex xs12 no-padding box-input-item">
		<div
			class="floating-label"
			:class="{ active: disabled, 'has-error': errors.has(getName) }"
		>
			<div class="input-group">
				<div class="input-text-group">
					<input
						:type="type"
						:name="getName"
						:disabled="disabled"
						:ref="getName"
						:focus="disabled"
						:data-vv-as="label"
						v-bind="$attrs"
						v-on="$listeners"
						v-validate="validate"
						class="floating-input simple"
						:placeholder="label"
					/>

					<!-- <label :for="getName">{{ label }}</label> -->
				</div>
			</div>
		</div>

		<!-- {{errors}} -->

		<transition name="fade-up">
			<div v-if="errors.has(getName)" class="input-text-helper">
				<span v-if="errors.has(getName)" class="input-text-helper-error">
					{{ errors.first(getName) }}
				</span>
				<span v-else class="input-text-helper-default">
					{{ helper }}
				</span>
			</div>
		</transition>
	</div>
</template>

<script>
	// const axios = require('axios');

	export default {
		name: "input-simple",
		props: {
			/**
			 * Indica se o botão esta desabilitado ou não
			 */
			disabled: {
				type: Boolean,
				required: false,
				default: false,
			},
			type: {
				type: String,
				required: false,
				default: "text",
			},
			label: {
				type: String,
				required: true,
			},
			helper: {
				type: String,
				required: false,
				default: null,
			},
			error: {
				type: String,
				required: false,
				default: null,
			},
			validate: {
				type: String,
				required: false,
				default: null,
			},
		},
		data() {
			return {
				inputVal: null,
			};
		},
		mounted() {
			this.inputVal = this.$attrs.value;
		},
		computed: {
			getName() {
				if (this.$attrs && this.$attrs.name) return this.$attrs.name;
				return this.label
					? this.label.replace(new RegExp(" ", "g"), "").toLowerCase()
					: "";
			},
			showHelper() {
				return this.hasError || (this.helper && this.helper.length > 0);
			},
			hasError() {
				return this.error && this.error.length > 0;
			},
		},
		methods: {},
	};
</script>

<style lang="scss">
	.box-input-item {
		.input-text-group {
			width: 100%;
		}

		/****  floating-Lable style start ****/
		.floating-label {
			position: relative;
			margin-bottom: 10px;
		}

		label {
			color: #b0a1bb;
			font-size: 16px;
			font-weight: normal !important;
			position: absolute;
			pointer-events: none;
			left: 22px;
			top: 21px;
			transition: 0.2s ease all;
			-moz-transition: 0.2s ease all;
			-webkit-transition: 0.2s ease all;
		}

		.floating-input,
		.floating-select {
			padding: 4px 4px;
			display: block;
			width: 100%;
			background-color: transparent;
			height: 65px;
			border-radius: 5px;
			border: solid 1px #dfdae4;
			font-family: Roboto;
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #000000;
			padding-left: 20px;
		}

		.floating-input:focus,
		.floating-select:focus {
			outline: none;
			//  border-bottom:2px solid #5264AE;
			border: solid 2px #4965bc;
			background-color: #ffffff;
		}

		.floating-input:not(:placeholder-shown) ~ label {
			top: 12px;
			font-family: Roboto;
			font-size: 12px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #4965bc;
		}

		.floating-input.simple:not(:placeholder-shown) {
			padding-top: 0px !important;
			padding-bottom: 0px;
		}

		.floating-select:focus ~ label,
		.floating-select:not([value=""]):valid ~ label {
			top: -18px;
			font-size: 14px;
			color: #5264ae;
		}

		/* active state */
		.floating-input:focus ~ .bar:before,
		.floating-input:focus ~ .bar:after,
		.floating-select:focus ~ .bar:before,
		.floating-select:focus ~ .bar:after {
			width: 50%;
		}

		*,
		*:before,
		*:after {
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}

		.floating-textarea {
			min-height: 30px;
			max-height: 260px;
			overflow: hidden;
			overflow-x: hidden;
		}

		/* highlighter */
		.highlight {
			position: absolute;
			height: 50%;
			width: 100%;
			top: 15%;
			left: 0;
			pointer-events: none;
			opacity: 0.5;
		}

		/* active state */
		.floating-input:focus ~ .highlight,
		.floating-select:focus ~ .highlight {
			-webkit-animation: inputHighlighter 0.3s ease;
			-moz-animation: inputHighlighter 0.3s ease;
			animation: inputHighlighter 0.3s ease;
		}

		.edit-fields {
			margin-bottom: 30px;
		}

		.editor {
			right: 10px;
			top: 44%;
		}
	}
</style>
