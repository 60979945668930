const dictionary = {
  pt_BR: {
    //   email: {
    //     required: (field) => `O campo ${field} é obrigatório.`
    //   },
    messages: {
      email: (field) => `O campo ${field} é obrigatório.`,
      cpf: () => 'CPF inválido',
      required: (field) => `O campo ${field} é obrigatório.`,
      // confirmed: () => `As senhas devem ser iguais`,
    }
  }
}
export default dictionary
