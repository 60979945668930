<template>
	<div class="line-item active">
		<div class="line"></div>
		<div class="item-line">
			<div class="va-row">
				<div class="flex xs12 no-padding white-border">
					<div class="header-purple">
						<div class="va-row">
							<div class="flex xs6 text-left">
								<h1>
									{{
										subscription.subscription.subscriptionStackDisplayOrder == 1
											? "Primeiro Kit"
											: subscription.subscription
													.subscriptionStackDisplayOrder + "º Kit"
									}}
								</h1>
							</div>
							<div class="flex xs6 md6 text-right">
								<div class="box-date">
									{{ subscription.subscription.dueDateEditable }}
								</div>
							</div>
						</div>
					</div>

					<div class="body-spacing">
						<div class="book-main-info">
							<div class="va-row">
								<div class="flex xs12 name-son no-padding">
									<span>Livro personalizado</span>
									<h1>
										{{ subscription.product.characterName }} -
										{{ subscription.product.name }}
									</h1>

									<router-link
										:to="{
											name: 'change-kits',
											params: {
												genderTagId: subscription.subscription.genderTagId,
												ageTagId: subscription.subscription.ageTagId,
												ProductId: subscription.product.id,
												SubscriptionStackId:
													subscription.subscription.subscriptionStackId,
												SubscriptionId: subscription.subscription.id,
											},
										}"
										class="btn-change"
									>
										TROCAR DE LIVRO
									</router-link>
								</div>
							</div>

							<hr />

							<div class="va-row">
								<div class="flex xs12 name-son no-padding">
									<span>Protagonista do Livro</span>
									<h1>
										{{ subscription.subscription.characterName.toUpperCase() }}
									</h1>

									<router-link
										:to="{
											name: 'change-name',
											params: {
												subscription: subscription,
											},
										}"
										class="btn-change"
									>
										EDITAR
									</router-link>
								</div>
							</div>
						</div>

						<hr />

						<!-- <h3 class="next-kit">Próximo Kit</h3> -->

						<div
							class="va-row character-covers"
							v-if="subscription.product.canEdit"
						>
							<div class="flex xs6 md2 text-center cover-photo">
								<!-- <img :src="require('@/assets/images/cover/'+ subscription.product.descriptionKey +'.jpg')" width="36" alt="" class=""> -->
								<img
									:src="
										'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
										subscription.product.builderCharacterId +
										'?page=1&w=300&v=0&random=' +
										randomMath
									"
								/>

								<router-link
									:to="{
										name: 'preview',
										params: {
											product: subscription.product,
										},
									}"
									class="btn-change"
								>
									VISUALIZAR LIVRO
								</router-link>

								<!-- <button class="btn btn-change" v-if="subscription.product.canEdit">VISUALIZAR LIVRO</button> -->
							</div>
							<div class="flex xs6 md2 cover-photo character text-center">
								<div class="bordered-asset">
									<img
										:src="
											'https://builder.dentrodahistoria.com.br/api/builder/getcharacter/' +
											subscription.product.builderCharacterId +
											'?page=1&w=181&v=0&random=' +
											randomMath
										"
										alt=""
									/>
								</div>

								<router-link
									:to="{
										name: 'change-avatar',
										params: {
											subscription: subscription,
										},
									}"
									class="btn-change"
								>
									EDITAR AVATAR
								</router-link>
							</div>

							<div class="flex xs12 md6 product-info">
								<span>
									<b>Resenha do livro:</b>
									<div v-html="subscription.product.description"></div>
									<br />
									<b>Nível de leitura:</b>
									{{ subscription.product.tagName }}
								</span>
							</div>
						</div>

						<div class="va-row" v-if="!subscription.product.canEdit">
							<div class="flex xs12 md2 text-center cover-photo">
								<img
									:src="
										require('@/assets/images/cover/' +
											subscription.product.descriptionKey +
											'.jpg')
									"
									alt=""
									class=""
								/>
								<!-- <button class="btn btn-change" v-if="subscription.product.canEdit">VISUALIZAR LIVRO</button> -->
							</div>
							<div class="flex xs12 md6 product-info">
								<p>
									<b>Resenha do livro:</b>
									<span v-html="subscription.product.description"></span>
									<br />
									<b>Nível de leitura:</b>
									{{ subscription.product.tagName }}
								</p>
							</div>
						</div>

						<div class="clearfix"></div>

						<hr />

						<div class="text-center" v-if="!ReadMore">
							<button
								class="btn btn-primary read-more-btn bottom"
								v-on:click="changeReadMoreState(true)"
							>
								LEIA MAIS <i class="fa fa-chevron-down"></i>
							</button>
						</div>

						<div v-if="ReadMore">
							<div class="va-row learn-paths">
								<div class="flex xs12 md8">
									<h1>
										O que {{ subscription.subscription.characterName }} pode
										aprender com esse livro?
									</h1>

									<p v-html="subscription.product.description2"></p>

									<div class="box-home">
										<div class="house-icon">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="36"
												height="36"
												viewBox="0 0 36 36"
											>
												<path
													fill="#000"
													fill-rule="evenodd"
													d="M18.677 7.238a1.07 1.07 0 0 0-1.348 0l-8.915 7.145c-.262.21-.414.528-.414.864v11.64c0 .607.487 1.1 1.086 1.1l5.698.013a1.086 1.086 0 0 0 1.089-1.099v-5.817c0-.608.486-1.102 1.085-1.102h2.084c.602 0 1.088.494 1.088 1.102v5.814c0 .611.49 1.105 1.089 1.102l5.699-.015A1.094 1.094 0 0 0 28 26.883V15.247c0-.336-.152-.657-.414-.864l-8.91-7.145z"
												></path>
											</svg>
										</div>

										<p>
											<b>Receba em casa:</b>
											<br />Junto com o livro você receberá o Guia de Leitura,
											que explica os campos de aprendizados incentivados por
											esse Kit.
										</p>

										<div class="clearfix"></div>
									</div>
								</div>

								<div class="flex xs12 md4">
									<div class="panel-kit">
										<div class="title">
											<h1>O que vem no kit</h1>
										</div>
										<ul>
											<li
												v-for="(subscription, id) in subscription.product
													.features"
												:key="id"
											>
												<span>{{ subscription.description }}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<hr />

							<div class="address-boxes">
								<h4>Endereço de entrega</h4>

								<div class="panel more-address">
									<div>
										<div class="va-row address-row panel-body">
											<div class="flex xs2 md1 text-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="36"
													height="36"
													viewBox="0 0 36 36"
												>
													<path
														fill="#000"
														fill-rule="nonzero"
														d="M18 5C12.477 5 8 9.477 8 15c0 8.25 10 16 10 16s10-8.25 10-16c0-5.523-4.477-10-10-10zm0 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
													></path>
												</svg>
											</div>
											<div class="flex xs10 no-padding-left">
												<b
													>{{ subscription.address.address1 }},
													{{ subscription.address.addressNumber }}</b
												>
												<br />
												{{ subscription.address.district }},
												{{ subscription.address.city }} -
												{{ subscription.address.stateId }}
												<br />
												CEP: {{ subscription.address.zipPostalCode }}
												<br />Complemento:
												<br />
												{{ subscription.address.addressReference }}
											</div>
											<!-- <div class="flex xs2">
                                  <div class="editor">
                                    <router-link
                                      :to="{ name: 'adresses' }"
                                      class="btn btn-micro-edit"
                                    >TROCAR ENDEREÇO</router-link>
                                  </div>
                                </div> -->
											<hr />
										</div>
									</div>
								</div>
							</div>

							<div class="text-center">
								<button
									class="btn btn-primary read-more-btn"
									v-on:click="changeReadMoreState(false)"
								>
									ESCONDER <i class="fa fa-chevron-up"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// const axios = require('axios');

	export default {
		name: "next-item",
		props: {
			subscription: {
				type: Object,
				required: true,
				default: null,
			},
			counter: {
				type: Number,
				required: true,
				default: null,
			},
		},
		data() {
			return {
				ReadMore: false,
				randomMath: Math.floor(Math.random() * 1000),
			};
		},
		mounted() {
			if (this.counter === 0) {
				this.ReadMore = true;
			}
		},
		computed: {},
		methods: {
			changeReadMoreState(state) {
				this.ReadMore = state;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.character-covers {
		@media (max-width: 990px) {
			margin-top: 15px !important;
		}

		.btn-change {
			height: 47px;
			font-family: Nunito;
			font-size: 12px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 5.5;
			letter-spacing: 0.7px;
			text-align: right;
			color: #4965bc;
			right: 0px;
			top: 10px;
		}
	}
	.read-more-btn {
		border-radius: 40px;
		border: solid 1px #dfdae4;
		background-image: none;
		background-color: #fff;
		color: #3bd3d6;
		margin-bottom: 20px;

		&.bottom {
			@media (max-width: 990px) {
				margin-top: 20px;
			}
		}
	}
	.status-box {
		margin-bottom: 20px;
		margin-bottom: 35px;

		.body-spacing {
			padding: 30px 30px 20px 30px !important;
		}
	}

	.header-purple {
		height: 87px;
		border-radius: 10px 10px 0px 0px;
		border: solid 1px #9378f0;
		background-color: #9378f0;
		padding: 20px 26px;

		@media (max-width: 990px) {
			padding: 5px 15px !important;
			height: 57px !important;
		}
		h1 {
			font-family: Roboto;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
			margin: 0px;
			line-height: 47px;
		}

		.box-date {
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 2.5;
			letter-spacing: normal;
			color: #ffffff;
			height: 40px;
			border-radius: 40px;
			background-color: #7659dc;
			text-align: center;
			display: inline-block;
			padding: 2px 29px;
			margin-top: 3px;

			@media (max-width: 480px) {
				display: none;
			}
		}
	}
	.learn-paths {
		@media (max-width: 990px) {
			.flex {
				padding: 0px !important;
			}
		}

		color: #000;

		h1 {
			margin-top: 0px;
			font-family: Nunito;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #000000;
			margin-bottom: 8px;

			@media (max-width: 990px) {
				font-size: 18px;
				line-height: 1.3;
				margin-top: 20px;
			}
		}

		.box-home {
			border-radius: 5px;
			border: dashed 1px #9378f0;
			background-color: #ffffff;
			padding: 10px 17px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #000000;
			max-width: 98%;

			@media (max-width: 990px) {
				padding: 10px 10px;
			}

			.house-icon {
				float: left;
				width: 50px;
				height: 50px;
				border-radius: 25px;
				background-color: #9378f0;
				text-align: center;
				padding-top: 6px;
				margin-right: 10px;

				@media (max-width: 990px) {
					margin-bottom: 20% !important;
				}

				svg {
					path {
						fill: #fff;
					}
				}
			}

			p {
				padding-top: 4px;
				margin-bottom: 0px;
			}
		}
	}

	.line-item {
		width: 100%;

		&.status-box {
			//margin-bottom: 21px;

			.va-row {
				margin-bottom: 0px !important;
			}
		}

		// .va-row{
		//   margin-bottom: 46px !important;
		// }

		&.active {
			.line {
				&:before {
					content: "";
					position: absolute;
					left: -10px;
					top: 38px;
					width: 20px;
					height: 20px;
					border-radius: 25px;
					background-color: #9378f0;
				}

				@media (max-width: 990px) {
					display: none;
				}
			}
		}

		.line {
			width: 2px;
			height: 100%;
			display: block;
			background-color: #9378f0;
			float: left;
			margin-right: 30px;
			position: relative;
		}

		.item-line {
			width: 100%;
		}
	}

	.panel-kit {
		border-radius: 10px;
		border: solid 1px #dfdae4;
		background-color: #ffffff;

		@media (max-width: 990px) {
			margin-top: 20px;
			margin-bottom: 10px;
		}

		.title {
			border-radius: 10px 10px 0px 0px;
			background-color: #f8f7fc;
			padding: 0px 20px;
			border-bottom: 1px solid #dfdae4;

			h1 {
				font-family: Roboto;
				font-size: 16px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				letter-spacing: normal;
				color: #000000;
				line-height: 50px;
				margin-bottom: 0px;

				@media (max-width: 990px) {
					margin-top: 0px;
				}
			}
		}

		ul {
			padding-left: 13px;
			margin-top: 12px;
			margin-bottom: 12px;

			@media (max-width: 990px) {
				padding-left: 12px;
				font-size: 14px;
			}
			@media (max-width: 480px) {
				padding-left: 12px;
				font-size: 14px;
			}
		}
	}

	.book-main-info {
		.btn-change {
			height: 47px;
			font-family: Nunito;
			font-size: 12px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 5.5;
			letter-spacing: 0.7px;
			text-align: right;
			color: #4965bc;
			position: absolute;
			right: 0px;
			top: 10px;

			@media (max-width: 767px) {
				position: relative;
				line-height: 3;
				z-index: 2;
			}
		}
	}

	.name-son {
		position: relative;
		@media (max-width: 990px) {
			padding-top: 10px !important;
		}
		span {
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #ada1b9;
			margin-top: 30px;
		}
		h1 {
			font-family: Nunito;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: 0px;
			color: #000000;
			margin-top: 10px;
			margin-bottom: 0px;

			@media (max-width: 990px) {
				font-size: 18px;
				line-height: 1.3;
				margin-top: 0px;
			}
		}
	}

	h3.next-kit {
		font-family: Nunito;
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.3;
		letter-spacing: 0px;
		color: #000000;
		margin-top: 0px;
		margin-bottom: 10px;
	}
	.next-kits {
		.btn.btn-change {
			font-family: Nunito;
			font-size: 12px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #4965bc;
			background: none;
			border: 0px;
			margin-top: 7px;
		}
	}
	.cover-photo {
		border-radius: 10px;
		overflow: hidden;

		@media (max-width: 990px) {
			// padding-right: 0px !important;
			padding-left: 0px !important;
		}

		.bordered-asset {
			border: 1px solid #ccc;
			width: 100%;
			// height: 100%;
			border-radius: 10px;
			text-align: center;

			img {
				border: 0px !important;
				margin: 0 auto;
				display: block;
				float: none !important;
			}
		}

		img {
			// max-width: 208px;
			float: left;
			width: 100%;

			// @media(max-width: 990px){
			//     max-width: 50%;
			//     display: block;
			//     margin: 0 auto;
			//     border-radius: 4px;
			//     float: none;

			// }
		}
		&.character {
			@media (max-width: 990px) {
				padding-right: 0px !important;
			}

			img {
				border-radius: 10px;
				border: 1px solid #ccc;
				max-width: 89%;
				float: left;

				@media (max-width: 990px) {
					max-width: 83%;
				}
			}
		}
	}

	.product-info {
		color: #000000;

		@media (max-width: 990px) {
			padding: 0px !important;
			padding-left: 15px !important;
		}
		h3 {
			font-family: Nunito;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
		}
		p {
			font-family: Roboto;
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.36;
			letter-spacing: normal;
			color: #000000;
			margin-bottom: 0px;
		}

		b {
			font-family: Roboto;
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.36;
			letter-spacing: normal;
			color: #000000;
			font-weight: bold;
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}

	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.white-border {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;
		margin-bottom: 20px;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 10px 20px 0px 20px;

			@media (max-width: 990px) {
				padding: 5px 15px 0px 15px;
			}
		}

		hr {
			border-top: solid 1px #dfdae4;
			margin-top: 20px;
			margin-bottom: 15px;

			@media (max-width: 990px) {
				margin-bottom: 0px;
				margin-top: 10px;
			}
		}

		.dual-inputs {
			padding: 0px 0px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 54%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.address-boxes {
		border-radius: 10px;
		background-color: #ffffff;
		position: relative;
		padding: 0px 8px;

		@media (max-width: 990px) {
			padding: 0px;
			padding-top: 20px;
		}

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;
		}

		hr {
			border-top: solid 2px #dfdae4;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 54%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #dfdae4;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			// border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}
</style>
