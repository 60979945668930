var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-item active"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"item-line"},[_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex xs12 no-padding white-border"},[_c('div',{staticClass:"header-purple"},[_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex xs6 text-left"},[_c('h1',[_vm._v(" "+_vm._s(_vm.subscription.subscription.subscriptionStackDisplayOrder == 1 ? "Primeiro Kit" : _vm.subscription.subscription .subscriptionStackDisplayOrder + "º Kit")+" ")])]),_c('div',{staticClass:"flex xs6 md6 text-right"},[_c('div',{staticClass:"box-date"},[_vm._v(" "+_vm._s(_vm.subscription.subscription.dueDateEditable)+" ")])])])]),_c('div',{staticClass:"body-spacing"},[_c('div',{staticClass:"book-main-info"},[_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex xs12 name-son no-padding"},[_c('span',[_vm._v("Livro personalizado")]),_c('h1',[_vm._v(" "+_vm._s(_vm.subscription.product.characterName)+" - "+_vm._s(_vm.subscription.product.name)+" ")]),_c('router-link',{staticClass:"btn-change",attrs:{"to":{
											name: 'change-kits',
											params: {
												genderTagId: _vm.subscription.subscription.genderTagId,
												ageTagId: _vm.subscription.subscription.ageTagId,
												ProductId: _vm.subscription.product.id,
												SubscriptionStackId:
													_vm.subscription.subscription.subscriptionStackId,
												SubscriptionId: _vm.subscription.subscription.id,
											},
										}}},[_vm._v(" TROCAR DE LIVRO ")])],1)]),_c('hr'),_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex xs12 name-son no-padding"},[_c('span',[_vm._v("Protagonista do Livro")]),_c('h1',[_vm._v(" "+_vm._s(_vm.subscription.subscription.characterName.toUpperCase())+" ")]),_c('router-link',{staticClass:"btn-change",attrs:{"to":{
											name: 'change-name',
											params: {
												subscription: _vm.subscription,
											},
										}}},[_vm._v(" EDITAR ")])],1)])]),_c('hr'),(_vm.subscription.product.canEdit)?_c('div',{staticClass:"va-row character-covers"},[_c('div',{staticClass:"flex xs6 md2 text-center cover-photo"},[_c('img',{attrs:{"src":'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
										_vm.subscription.product.builderCharacterId +
										'?page=1&w=300&v=0&random=' +
										_vm.randomMath}}),_c('router-link',{staticClass:"btn-change",attrs:{"to":{
										name: 'preview',
										params: {
											product: _vm.subscription.product,
										},
									}}},[_vm._v(" VISUALIZAR LIVRO ")])],1),_c('div',{staticClass:"flex xs6 md2 cover-photo character text-center"},[_c('div',{staticClass:"bordered-asset"},[_c('img',{attrs:{"src":'https://builder.dentrodahistoria.com.br/api/builder/getcharacter/' +
											_vm.subscription.product.builderCharacterId +
											'?page=1&w=181&v=0&random=' +
											_vm.randomMath,"alt":""}})]),_c('router-link',{staticClass:"btn-change",attrs:{"to":{
										name: 'change-avatar',
										params: {
											subscription: _vm.subscription,
										},
									}}},[_vm._v(" EDITAR AVATAR ")])],1),_c('div',{staticClass:"flex xs12 md6 product-info"},[_c('span',[_c('b',[_vm._v("Resenha do livro:")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.subscription.product.description)}}),_c('br'),_c('b',[_vm._v("Nível de leitura:")]),_vm._v(" "+_vm._s(_vm.subscription.product.tagName)+" ")])])]):_vm._e(),(!_vm.subscription.product.canEdit)?_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex xs12 md2 text-center cover-photo"},[_c('img',{attrs:{"src":require('@/assets/images/cover/' +
											_vm.subscription.product.descriptionKey +
											'.jpg'),"alt":""}})]),_c('div',{staticClass:"flex xs12 md6 product-info"},[_c('p',[_c('b',[_vm._v("Resenha do livro:")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.subscription.product.description)}}),_c('br'),_c('b',[_vm._v("Nível de leitura:")]),_vm._v(" "+_vm._s(_vm.subscription.product.tagName)+" ")])])]):_vm._e(),_c('div',{staticClass:"clearfix"}),_c('hr'),(!_vm.ReadMore)?_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-primary read-more-btn bottom",on:{"click":function($event){return _vm.changeReadMoreState(true)}}},[_vm._v(" LEIA MAIS "),_c('i',{staticClass:"fa fa-chevron-down"})])]):_vm._e(),(_vm.ReadMore)?_c('div',[_c('div',{staticClass:"va-row learn-paths"},[_c('div',{staticClass:"flex xs12 md8"},[_c('h1',[_vm._v(" O que "+_vm._s(_vm.subscription.subscription.characterName)+" pode aprender com esse livro? ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.subscription.product.description2)}}),_c('div',{staticClass:"box-home"},[_c('div',{staticClass:"house-icon"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"36","height":"36","viewBox":"0 0 36 36"}},[_c('path',{attrs:{"fill":"#000","fill-rule":"evenodd","d":"M18.677 7.238a1.07 1.07 0 0 0-1.348 0l-8.915 7.145c-.262.21-.414.528-.414.864v11.64c0 .607.487 1.1 1.086 1.1l5.698.013a1.086 1.086 0 0 0 1.089-1.099v-5.817c0-.608.486-1.102 1.085-1.102h2.084c.602 0 1.088.494 1.088 1.102v5.814c0 .611.49 1.105 1.089 1.102l5.699-.015A1.094 1.094 0 0 0 28 26.883V15.247c0-.336-.152-.657-.414-.864l-8.91-7.145z"}})])]),_vm._m(0),_c('div',{staticClass:"clearfix"})])]),_c('div',{staticClass:"flex xs12 md4"},[_c('div',{staticClass:"panel-kit"},[_vm._m(1),_c('ul',_vm._l((_vm.subscription.product
													.features),function(subscription,id){return _c('li',{key:id},[_c('span',[_vm._v(_vm._s(subscription.description))])])}),0)])])]),_c('hr'),_c('div',{staticClass:"address-boxes"},[_c('h4',[_vm._v("Endereço de entrega")]),_c('div',{staticClass:"panel more-address"},[_c('div',[_c('div',{staticClass:"va-row address-row panel-body"},[_c('div',{staticClass:"flex xs2 md1 text-center"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"36","height":"36","viewBox":"0 0 36 36"}},[_c('path',{attrs:{"fill":"#000","fill-rule":"nonzero","d":"M18 5C12.477 5 8 9.477 8 15c0 8.25 10 16 10 16s10-8.25 10-16c0-5.523-4.477-10-10-10zm0 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"}})])]),_c('div',{staticClass:"flex xs10 no-padding-left"},[_c('b',[_vm._v(_vm._s(_vm.subscription.address.address1)+", "+_vm._s(_vm.subscription.address.addressNumber))]),_c('br'),_vm._v(" "+_vm._s(_vm.subscription.address.district)+", "+_vm._s(_vm.subscription.address.city)+" - "+_vm._s(_vm.subscription.address.stateId)+" "),_c('br'),_vm._v(" CEP: "+_vm._s(_vm.subscription.address.zipPostalCode)+" "),_c('br'),_vm._v("Complemento: "),_c('br'),_vm._v(" "+_vm._s(_vm.subscription.address.addressReference)+" ")]),_c('hr')])])])]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-primary read-more-btn",on:{"click":function($event){return _vm.changeReadMoreState(false)}}},[_vm._v(" ESCONDER "),_c('i',{staticClass:"fa fa-chevron-up"})])])]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Receba em casa:")]),_c('br'),_vm._v("Junto com o livro você receberá o Guia de Leitura, que explica os campos de aprendizados incentivados por esse Kit. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h1',[_vm._v("O que vem no kit")])])
}]

export { render, staticRenderFns }