import { render, staticRenderFns } from "./PeopleViewManager.vue?vue&type=template&id=8f8e4f6a&scoped=true"
import script from "./PeopleViewManager.vue?vue&type=script&lang=js"
export * from "./PeopleViewManager.vue?vue&type=script&lang=js"
import style0 from "./PeopleViewManager.vue?vue&type=style&index=0&id=8f8e4f6a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f8e4f6a",
  null
  
)

export default component.exports