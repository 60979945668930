<template>
	<div class="auth-layout">
		<div class="nav d-lg-none">
			<router-link :to="{ path: '/' }">
				<!-- <vuestic-icon-vuestic/> -->
			</router-link>
		</div>
		<div class="main va-row">
			<div class="auth-content flex lg6 xs12 fill-height">
				<div class="dde-content">
					<router-view />
				</div>
			</div>
			<div class="auth-layout__auth-wallpaper flex md6"></div>
		</div>
	</div>
</template>

<script>
	// d-none and d-lg-flex were deleted, bug will be fixed in the nearest update
	// import VuesticIconVuestic
	//   from '../../vuestic-theme/vuestic-components/vuestic-icon/VuesticIconVuestic'

	export default {
		name: "AuthLayout",
		// components: { VuesticIconVuestic },
	};
</script>

<style lang="scss">
	.auth-layout {
		height: 100vh;
		margin: 0;

		&__auth-wallpaper {
			position: relative;
			background-color: #4965bc;
			overflow: hidden;
			background-image: url("../../assets/images/home_escola.png");
			background-position: bottom center;
			display: flex;
			justify-content: center;
			align-items: center;
			background-size: cover;

			@include va-flex-center();

			@media (max-width: 990px) {
				display: none;
			}

			&__logo {
				z-index: 2;
				position: absolute;
				height: $auth-wallpaper-ivuestic-h;
				width: 100%;
			}
			.vuestic-icon-vuestic {
				height: $auth-wallpaper-ivuestic-h;
				width: 100%;
			}

			&__cross-line {
				z-index: 1;
				position: absolute;
				background-color: $auth-wallpaper-oblique-line;
				left: calc(50% - 27% / 2);
				transform: rotate(15deg);
				width: 27%;
				height: 115%;
			}
		}

		.nav {
			display: flex;
			align-items: center;
			justify-content: center;
			height: $top-mobile-nav-height;
			background-color: $top-nav-bg;

			@media (max-width: 990px) {
				display: none;
			}
			.vuestic-icon-vuestic {
				height: $auth-mobile-nav-ivuestic-h;
				width: 100%;
			}
		}
		.main {
			margin: 0;
			height: 100vh;
			.auth-content {
				display: flex;
				justify-content: center;
				background-color: white;
				overflow-y: auto;
				padding-left: 25px !important;
				padding-right: 25px !important;
			}
		}

		@include media-breakpoint-down(md) {
			.main {
				height: 100vh;
				.auth-content {
					align-items: flex-start;
					padding-top: $auth-content-padding-t;
				}
				.auth-wallpaper {
					display: none;
				}
			}
		}
	}

	.dde-content {
		display: flex;
		padding: 40px 24px;
		align-items: center;
		height: 100%;
		justify-content: center;

		@media (max-height: 663px) {
			height: fit-content;
		}
	}
</style>
