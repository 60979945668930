import Vue from 'vue'
import Vuex from 'vuex'
import VuexI18n from 'vuex-i18n' // load vuex i18n module
import app from './modules/app'
import VuexPersistence from 'vuex-persist'

import * as getters from './getters'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app,
  },
  state: {},
  mutations: {},
  plugins: [vuexLocal.plugin]
})

Vue.use(VuexI18n.plugin, store)

export default store
